import React from 'react';
import {Hotel, HotelState} from '../../../types/types';
import {makeStyles} from '@material-ui/core';
import {Theme} from '@material-ui/core/styles/createTheme';
import Popper from '@material-ui/core/Popper';
import Grow from '@material-ui/core/Grow';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import MenuList from '@material-ui/core/MenuList';
import MenuItem from '@material-ui/core/MenuItem';
import EditIcon from '@material-ui/icons/Edit';
import Divider from '@material-ui/core/Divider';
import {deleteHotel, sendNewTokenMail, setHotelStarStatus, setHotelState} from '../../../reducer/application/types';
import DeleteIcon from '@material-ui/icons/Delete';
import StarIcon from '@material-ui/icons/Star';
import FetchStatus from '../../../api/FetchStatus';
import CircularProgress from '@material-ui/core/CircularProgress';
import SendIcon from '@material-ui/icons/Send';
import {useDispatch} from 'react-redux';
import {useLocalization} from '../../../localization/localization';
import {Block, CheckCircle, Spellcheck, StarBorder} from '@material-ui/icons';

interface HotelMenuProps {
    hotel: Hotel;
    anchorElement: HTMLElement | null;
    hide: () => void;
    openDeleteDialog: (callback: () => void) => void;
    openHighlightDialog: () => void;
    sendNewTokenMailFetchStatus: FetchStatus;
    editHotel: () => void;
}

const useStyles = makeStyles((theme: Theme) => ({
    root: {},
    actionMenuSurround: {
        boxShadow: '0 6px 16px -4px rgba(0,0,0,0.3)',
        background: 'white',
        borderRadius: 4,
        zIndex: 20
    },
    actionMenuItem: {
        color: '#193150',
        letterSpacing: -0.42,
        fontSize: 15,
        lineHeight: 1,
        padding: theme.spacing(1.5, 2)
    },
    actionIcon: {
        marginRight: 8,
        width: 24,
        height: 24,
        color: 'inherit'
    },
    actionDivider: {
        background: '#F5F5F5'
    },
    reSendTokenProgress: {
        marginRight: theme.spacing(1)
    }
}));

const HotelMenu: React.FC<HotelMenuProps> = ({
                                                 hotel,
                                                 anchorElement,
                                                 hide,
                                                 openDeleteDialog,
                                                 openHighlightDialog,
                                                 sendNewTokenMailFetchStatus,
                                                 editHotel
                                             }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const messages = useLocalization();

    return (
        <Popper open={Boolean(anchorElement)} anchorEl={anchorElement} transition
                style={{zIndex: 5}}>
            {({TransitionProps, placement}) => (
                <Grow
                    {...TransitionProps}
                    style={{transformOrigin: placement === 'bottom' ? 'right top' : 'right bottom'}}>
                    <div className={classes.actionMenuSurround}>
                        <ClickAwayListener onClickAway={hide}>
                            <MenuList>
                                <MenuItem className={classes.actionMenuItem}
                                          onClick={() => {
                                              editHotel();
                                              hide();
                                          }}>
                                    <EditIcon className={classes.actionIcon}/>
                                    {messages['hotel.actions.edit']}
                                </MenuItem>
                                <Divider className={classes.actionDivider}/>
                                <MenuItem className={classes.actionMenuItem} onClick={() => {
                                    hide();
                                    openDeleteDialog(() => () => {
                                        dispatch(deleteHotel(hotel.id))
                                    });
                                }}>
                                    <DeleteIcon className={classes.actionIcon}/>
                                    {messages['hotel.actions.delete']}
                                </MenuItem>
                                <Divider className={classes.actionDivider}/>
                                {(hotel.state === HotelState.Activated || hotel.state === HotelState.Edited) &&
                                <>
                                    <MenuItem className={classes.actionMenuItem} onClick={() => {
                                        if (hotel.starred) {
                                            dispatch(setHotelStarStatus({
                                                id: hotel.id
                                            }))
                                        } else {
                                            openHighlightDialog();
                                        }
                                        hide();
                                    }}>
                                        {hotel.starred ? <StarBorder className={classes.actionIcon}/> :
                                            <StarIcon className={classes.actionIcon}/>}
                                        {hotel.starred ? messages['hotel.actions.unstar'] : messages['hotel.actions.star']}
                                    </MenuItem>
                                    <Divider className={classes.actionDivider}/>
                                </>
                                }
                                {(hotel.state === HotelState.Activated || hotel.state === HotelState.Edited || hotel.state === HotelState.Deactivated) && <>
                                    <MenuItem className={classes.actionMenuItem} onClick={() => {
                                        dispatch(setHotelState({
                                            id: hotel.id,
                                            state: hotel.state === HotelState.Deactivated ? HotelState.Activated : HotelState.Deactivated
                                        }));
                                        hide();
                                    }}>
                                        {hotel.state === HotelState.Deactivated ?
                                            <CheckCircle className={classes.actionIcon}/> :
                                            <Block className={classes.actionIcon}/>}
                                        {hotel.state === HotelState.Deactivated ? messages['hotel.actions.activate'] : messages['hotel.actions.deactivate']}
                                    </MenuItem>
                                    <Divider className={classes.actionDivider}/>
                                </>}
                                {hotel.state === HotelState.Pending && <>
                                    <MenuItem className={classes.actionMenuItem} onClick={() => {
                                        dispatch(setHotelState({
                                            id: hotel.id,
                                            state: HotelState.Activated
                                        }));
                                        hide();
                                    }}>
                                        <Spellcheck className={classes.actionIcon}/>
                                        {messages['hotel.actions.checked']}
                                    </MenuItem>
                                    <Divider className={classes.actionDivider}/>
                                </>}
                                <MenuItem className={classes.actionMenuItem}
                                          disabled={sendNewTokenMailFetchStatus === FetchStatus.ACTIVE}
                                          onClick={() => {
                                              if (sendNewTokenMailFetchStatus !== FetchStatus.ACTIVE) {
                                                  dispatch(sendNewTokenMail(hotel.id))
                                              }
                                          }}>
                                    {sendNewTokenMailFetchStatus === FetchStatus.ACTIVE ?
                                        <CircularProgress
                                            className={classes.reSendTokenProgress}
                                            color={'primary'}/> :
                                        <SendIcon className={classes.actionIcon}/>}
                                    {messages['hotel.actions.token.re.send']}
                                </MenuItem>
                            </MenuList>
                        </ClickAwayListener>
                    </div>
                </Grow>
            )}
        </Popper>
    );
}

export default HotelMenu;