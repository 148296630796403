import {ApplicationState, Hotel, Language, User} from '../../types/types';
import FetchStatus from '../../api/FetchStatus';
import {normalize} from '../../helper/helperFunctions';
import {createReducer} from '@reduxjs/toolkit';
import {
    addApplicationMessage,
    deleteHotelFromState,
    dismissApplicationMessage,
    fetchChangePassword,
    fetchChangePasswordError,
    fetchChangePasswordSuccess,
    fetchCreateOrUpdateHotel,
    fetchCreateOrUpdateHotelError,
    fetchCreateOrUpdateHotelSuccess,
    fetchLoadAdmins,
    fetchLoadAdminsError,
    fetchLoadAdminsSuccess,
    fetchLoadCountries,
    fetchLoadCountriesError,
    fetchLoadCountriesSuccess,
    fetchLoadHotels,
    fetchLoadHotelsError,
    fetchLoadHotelsSuccess,
    fetchSaveHotel,
    fetchSaveHotelError,
    fetchSaveHotelSuccess,
    fetchSendNewTokenMail,
    fetchSendNewTokenMailError,
    fetchSendNewTokenMailSuccess,
    removeApplicationMessage, removeHotelFromState,
    resetCreateOrUpdateHotelFetchStatus,
    resetHotelFetchStatus, resetNewHotelToken,
    setHotelInactiveStatus,
    setHotelPage,
    setHotelStarStatus,
    setHotelStateSuccess,
    setLanguage,
    setRowsPerHotelPage,
    setShowChangePasswordDialog,
    setUpdatedHotel,
    toggleHotelSelection
} from './types';


const initialState: ApplicationState = {
    language: {
        available: [Language.GERMAN],
        selected: navigator.language.replace('-', '_') as Language
    },
    countries: {},
    messages: [],
    loadHotelsFetchStatus: FetchStatus.DEFAULT,
    loadCountriesFetchStatus: FetchStatus.DEFAULT,
    hotels: {},
    tags: {},
    selectedHotels: [],
    createOrUpdateHotelFetchStatus: FetchStatus.DEFAULT,
    createdToken: null,
    sendNewTokenMailFetchStatus: FetchStatus.DEFAULT,
    showChangePasswordDialog: false,
    changePasswordFetchStatus: FetchStatus.DEFAULT,
    hotelPage: 0,
    rowsPerHotelPage: 10,
    adminFetchStatus: FetchStatus.DEFAULT,
    admins: {}
};


export default createReducer<ApplicationState>(initialState, builder =>
    builder.addCase(setLanguage, (state, action) => ({
        ...state,
        language: {
            ...state.language,
            selected: state.language.available.includes(action.payload) ? action.payload : Language.GERMAN
        }
    })).addCase(addApplicationMessage, (state, action) => ({
        ...state,
        messages: [...state.messages, action.payload]
    })).addCase(dismissApplicationMessage, (state, action) => ({
        ...state,
        messages: state.messages.map(it => {
            if (it.key === action.payload) {
                return {
                    ...it,
                    dismissed: true
                }
            }
            return it;
        })
    })).addCase(removeApplicationMessage, (state, action) => ({
        ...state,
        messages: state.messages.filter(it => it.key !== action.payload)
    })).addCase(fetchLoadHotels, state => ({
        ...state,
        loadHotelsFetchStatus: FetchStatus.ACTIVE
    })).addCase(fetchLoadHotelsSuccess, (state, action) => ({
        ...state,
        loadHotelsFetchStatus: FetchStatus.SUCCESS,
        hotels: {
            ...state.hotels,
            ...normalize(action.payload.hotels, 'id', {
                fetchStatus: FetchStatus.DEFAULT
            })
        },
        tags: {
            ...state.tags,
            ...normalize(action.payload.tags, 'id')
        }
    })).addCase(fetchLoadHotelsError, state => ({
        ...state,
        loadHotelsFetchStatus: FetchStatus.ERROR
    })).addCase(fetchLoadCountries, state => ({
        ...state,
        loadCountriesFetchStatus: FetchStatus.ACTIVE
    })).addCase(fetchLoadCountriesSuccess, (state, action) => ({
        ...state,
        loadCountriesFetchStatus: FetchStatus.SUCCESS,
        countries: {
            ...state.countries,
            ...normalize(action.payload, 'id')
        }
    })).addCase(fetchLoadCountriesError, state => ({
        ...state,
        loadCountriesFetchStatus: FetchStatus.ERROR
    })).addCase(toggleHotelSelection, (state, action) => {
        let selectedHotels = [...state.selectedHotels];
        if (selectedHotels.includes(action.payload)) {
            selectedHotels = selectedHotels.filter(it => it !== action.payload);
        } else {
            selectedHotels.push(action.payload);
        }
        return {
            ...state,
            selectedHotels
        }
    }).addCase(setHotelInactiveStatus, (state, action) => ({
        ...state,
        hotels: {
            ...state.hotels,
            [action.payload.id]: {
                ...state.hotels[action.payload.id] as Hotel,
                inactive: action.payload.status
            }
        }
    })).addCase(setHotelStarStatus, (state, action) => ({
        ...state,
        hotels: {
            ...state.hotels,
            [action.payload.id]: {
                ...state.hotels[action.payload.id] as Hotel,
                starred: Boolean(action.payload.categoryIDs?.length)
            }
        }
    })).addCase(fetchSaveHotel, (state, action) => ({
        ...state,
        hotels: {
            ...state.hotels,
            [action.payload]: {
                ...state.hotels[action.payload] as Hotel,
                fetchStatus: FetchStatus.ACTIVE
            }
        }
    })).addCase(fetchSaveHotelSuccess, (state, action) => ({
        ...state,
        hotels: {
            ...state.hotels,
            [action.payload]: {
                ...state.hotels[action.payload] as Hotel,
                fetchStatus: FetchStatus.SUCCESS
            }
        }
    })).addCase(fetchSaveHotelError, (state, action) => ({
        ...state,
        hotels: {
            ...state.hotels,
            [action.payload]: {
                ...state.hotels[action.payload] as Hotel,
                fetchStatus: FetchStatus.ERROR
            }
        }
    })).addCase(setUpdatedHotel, (state, action) => ({
        ...state,
        hotels: {
            ...state.hotels,
            [action.payload.id]: {
                ...state.hotels[action.payload.id] as Hotel,
                name: action.payload.name,
                User: {
                    ...state.hotels[action.payload.id]?.User as User,
                    firstName: action.payload.firstName,
                    lastName: action.payload.lastName,
                    email: action.payload.email,
                    phone: action.payload.phone
                }
            }
        }
    })).addCase(deleteHotelFromState, (state, action) => {
        const reduced = {...state};
        reduced.hotels = {...reduced.hotels};
        delete reduced.hotels[action.payload];
        reduced.selectedHotels = [...reduced.selectedHotels];
        if (reduced.selectedHotels.includes(action.payload)) {
            reduced.selectedHotels = reduced.selectedHotels.filter(it => it !== action.payload);
        }
        return reduced;
    }).addCase(fetchCreateOrUpdateHotel, state => ({
        ...state,
        createOrUpdateHotelFetchStatus: FetchStatus.ACTIVE,
        createdToken: null
    })).addCase(fetchCreateOrUpdateHotelSuccess, (state, action) => ({
        ...state,
        hotels: {
            ...state.hotels,
            [action.payload.hotel.id]: {
                ...action.payload.hotel,
                User: action.payload.user
            }
        },
        createOrUpdateHotelFetchStatus: FetchStatus.SUCCESS,
        createdToken: action.payload.token || null
    })).addCase(fetchCreateOrUpdateHotelError, state => ({
        ...state,
        createOrUpdateHotelFetchStatus: FetchStatus.ERROR,
        createdToken: null
    })).addCase(resetCreateOrUpdateHotelFetchStatus, (state) => ({
        ...state,
        createOrUpdateHotelFetchStatus: FetchStatus.DEFAULT
    })).addCase(fetchSendNewTokenMail, state => ({
        ...state,
        sendNewTokenMailFetchStatus: FetchStatus.ACTIVE
    })).addCase(fetchSendNewTokenMailSuccess, state => ({
        ...state,
        sendNewTokenMailFetchStatus: FetchStatus.SUCCESS
    })).addCase(fetchSendNewTokenMailError, state => ({
        ...state,
        sendNewTokenMailFetchStatus: FetchStatus.ERROR
    })).addCase(setShowChangePasswordDialog, (state, action) => ({
        ...state,
        showChangePasswordDialog: action.payload
    })).addCase(fetchChangePassword, state => ({
        ...state,
        changePasswordFetchStatus: FetchStatus.ACTIVE
    })).addCase(fetchChangePasswordSuccess, state => ({
        ...state,
        changePasswordFetchStatus: FetchStatus.SUCCESS,
        showChangePasswordDialog: false
    })).addCase(fetchChangePasswordError, state => ({
        ...state,
        changePasswordFetchStatus: FetchStatus.ERROR,
        showChangePasswordDialog: false
    })).addCase(setHotelPage, (state, action) => ({
        ...state,
        hotelPage: action.payload
    })).addCase(setRowsPerHotelPage, (state, action) => ({
        ...state,
        rowsPerHotelPage: action.payload
    })).addCase(fetchLoadAdmins, (state) => ({
        ...state,
        adminFetchStatus: FetchStatus.ACTIVE
    })).addCase(fetchLoadAdminsError, (state) => ({
        ...state,
        adminFetchStatus: FetchStatus.ERROR
    })).addCase(fetchLoadAdminsSuccess, (state, action) => ({
        ...state,
        adminFetchStatus: FetchStatus.SUCCESS,
        admins: normalize(action.payload)
    })).addCase(setHotelStateSuccess, (state, action) => ({
        ...state,
        hotels: {
            ...state.hotels,
            [action.payload.id]: {
                ...(state.hotels[action.payload.id] as Hotel),
                state: action.payload.state
            }
        }
    })).addCase(resetHotelFetchStatus, (state, action) => ({
        ...state,
        hotels: {
            ...state.hotels,
            [action.payload]: {
                ...(state.hotels[action.payload] as Hotel),
                fetchStatus: FetchStatus.DEFAULT
            }
        }
    })).addCase(resetNewHotelToken, (state) => ({
        ...state,
        createdToken: null
    })).addCase(removeHotelFromState, (state, action) => {
        const reduced = {...state};
        reduced.hotels = {...reduced.hotels};
        delete reduced.hotels[action.payload];
        return reduced;
    }));