import {call, take, put, select, spawn, takeLatest, takeEvery} from 'redux-saga/effects';
import type {AppState, Country, Hotel} from '../types/types';
import {Route, get, post} from '../api/Api';
import {StatusCodes} from 'http-status-codes';
import {
    CREATE_OR_UPDATE_HOTEL,
    DELETE_HOTEL,
    fetchCreateOrUpdateHotel,
    fetchCreateOrUpdateHotelError,
    fetchCreateOrUpdateHotelSuccess,
    fetchLoadHotels,
    fetchLoadHotelsError,
    fetchLoadHotelsSuccess,
    fetchSaveHotel,
    fetchSaveHotelError,
    fetchSaveHotelSuccess,
    fetchSendNewTokenMail,
    fetchSendNewTokenMailError,
    fetchSendNewTokenMailSuccess,
    LOAD_HOTELS,
    SAVE_HOTEL,
    SEND_NEW_TOKEN_MAIL,
    SET_HOTEL_INACTIVE_STATUS,
    SET_HOTEL_STAR_STATUS,
    setHotelInactiveStatus,
    setHotelStarStatus,
    setUpdatedHotel,
    UpdateHotelPayload,
    CreateOrUpdateHotelResponsePayload,
    CreateOrSaveHotelPayload,
    SET_HOTEL_STATE,
    SetHotelStatePayload,
    setHotelStateSuccess,
    LOAD_COUNTRIES, fetchLoadCountries, fetchLoadCountriesError, fetchLoadCountriesSuccess, removeHotelFromState
} from '../reducer/application/types';
import {PayloadAction} from '@reduxjs/toolkit';
import {logout} from '../reducer/user/types';

export function* loadHotels() {
    yield takeLatest(LOAD_HOTELS, function* () {
        try {
            const jwt: string | undefined = yield select((state: AppState) => state.user.jwt);
            if (jwt) {
                yield put(fetchLoadHotels());
                const {hotels, tags} = yield call(get, Route.LOAD_HOTELS, undefined, jwt);
                if (hotels && tags) {
                    yield put(fetchLoadHotelsSuccess({hotels, tags}));
                } else {
                    yield put(fetchLoadHotelsError());
                }
            }
        } catch (e) {
            console.error(e);
            yield put(logout());
            yield put(fetchLoadHotelsError());
        }
    });
}

export function* setHotelInactiveSaga() {
    while (true) {
        // @ts-ignore
        const action = yield take(SET_HOTEL_INACTIVE_STATUS);
        const {hotel, jwt} = yield select((state: AppState) => ({
            hotel: state.application.hotels[action.payload.id],
            jwt: state.user.jwt
        }));
        if (hotel && jwt && !action.sagaIgnore) {
            try {
                yield put(fetchSaveHotel(hotel.id));
                const hotels: number[] = yield call(post, action.payload.status ? Route.SET_HOTEL_INACTIVE : Route.SET_HOTEL_ACTIVE, {ids: [hotel.id]}, jwt);
                if (hotels.includes(hotel.id)) {
                    yield put(fetchSaveHotelSuccess(hotels[0]));
                } else {
                    yield put(fetchSaveHotelError(hotel.id));
                    // Reset the previous change
                    yield put(setHotelInactiveStatus({
                        id: hotel.id,
                        status: !action.payload.status,
                        sagaIgnore: true
                    }));
                }
            } catch (e) {
                console.error(e);
                yield put(fetchSaveHotelError(hotel.id));
                yield put(setHotelInactiveStatus({
                    id: hotel.id,
                    status: !action.payload.status,
                    sagaIgnore: true
                }));
                if (parseInt(e.message, 10) === StatusCodes.UNAUTHORIZED) {
                    yield put(logout());
                }
            }
        }
    }
}

export function* setHotelStarSaga(): Generator<any, any, any> {
    while (true) {
        const action = yield take(SET_HOTEL_STAR_STATUS);
        const {hotel, jwt} = yield select((state: AppState) => ({
            hotel: state.application.hotels[action.payload.id],
            jwt: state.user.jwt
        }));
        if (hotel && jwt && !action.sagaIgnore) {
            try {
                yield put(fetchSaveHotel(hotel.id));
                const hotels = yield call(post, action.payload.categoryIDs ? Route.STAR_HOTEL : Route.UNSTAR_HOTEL, {
                    id: hotel.id,
                    categoryIDs: action.payload.categoryIDs
                }, jwt);
                if (hotels.includes(hotel.id)) {
                    yield put(fetchSaveHotelSuccess(hotels));
                } else {
                    yield put(fetchSaveHotelError(hotel.id));
                    // Reset the previous change
                    yield put(setHotelStarStatus({
                        id: hotel.id,
                        sagaIgnore: true
                    }));
                }
            } catch (e) {
                console.error(e);
                yield put(fetchSaveHotelError(hotel.id));
                if (parseInt(e.message, 10) === StatusCodes.UNAUTHORIZED) {
                    yield put(logout());
                }
            }
        }
    }
}

export function* setHotelStateSaga() {
    yield takeEvery(SET_HOTEL_STATE, function* (action: PayloadAction<SetHotelStatePayload>) {
        const {hotel, jwt} = yield select((state: AppState) => ({
            hotel: state.application.hotels[action.payload.id],
            jwt: state.user.jwt
        }));
        if (hotel && jwt?.trim()) {
            try {
                yield put(fetchSaveHotel(action.payload.id));
                yield call(post, Route.SET_HOTEL_STATE, {state: action.payload.state, ids: [action.payload.id]}, jwt);
                yield put(fetchSaveHotelSuccess(action.payload.id));
                yield put(setHotelStateSuccess(action.payload));
            } catch (e) {
                console.error(e);
                yield put(fetchSaveHotelError(action.payload.id));
            }
        }
    });
}

export function* saveHotelSaga(): Generator<any, any, any> {
    while (true) {
        const action: PayloadAction<UpdateHotelPayload> = yield take(SAVE_HOTEL);
        const {hotel, jwt} = yield select((state: AppState) => ({
            hotel: state.application.hotels[action.payload.id],
            jwt: state.user.jwt
        }));
        try {
            if (hotel && jwt && action.payload.firstName.trim() && action.payload.lastName.trim() && action.payload.email.trim() && action.payload.name.trim()) {
                yield put(fetchSaveHotel(hotel.id));
                const hotels = yield call(post, Route.SAVE_HOTEL, action.payload, jwt);
                if (hotels.includes(hotel.id)) {
                    yield put(fetchSaveHotelSuccess(hotels));
                    yield put(setUpdatedHotel(action.payload));
                } else {
                    yield put(fetchSaveHotelError(hotel.id));
                }
            }
        } catch (e) {
            console.error(e);
            yield put(fetchSaveHotelError(hotel.id));
            if (parseInt(e.message, 10) === StatusCodes.UNAUTHORIZED) {
                yield put(logout());
            }
        }

    }
}

export function* deleteHotelSaga(): Generator<any, any, any> {
    yield takeEvery(DELETE_HOTEL, function* (action: PayloadAction<number>) {
        const {hotel, jwt}: { hotel?: Hotel, jwt?: string } = yield select((state: AppState) => ({
            hotel: state.application.hotels[action.payload],
            jwt: state.user.jwt
        }));
        try {
            if (hotel && jwt) {
                yield put(fetchSaveHotel(hotel.id));
                const hotels: number[] = yield call(post, Route.DELETE_HOTEL, {ids: [action.payload]}, jwt);
                if (hotels.includes(hotel.id)) {
                    yield put(fetchSaveHotelSuccess(action.payload));
                    yield put(removeHotelFromState(hotel.id));
                } else {
                    yield put(fetchSaveHotelError(hotel.id));
                }
            }
        } catch (e) {
            console.error(e);
            if (hotel) {
                yield put(fetchSaveHotelError(hotel.id));
            }
            if (parseInt(e.message, 10) === StatusCodes.UNAUTHORIZED) {
                yield put(logout());
            }
        }
    })
}

export function* createHotelSaga(): Generator<any, any, any> {
    yield takeEvery(CREATE_OR_UPDATE_HOTEL, function* (action: PayloadAction<CreateOrSaveHotelPayload>) {
        const {jwt} = yield select((state: AppState) => ({
            jwt: state.user.jwt
        }));
        try {
            if (jwt) {
                yield put(fetchCreateOrUpdateHotel());
                const result: CreateOrUpdateHotelResponsePayload = yield call(post, Route.CREATE_OR_UPDATE_HOTEL, action.payload, jwt);
                if (result && result.hotel && result.user) {
                    yield put(fetchCreateOrUpdateHotelSuccess(result));
                } else {
                    yield put(fetchCreateOrUpdateHotelError());
                }
            }
        } catch (e) {
            console.error(e);
            yield put(fetchCreateOrUpdateHotelError());
            if (parseInt(e.message, 10) === StatusCodes.UNAUTHORIZED) {
                yield put(logout());
            }
        }
    })
}


export function* sendNewTokenEmailSaga(): Generator<any, any, any> {
    while (true) {
        const action: PayloadAction<number> = yield take(SEND_NEW_TOKEN_MAIL);
        const {hotel, jwt} = yield select((state: AppState) => ({
            hotel: state.application.hotels[action.payload],
            jwt: state.user.jwt
        }));
        try {
            if (hotel && jwt) {
                yield put(fetchSendNewTokenMail());
                yield call(post, Route.SEND_NEW_TOKEN_MAIL, {id: action.payload}, jwt);
                yield put(fetchSendNewTokenMailSuccess());
            }
        } catch (e) {
            console.error(e);
            yield put(fetchSendNewTokenMailError());
            if (parseInt(e.message, 10) === StatusCodes.UNAUTHORIZED) {
                yield put(logout());
            }
        }

    }
}

function* loadCountriesSaga() {
    yield takeEvery(LOAD_COUNTRIES, function* () {
        try {
            const {jwt} = yield select((state: AppState) => ({
                jwt: state.user.jwt
            }));
            if (jwt?.trim()) {
                yield put(fetchLoadCountries());
                const countries: Country[] = yield call(get, Route.LOAD_COUNTRIES, undefined, jwt);
                yield put(fetchLoadCountriesSuccess(countries));
            }
        } catch (e) {
            console.error(e);
            yield put(fetchLoadCountriesError());
        }
    })
}

/**
 * The root saga of the web app. It calls all other sagas which will be used in the web app.
 */
function* hotelSaga() {
    yield spawn(loadHotels);
    yield spawn(setHotelInactiveSaga);
    yield spawn(setHotelStarSaga);
    yield spawn(saveHotelSaga);
    yield spawn(deleteHotelSaga);
    yield spawn(createHotelSaga);
    yield spawn(sendNewTokenEmailSaga);
    yield spawn(setHotelStateSaga);
    yield spawn(loadCountriesSaga);
}

export default hotelSaga;