import {createAction} from '@reduxjs/toolkit';
import {
    Admin,
    ApplicationMessage,
    Country,
    Gender,
    Hotel,
    HotelState,
    Language,
    PricingModel,
    Tag,
    User
} from '../../types/types';

export const SET_LANGUAGE = 'APPLICATION/SET_LANGUAGE';
export const ADD_APPLICATION_MESSAGE = 'APPLICATION/ADD_APPLICATION_MESSAGE';
export const REMOVE_APPLICATION_MESSAGE = 'APPLICATION/REMOVE_APPLICATION_MESSAGE';
export const DISMISS_APPLICATION_MESSAGE = 'APPLICATION/DISMISS_APPLICATION_MESSAGE';

export const LOAD_HOTELS = 'APPLICATION/LOAD_HOTELS';
export const FETCH_LOAD_HOTELS = 'APPLICATION/FETCH_LOAD_HOTELS';
export const FETCH_LOAD_HOTELS_SUCCESS = 'APPLICATION/FETCH_LOAD_HOTELS_SUCCESS';
export const FETCH_LOAD_HOTELS_ERROR = 'APPLICATION/FETCH_LOAD_HOTELS_ERROR';
export const TOGGLE_HOTEL_SELECTION = 'APPLICATION/TOGGLE_HOTEL_SELECTION';
export const SET_HOTEL_INACTIVE_STATUS = 'APPLICATION/SET_HOTEL_INACTIVE_STATUS';
export const SET_HOTEL_STATE = 'APPLICATION/SET_HOTEL_STATE';
export const SET_HOTEL_STAR_STATUS = 'APPLICATION/SET_HOTEL_STAR_STATUS';
export const SET_HOTEL_STATE_SUCCESS = 'APPLICATION/SET_HOTEL_STATE_SUCCESS';
export const FETCH_SAVE_HOTEL = 'APPLICATION/FETCH_SAVE_HOTEL';
export const FETCH_SAVE_HOTEL_SUCCESS = 'APPLICATION/FETCH_SAVE_HOTEL_SUCCESS';
export const FETCH_SAVE_HOTEL_ERROR = 'APPLICATION/FETCH_SAVE_HOTEL_ERROR';
export const SAVE_HOTEL = 'APPLICATION/SAVE_HOTEL';
export const SET_UPDATED_HOTEL = 'APPLICATION/SET_UPDATED_HOTEL';
export const DELETE_HOTEL = 'APPLICATION/DELETE_HOTEL';
export const DELETE_HOTEL_FROM_STATE = 'APPLICATION/DELETE_HOTEL_FROM_STATE';
export const CREATE_OR_UPDATE_HOTEL = 'APPLICATION/CREATE_OR_UPDATE_HOTEL';
export const FETCH_CREATE_OR_UPDATE_HOTEL = 'APPLICATION/FETCH_CREATE_OR_UPDATE_HOTEL';
export const FETCH_CREATE_OR_UPDATE_HOTEL_SUCCESS = 'APPLICATION/FETCH_CREATE_OR_UPDATE_HOTEL_SUCCESS';
export const FETCH_CREATE_OR_UPDATE_HOTEL_ERROR = 'APPLICATION/FETCH_CREATE_OR_UPDATE_HOTEL_ERROR';
export const RESET_CREATE_OR_UPDATE_HOTEL_FETCH_STATUS = 'APPLICATION/RESET_CREATE_OR_UPDATE_HOTEL_FETCH_STATUS';
export const RESET_HOTEL_TOKEN = 'APPLICATION/RESET_HOTEL_TOKEN';
export const SEND_NEW_TOKEN_MAIL = 'APPLICATION/SEND_NEW_TOKEN_MAIL';
export const FETCH_SEND_NEW_TOKEN_MAIL = 'APPLICATION/FETCH_SEND_NEW_TOKEN_MAIL';
export const FETCH_SEND_NEW_TOKEN_MAIL_SUCCESS = 'APPLICATION/FETCH_SEND_NEW_TOKEN_MAIL_SUCCESS';
export const FETCH_SEND_NEW_TOKEN_MAIL_ERROR = 'APPLICATION/FETCH_SEND_NEW_TOKEN_MAIL_ERROR';
export const SET_SHOW_CHANGE_PASSWORD_DIALOG = 'APPLICATION/SET_SHOW_CHANGE_PASSWORD_DIALOG';
export const CHANGE_PASSWORD = 'APPLICATION/CHANGE_PASSWORD';
export const FETCH_CHANGE_PASSWORD = 'APPLICATION/FETCH_CHANGE_PASSWORD';
export const FETCH_CHANGE_PASSWORD_SUCCESS = 'APPLICATION/FETCH_CHANGE_PASSWORD_SUCCESS';
export const FETCH_CHANGE_PASSWORD_ERROR = 'APPLICATION/FETCH_CHANGE_PASSWORD_ERROR';
export const SET_HOTEL_PAGE = 'APPLICATION/SET_HOTEL_PAGE';
export const SET_ROWS_PER_HOTEL_PAGE = 'APPLICATION/SET_ROWS_PER_HOTEL_PAGE';

export const LOAD_ADMINS = 'APPLICATION/LOAD_ADMINS';
export const FETCH_LOAD_ADMINS = 'APPLICATION/FETCH_LOAD_ADMINS';
export const FETCH_LOAD_ADMINS_SUCCESS = 'APPLICATION/FETCH_LOAD_ADMINS_SUCCESS';
export const FETCH_LOAD_ADMINS_ERROR = 'APPLICATION/FETCH_LOAD_ADMINS_ERROR';

export const LOAD_COUNTRIES = 'APPLICATION/LOAD_COUNTRIES';
export const FETCH_LOAD_COUNTRIES = 'APPLICATION/FETCH_LOAD_COUNTRIES';
export const FETCH_LOAD_COUNTRIES_SUCCESS = 'APPLICATION/FETCH_LOAD_COUNTRIES_SUCCESS';
export const FETCH_LOAD_COUNTRIES_ERROR = 'APPLICATION/FETCH_LOAD_COUNTRIES_ERROR';

export const RESET_HOTEL_FETCH_STATUS = 'APPLICATION/RESET_HOTEL_FETCH_STATUS';

export const RESET_NEW_HOTEL_TOKEN = 'APPLICATION/RESET_NEW_HOTEL_TOKEN';

export const REMOVE_HOTEL_FROM_STATE = 'APPLICATION/REMOVE_HOTEL_FROM_STATE'

export interface ChangePasswordPayload {
    password: string;
    confirmation: string;
}

interface SetHotelInactiveStatusPayload {
    id: number;
    status: boolean;
    sagaIgnore?: boolean;
}

export interface SetHotelStatePayload {
    id: number;
    state: HotelState;
    sagaIgnore?: boolean;
}

interface SetHotelStarStatusPayload {
    id: number;
    categoryIDs?: number[];
    sagaIgnore?: boolean;
}

export interface UpdateHotelPayload {
    id: number;
    name: string;
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
}

interface LoadHotelsSuccessPayload {
    hotels: Hotel[];
    tags: Tag[];
}

export interface CreateOrUpdateHotelResponsePayload {
    hotel: Hotel;
    user: User;
    token?: string;
}

export const setLanguage = createAction<Language>(SET_LANGUAGE);

export const addApplicationMessage = createAction<ApplicationMessage>(ADD_APPLICATION_MESSAGE);
export const dismissApplicationMessage = createAction<string>(DISMISS_APPLICATION_MESSAGE);
export const removeApplicationMessage = createAction<string>(REMOVE_APPLICATION_MESSAGE);

export const loadHotels = createAction(LOAD_HOTELS);

export const fetchLoadHotels = createAction(FETCH_LOAD_HOTELS);
export const fetchLoadHotelsSuccess = createAction<LoadHotelsSuccessPayload>(FETCH_LOAD_HOTELS_SUCCESS);
export const fetchLoadHotelsError = createAction(FETCH_LOAD_HOTELS_ERROR);

export const setShowChangePasswordDialog = createAction<boolean>(SET_SHOW_CHANGE_PASSWORD_DIALOG);

export const changePassword = createAction<ChangePasswordPayload>(CHANGE_PASSWORD);
export const fetchChangePassword = createAction(FETCH_CHANGE_PASSWORD);
export const fetchChangePasswordSuccess = createAction(FETCH_CHANGE_PASSWORD_SUCCESS);
export const fetchChangePasswordError = createAction(FETCH_CHANGE_PASSWORD_ERROR);

export const toggleHotelSelection = createAction<number>(TOGGLE_HOTEL_SELECTION);
export const setHotelInactiveStatus = createAction<SetHotelInactiveStatusPayload>(SET_HOTEL_INACTIVE_STATUS);
export const setHotelState = createAction<SetHotelStatePayload>(SET_HOTEL_STATE);
export const setHotelStateSuccess = createAction<SetHotelStatePayload>(SET_HOTEL_STATE_SUCCESS);
export const setHotelStarStatus = createAction<SetHotelStarStatusPayload>(SET_HOTEL_STAR_STATUS);

export const saveHotel = createAction<UpdateHotelPayload>(SAVE_HOTEL);
export const fetchSaveHotel = createAction<number>(FETCH_SAVE_HOTEL);
export const fetchSaveHotelSuccess = createAction<number>(FETCH_SAVE_HOTEL_SUCCESS);
export const fetchSaveHotelError = createAction<number>(FETCH_SAVE_HOTEL_ERROR);

export const setUpdatedHotel = createAction<UpdateHotelPayload>(SET_UPDATED_HOTEL);

export const deleteHotel = createAction<number>(DELETE_HOTEL);
export const deleteHotelFromState = createAction<number>(DELETE_HOTEL_FROM_STATE);

export interface CreateOrSaveHotelPayload {
    id: number;
    pricingModel: PricingModel;
    frameContractNumber?: string;
    objectNumber: string;
    hotelName: string;
    street: string;
    streetNumber: string;
    zip: string;
    city: string;
    country: number;
    gender: Gender;
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
}

export const createHotel = createAction<CreateOrSaveHotelPayload>(CREATE_OR_UPDATE_HOTEL);
export const fetchCreateOrUpdateHotel = createAction(FETCH_CREATE_OR_UPDATE_HOTEL);
export const fetchCreateOrUpdateHotelSuccess = createAction<CreateOrUpdateHotelResponsePayload>(FETCH_CREATE_OR_UPDATE_HOTEL_SUCCESS);
export const fetchCreateOrUpdateHotelError = createAction(FETCH_CREATE_OR_UPDATE_HOTEL_ERROR);
export const resetCreateOrUpdateHotelFetchStatus = createAction(RESET_CREATE_OR_UPDATE_HOTEL_FETCH_STATUS);

export const sendNewTokenMail = createAction<number>(SEND_NEW_TOKEN_MAIL);
export const fetchSendNewTokenMail = createAction(FETCH_SEND_NEW_TOKEN_MAIL);
export const fetchSendNewTokenMailSuccess = createAction(FETCH_SEND_NEW_TOKEN_MAIL_SUCCESS);
export const fetchSendNewTokenMailError = createAction(FETCH_SEND_NEW_TOKEN_MAIL_ERROR);

export const setHotelPage = createAction<number>(SET_HOTEL_PAGE);
export const setRowsPerHotelPage = createAction<number>(SET_ROWS_PER_HOTEL_PAGE);

export const loadAdmins = createAction(LOAD_ADMINS);
export const fetchLoadAdmins = createAction(FETCH_LOAD_ADMINS);
export const fetchLoadAdminsSuccess = createAction<Admin[]>(FETCH_LOAD_ADMINS_SUCCESS);
export const fetchLoadAdminsError = createAction(FETCH_LOAD_ADMINS_ERROR);

export const resetHotelFetchStatus = createAction<number>(RESET_HOTEL_FETCH_STATUS);

export const loadCountries = createAction(LOAD_COUNTRIES);
export const fetchLoadCountries = createAction(FETCH_LOAD_COUNTRIES);
export const fetchLoadCountriesSuccess = createAction<Country[]>(FETCH_LOAD_COUNTRIES_SUCCESS);
export const fetchLoadCountriesError = createAction(FETCH_LOAD_COUNTRIES_ERROR);

export const resetNewHotelToken = createAction(RESET_NEW_HOTEL_TOKEN);

export const removeHotelFromState = createAction<number>(REMOVE_HOTEL_FROM_STATE);