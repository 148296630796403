import React from 'react';
import {makeStyles, Theme} from '@material-ui/core/styles';
import {Select, MenuItem, InputLabel, FormControl} from '@material-ui/core';
import * as classnames from 'classnames';

export interface SelectValue {
    id?: string;
    value: any;
    label?: string | React.ReactElement;
}


interface IftaSelectProps {
    values: SelectValue[];
    value?: number | string
    onChange: (e: React.ChangeEvent<{ name?: string, value: any }>) => void
    label: string
    emptyValue: string
    shrinkLabelClass?: string
    selectClass?: string
    className?: string
    menuItemClass?: string
    menuItemSelectedClass?: string
}

// @ts-ignore
const useStyles = makeStyles((theme: Theme) => ({
    root: {
        width: '100%',
        marginTop: -theme.spacing(2)
    },
    select: {
        ...theme.input,
        padding: theme.spacing(1.5, 2, 0, 2),
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(1.5, 2, 0, 2)
        }
    },
    label: {
        marginLeft: theme.spacing(2),
        marginTop: theme.spacing(3.5),
        fontSize: 12,
        lineHeight: 1,
        color: theme.palette.secondary.main,
        fontWeight: 500,
        zIndex: 2,
        transform: 'translate(0, -2.5px) scale(1)',
        [theme.breakpoints.down('sm')]: {
            marginLeft: theme.spacing(1.5)
        }
    },
    selectField: {
        marginTop: 5,
        fontSize: 16,
        fontWeight: 400,
        color: theme.palette.secondary.main,
        paddingBottom: theme.spacing(1),
        '&:focus': {
            background: 'white !important'
        }
    },
    icon: {
        marginTop: 10
    }
}));

const IftaSelect: React.FC<IftaSelectProps> = ({
                                                   values, value, onChange, label,
                                                   emptyValue, shrinkLabelClass,
                                                   selectClass, className, menuItemClass,
                                                   menuItemSelectedClass
                                               }) => {

    const classes = useStyles();

    return (
        <FormControl className={classes.root}>
            <InputLabel shrink={true} className={classnames(classes.label, shrinkLabelClass)}>
                {label}
            </InputLabel>
            <Select
                labelId={label}
                id={label}
                value={value !== undefined ? value : ''}
                onChange={onChange} className={classnames(classes.select, className)}
                classes={{
                    select: classnames(classes.selectField, selectClass),
                    icon: classes.icon
                }}
                displayEmpty={true}
                disableUnderline={true}>
                <MenuItem value={''} disabled={true} className={menuItemClass}>
                    {emptyValue}
                </MenuItem>
                {values.map(it => (
                    <MenuItem value={it.value} className={menuItemClass} key={`select-item-${it.label}-${it.value}`}
                     classes={{
                         selected: menuItemSelectedClass
                     }}>
                        {it.label}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
}

export default IftaSelect;
