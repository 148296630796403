import React, {useEffect, useMemo, useState} from 'react';
import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    makeStyles,
    Typography
} from '@material-ui/core';
import {Theme} from '@material-ui/core/styles';
import {useLocalization} from '../../../../localization/localization';
import {AppState, BlogCategory, Language, LocalizationKey} from '../../../../types/types';
import Ifta from '../../../forms/Ifta';
import Upload from '../../../forms/Upload';
import FetchStatus from '../../../../api/FetchStatus';
import {useDispatch, useSelector} from 'react-redux';
import {
    addNewBlogCategory,
    removeBlogCategoryImage, resetSaveBlogCategoryFetchStatus,
    saveBlogCategory,
    setBlogCategoryLocalization,
    setBlogCategoryResponsible,
    uploadBlogCategoryImageErrorCreator,
    uploadBlogCategoryImageFinishCreator,
    uploadBlogCategoryImageProgressCreator,
    uploadImage
} from '../../../../reducer/blog/types';
import {denormalize, getLocalizationValueForLanguageAndKey, mapImageToSrcSet} from '../../../../helper/helperFunctions';
import Image from '../../../helper/Image';
import {Clear} from '@material-ui/icons';
import IftaSelect from '../../../forms/IftaSelect';
import germanIcon from '../../../../images/icons/german.svg';
import englishIcon from '../../../../images/icons/english.svg';

interface EditCategoryDialogProps {
    open: boolean
    close: () => void
    activeFetch: boolean
    category: BlogCategory
    openSnackbar: (text: string, icon: any, level: FetchStatus) => void
}

const useStyles = makeStyles((theme: Theme) => ({
    root: {},
    paper: {
        maxWidth: 2000
    },
    cancelButton: {
        border: '1px solid #193150',
        color: '#193150',
        background: 'white',
        fontSize: 18,
        fontWeight: 600,
        textTransform: 'none',
        marginRight: theme.spacing(2),
        boxShadow: 'none',
        width: 300,
        height: 48,
        '&:hover': {
            boxShadow: 'none'
        }
    },
    submitButton: {
        background: theme.palette.primary.main,
        color: 'white',
        textTransform: 'none',
        fontSize: 18,
        fontWeight: 600,
        boxShadow: 'none',
        width: 230,
        height: 48,
        '&:hover': {
            boxShadow: 'none'
        }
    },
    progress: {},
    title: {
        padding: theme.spacing(3, 5, 1.5)
    },
    content: {
        padding: theme.spacing(2, 5)
    },
    actions: {
        padding: theme.spacing(0, 5, 3)
    },
    row: {
        marginBottom: theme.spacing(2)
    },
    headline: {
        fontSize: 28,
        fontWeight: 600,
        color: '#193150',
        lineHeight: '36px'
    },
    uploadSurround: {
        width: '100%',
        height: 280,
        border: `2px dashed ${theme.palette.primary.light}`,
        borderRadius: 6,
        marginBottom: theme.spacing(2),
        position: 'relative'
    },
    previewImage: {
        position: 'absolute',
        maxWidth: `calc(100% - ${theme.spacing(4)}px)`,
        maxHeight: `calc(100% - ${theme.spacing(4)}px)`,
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)'
    },
    deleteImageButton: {
        width: 24,
        height: 24,
        backgroundColor: '#FF5252',
        position: 'absolute',
        right: 5,
        top: 5
    },
    deleteImageIcon: {
        color: 'white',
        width: 17,
        height: 17
    },
    languageButton: {
        boxShadow: '0 0 1px 0 rgba(0,0,0,0.2), 0 4px 8px 0 rgba(0,0,0,0.12)',
        background: 'white',
        textTransform: 'none',
        fontSize: 15,
        fontWeight: 600,
        color: '#193150',
        marginLeft: theme.spacing(2),
        height: 42,
        width: 130
    },
    languageIcon: {
        width: 24,
        height: 24,
        marginRight: theme.spacing(1)
    },
    titleSurround: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    }
}));

const EditCategoryDialog: React.FC<EditCategoryDialogProps> = ({
                                                                   open, close,
                                                                   activeFetch, category, openSnackbar
                                                               }) => {
    const classes = useStyles();
    const messages = useLocalization();
    const dispatch = useDispatch();

    const [selectedLanguage, setSelectedLanguage] = useState(Language.GERMAN);
    const {admins} = useSelector((state: AppState) => ({
        admins: state.application.admins
    }))

    const imageSet = useMemo(() => {
        const path = category.BlogImages[0]?.path || '';
        const type = category.BlogImages[0]?.type || '';
        return mapImageToSrcSet(path, type);
    }, [category.BlogImages]);

    const usedAdmins = useMemo(() => denormalize(admins), [admins]);

    const canBeSubmitted = useMemo(() => Boolean(imageSet) && Boolean(category.responsibleAdmin)
        && Boolean(category.Localizations.filter(it => it.key === LocalizationKey.BlogCategoryName && Boolean(it.value?.length)).length)
        && Boolean(category.Localizations.filter(it => it.key === LocalizationKey.BlogCategoryDescription && Boolean(it.value?.length)).length), [category, imageSet]);

    useEffect(() => {
        if (category.saveFetchStatus === FetchStatus.SUCCESS) {
            openSnackbar(messages.blog.categories.manage.success, null, FetchStatus.SUCCESS);
            dispatch(resetSaveBlogCategoryFetchStatus(category.id));
            if (category.id === -1) {
                // Category was saved, reset the creation category state
                dispatch(addNewBlogCategory());
            }
            close();
        } else if (category.saveFetchStatus === FetchStatus.ERROR) {
            openSnackbar(messages.blog.categories.manage.error, null, FetchStatus.ERROR);
        }
    }, [category.saveFetchStatus, messages, dispatch, openSnackbar, category.id, close]);

    return (
        <Dialog open={open} className={classes.root} classes={{
            paper: classes.paper
        }} onClose={close} disableBackdropClick={true}>
            <DialogTitle className={classes.title}>
                <div className={classes.titleSurround}>
                    <Typography variant={'h2'} className={classes.headline}>
                        {Boolean(category) ? messages.blog.categories.edit.title : messages.blog.categories.create.title}
                    </Typography>
                    <Button variant={'contained'} className={classes.languageButton} onClick={() => {
                        const nextLanguage = selectedLanguage === Language.GERMAN ? Language.ENGLISH : Language.GERMAN;
                        if (nextLanguage === Language.ENGLISH) {
                            // Check if english values are available, if not, populate it with the german values
                            const name = getLocalizationValueForLanguageAndKey(category.Localizations, LocalizationKey.BlogCategoryName, nextLanguage);
                            if (!name) {
                                dispatch(setBlogCategoryLocalization({
                                    language: Language.ENGLISH,
                                    value: getLocalizationValueForLanguageAndKey(category.Localizations, LocalizationKey.BlogCategoryName, Language.GERMAN),
                                    key: LocalizationKey.BlogCategoryName,
                                    id: category.id
                                }));
                                dispatch(setBlogCategoryLocalization({
                                    language: Language.ENGLISH,
                                    value: getLocalizationValueForLanguageAndKey(category.Localizations, LocalizationKey.BlogCategoryDescription, Language.GERMAN),
                                    key: LocalizationKey.BlogCategoryDescription,
                                    id: category.id
                                }));
                            }
                        }
                        setSelectedLanguage(nextLanguage);
                    }}>
                        <img src={selectedLanguage === Language.GERMAN ? germanIcon : englishIcon}
                             alt={selectedLanguage.toString()} title={selectedLanguage.toString()}
                             className={classes.languageIcon}/>
                        {selectedLanguage === Language.GERMAN ? messages.language.german : messages.language.english}
                    </Button>
                </div>
            </DialogTitle>
            <DialogContent className={classes.content}>
                <div className={classes.uploadSurround}>
                    <Upload progress={category.imageUploadProgress || 0}
                            buttonText={messages.blog.categories.upload.button} callback={(file) => {
                        dispatch(uploadImage({
                            onProgress: uploadBlogCategoryImageProgressCreator(category.id),
                            onFinish: uploadBlogCategoryImageFinishCreator(category.id),
                            onError: uploadBlogCategoryImageErrorCreator(category.id),
                            file
                        }))
                    }} description={messages.blog.categories.upload.description}
                            subLine={messages.blog.categories.upload.subLine}
                            fetchStatus={category?.imageUploadFetchStatus || FetchStatus.DEFAULT}/>
                    {imageSet !== undefined && <>
                        <Image srcSet={imageSet} alt={''} className={classes.previewImage}/>
                        <IconButton className={classes.deleteImageButton}
                                    onClick={() => dispatch(removeBlogCategoryImage(category.id))}>
                            <Clear className={classes.deleteImageIcon}/>
                        </IconButton>
                    </>}
                </div>
                <div className={classes.row}>
                    <Ifta
                        value={getLocalizationValueForLanguageAndKey(category.Localizations, LocalizationKey.BlogCategoryName, selectedLanguage)}
                        label={messages.blog.categories.manage.name.label}
                        name={'manage-category-name'} placeholder={messages.blog.categories.manage.name.placeholder}
                        onChange={e => dispatch(setBlogCategoryLocalization({
                            id: category.id,
                            key: LocalizationKey.BlogCategoryName,
                            language: selectedLanguage,
                            value: e.currentTarget.value
                        }))}/>
                </div>
                <div className={classes.row}>
                    <Ifta
                        value={getLocalizationValueForLanguageAndKey(category.Localizations, LocalizationKey.BlogCategoryDescription, selectedLanguage)}
                        label={messages.blog.categories.manage.description.label} multiline={true}
                        rows={4}
                        name={'manage-category-description'}
                        placeholder={messages.blog.categories.manage.description.placeholder}
                        onChange={e => dispatch(setBlogCategoryLocalization({
                            id: category.id,
                            key: LocalizationKey.BlogCategoryDescription,
                            language: selectedLanguage,
                            value: e.currentTarget.value
                        }))}/>
                </div>
                <div className={classes.row}>
                    <IftaSelect values={usedAdmins.map(it => ({
                        id: it.id.toString(10),
                        label: `${it.firstName} ${it.lastName}`,
                        value: it.id
                    }))} onChange={e =>
                        dispatch(setBlogCategoryResponsible({
                            categoryID: category.id,
                            admin: admins[e.target.value]
                        }))}
                                label={messages.blog.categories.manage.responsible.label}
                                emptyValue={messages.blog.categories.manage.responsible.placeholder}
                                value={category.responsibleAdmin?.id}/>
                </div>
            </DialogContent>
            <DialogActions className={classes.actions}>
                <Button className={classes.cancelButton} variant={'contained'} onClick={() => close()}>
                    {category.id > -1 ? messages.blog.categories.edit.cancel : messages.blog.categories.create.cancel}
                </Button>
                <Button disabled={category.saveFetchStatus === FetchStatus.ACTIVE || !canBeSubmitted}
                        className={classes.submitButton} variant={'contained'} color={'primary'}
                        onClick={() => dispatch(saveBlogCategory(category.id))}>
                    {activeFetch && <CircularProgress className={classes.progress}/>}
                    {category.id > -1 ? messages.blog.categories.edit.submit : messages.blog.categories.create.submit}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default EditCategoryDialog;