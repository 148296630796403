import {createAction, PayloadAction} from '@reduxjs/toolkit';
import {
    Admin,
    BlogCategory,
    BlogImage,
    BlogPost,
    BlogPostState,
    BlogTag,
    Language,
    LocalizationKey
} from '../../types/types';

export const LOAD_CATEGORIES = 'BLOG/LOAD_CATEGORIES';
export const FETCH_LOAD_CATEGORIES = 'BLOG/FETCH_LOAD_CATEGORIES';
export const FETCH_LOAD_CATEGORIES_SUCCESS = 'BLOG/FETCH_LOAD_CATEGORIES_SUCCESS';
export const FETCH_LOAD_CATEGORIES_ERROR = 'BLOG/FETCH_LOAD_CATEGORIES_ERROR';
export const RESET_LOAD_CATEGORIES_FETCH_STATUS = 'BLOG/RESET_LOAD_CATEGORIES_FETCH_STATUS';

export const ADD_NEW_BLOG_CATEGORY = 'BLOG/ADD_NEW_BLOG_CATEGORY';
export const SET_BLOG_CATEGORY_LOCALIZATION = 'BLOG/SET_BLOG_CATEGORY_LOCALIZATION';
export const SET_BLOG_CATEGORY_RESPONSIBLE = 'BLOG/SET_BLOG_CATEGORY_RESPONSIBLE';

export const UPLOAD_IMAGE = 'BLOG/UPLOAD_IMAGE';
export const UPLOAD_BLOG_CATEGORY_IMAGE_PROGRESS = 'BLOG/UPLOAD_BLOG_CATEGORY_IMAGE_PROGRESS';
export const UPLOAD_BLOG_CATEGORY_IMAGE_SUCCESS = 'BLOG/UPLOAD_BLOG_CATEGORY_IMAGE_SUCCESS';
export const UPLOAD_BLOG_CATEGORY_IMAGE_ERROR = 'BLOG/UPLOAD_BLOG_CATEGORY_IMAGE_ERROR';

export const SAVE_BLOG_CATEGORY = 'BLOG/SAVE_BLOG_CATEGORY';
export const FETCH_SAVE_BLOG_CATEGORY = 'BLOG/FETCH_SAVE_BLOG_CATEGORY';
export const FETCH_SAVE_BLOG_CATEGORY_SUCCESS = 'BLOG/FETCH_SAVE_BLOG_CATEGORY_SUCCESS';
export const FETCH_SAVE_BLOG_CATEGORY_ERROR = 'BLOG/FETCH_SAVE_BLOG_CATEGORY_ERROR';
export const RESET_SAVE_BLOG_CATEGORY_FETCH_STATUS = 'BLOG/RESET_SAVE_BLOG_CATEGORY_FETCH_STATUS';

export const DELETE_BLOG_CATEGORY = 'BLOG/DELETE_BLOG_CATEGORY';
export const FETCH_DELETE_BLOG_CATEGORY = 'BLOG/FETCH_DELETE_BLOG_CATEGORY';
export const FETCH_DELETE_BLOG_CATEGORY_SUCCESS = 'BLOG/FETCH_DELETE_BLOG_CATEGORY_SUCCESS';
export const FETCH_DELETE_BLOG_CATEGORY_ERROR = 'BLOG/FETCH_DELETE_BLOG_CATEGORY_ERROR';
export const REMOVE_BLOG_CATEGORY_FROM_STATE = 'BLOG/REMOVE_BLOG_CATEGORY_FROM_STATE';

export const REMOVE_BLOG_CATEGORY_IMAGE = 'BLOG/REMOVE_BLOG_CATEGORY_IMAGE';
export const SET_CREATED_CATEGORY_SUCCESS_FETCH_STATUS = 'BLOG/SET_CREATED_CATEGORY_SUCCESS_FETCH_STATUS';

export const LOAD_CATEGORY_BLOG_POSTS = 'BLOG/LOAD_CATEGORY_BLOG_POSTS';
export const FETCH_LOAD_CATEGORY_BLOG_POSTS = 'BLOG/FETCH_LOAD_CATEGORY_BLOG_POSTS';
export const FETCH_LOAD_CATEGORY_BLOG_POSTS_SUCCESS = 'BLOG/FETCH_LOAD_CATEGORY_BLOG_POSTS_SUCCESS';
export const FETCH_LOAD_CATEGORY_BLOG_POSTS_ERROR = 'BLOG/FETCH_LOAD_CATEGORY_BLOG_POSTS_ERROR';
export const RESET_LOAD_CATEGORY_BLOG_POSTS_FETCH_STATUS = 'BLOG/RESET_LOAD_CATEGORY_BLOG_POSTS_FETCH_STATUS';

export const ADD_NEW_CATEGORY_BLOG_POST = 'BLOG/ADD_NEW_CATEGORY_BLOG_POST';
export const SET_BLOG_CATEGORY_POST_LOCALIZATION = 'BLOG/SET_BLOG_CATEGORY_POST_LOCALIZATION';

export const UPLOAD_BLOG_POST_IMAGE_PROGRESS = 'BLOG/UPLOAD_BLOG_POST_IMAGE_PROGRESS';
export const UPLOAD_BLOG_POST_IMAGE_SUCCESS = 'BLOG/UPLOAD_BLOG_POST_IMAGE_SUCCESS';
export const UPLOAD_BLOG_POST_IMAGE_ERROR = 'BLOG/UPLOAD_BLOG_POST_IMAGE_ERROR';
export const REMOVE_BLOG_POST_IMAGE = 'BLOG/REMOVE_BLOG_POST_IMAGE';
export const SET_BLOG_POST_TAGS = 'BLOG/SET_BLOG_POST_TAGS';
export const SET_BLOG_POST_STATE = 'BLOG/SET_BLOG_POST_STATE';

export const SAVE_BLOG_POST = 'BLOG/SAVE_BLOG_POST';
export const FETCH_SAVE_BLOG_POST = 'BLOG/FETCH_SAVE_BLOG_POST';
export const FETCH_SAVE_BLOG_POST_SUCCESS = 'BLOG/FETCH_SAVE_BLOG_POST_SUCCESS';
export const FETCH_SAVE_BLOG_POST_ERROR = 'BLOG/FETCH_SAVE_BLOG_POST_ERROR';
export const RESET_SAVE_BLOG_POST_FETCH_STATUS = 'BLOG/RESET_SAVE_BLOG_POST_FETCH_STATUS';
export const SET_CREATED_POST_SUCCESS_FETCH_STATUS = 'BLOG/SET_CREATED_POST_SUCCESS_FETCH_STATUS';

export const DELETE_BLOG_POST = 'BLOG/DELETE_BLOG_POST';
export const FETCH_DELETE_BLOG_POST = 'BLOG/FETCH_DELETE_BLOG_POST';
export const FETCH_DELETE_BLOG_POST_SUCCESS = 'BLOG/FETCH_DELETE_BLOG_POST_SUCCESS';
export const FETCH_DELETE_BLOG_POST_ERROR = 'BLOG/FETCH_DELETE_BLOG_POST_ERROR';
export const REMOVE_BLOG_POST_FROM_STATE = 'BLOG/REMOVE_BLOG_POST_FROM_STATE';

export const UPDATE_BLOG_POST_STATE = 'BLOG/UPDATE_BLOG_POST_STATE';
export const FETCH_UPDATE_BLOG_POST_STATE = 'BLOG/FETCH_UPDATE_BLOG_POST_STATE';
export const FETCH_UPDATE_BLOG_POST_STATE_SUCCESS = 'BLOG/FETCH_UPDATE_BLOG_POST_STATE_SUCCESS';
export const FETCH_UPDATE_BLOG_POST_STATE_ERROR = 'BLOG/FETCH_UPDATE_BLOG_POST_STATE_ERROR';
export const RESET_UPDATE_BLOG_POST_STATE_FETCH_STATUS = 'BLOG/RESET_UPDATE_BLOG_POST_STATE_FETCH_STATUS';

export const HIGHLIGHT_BLOG_POST = 'BLOG/HIGHLIGHT_BLOG_POST';
export const FETCH_HIGHLIGHT_BLOG_POST = 'BLOG/FETCH_HIGHLIGHT_BLOG_POST';
export const FETCH_HIGHLIGHT_BLOG_POST_SUCCESS = 'BLOG/FETCH_HIGHLIGHT_BLOG_POST_SUCCESS';
export const FETCH_HIGHLIGHT_BLOG_POST_ERROR = 'BLOG/FETCH_HIGHLIGHT_BLOG_POST_ERROR';
export const RESET_HIGHLIGHT_BLOG_POST_FETCH_STATUS = 'BLOG/RESET_HIGHLIGHT_BLOG_POST_FETCH_STATUS';

export const loadCategories = createAction(LOAD_CATEGORIES);
export const fetchLoadCategories = createAction(FETCH_LOAD_CATEGORIES);
export const fetchLoadCategoriesSuccess = createAction<BlogCategory[]>(FETCH_LOAD_CATEGORIES_SUCCESS);
export const fetchLoadCategoriesError = createAction(FETCH_LOAD_CATEGORIES_ERROR);
export const resetLoadCategoriesFetchStatus = createAction(RESET_LOAD_CATEGORIES_FETCH_STATUS);


export interface UploadProgressPayload {
    progress: number
}

export interface UploadBlogCategoryImageProgressPayload extends UploadProgressPayload {
    categoryID: number
}

export interface UploadBlogPostImageProgressPayload extends UploadProgressPayload {
    postID: number
}

export interface UploadSuccessPayload {
    image: BlogImage
}

export interface UploadBlogCategoryImageSuccessPayload extends UploadSuccessPayload {
    categoryID: number
}

export interface UploadBlogPostImageSuccessPayload extends UploadSuccessPayload {
    postID: number
}

export interface UploadErrorPayload {
}

export interface UploadBlogCategoryImageErrorPayload extends UploadErrorPayload {
    categoryID: number
}

export interface UploadBlogPostImageErrorPayload extends UploadErrorPayload {
    postID: number
}

export interface UploadImagePayload {
    file: File
    onProgress: (progress: number) => PayloadAction<UploadProgressPayload>
    onFinish: (image: BlogImage) => PayloadAction<UploadSuccessPayload>
    onError: () => PayloadAction<UploadErrorPayload>
}

export const uploadImage = createAction<UploadImagePayload>(UPLOAD_IMAGE);


export const uploadBlogCategoryImageProgressCreator = (categoryID: number) =>
    (progress: number) => uploadBlogCategoryImageProgress({
        progress,
        categoryID
    });

export const uploadBlogCategoryImageFinishCreator = (categoryID: number) =>
    (image: BlogImage) => uploadBlogCategoryImageFinish({
        image,
        categoryID
    });

export const uploadBlogCategoryImageErrorCreator = (categoryID: number) => () => uploadBlogCategoryImageError({categoryID});

export const uploadBlogCategoryImageProgress = createAction<UploadBlogCategoryImageProgressPayload>(UPLOAD_BLOG_CATEGORY_IMAGE_PROGRESS);
export const uploadBlogCategoryImageFinish = createAction<UploadBlogCategoryImageSuccessPayload>(UPLOAD_BLOG_CATEGORY_IMAGE_SUCCESS);
export const uploadBlogCategoryImageError = createAction<UploadBlogCategoryImageErrorPayload>(UPLOAD_BLOG_CATEGORY_IMAGE_ERROR);

export const addNewBlogCategory = createAction(ADD_NEW_BLOG_CATEGORY);
export const removeBlogCategoryImage = createAction<number>(REMOVE_BLOG_CATEGORY_IMAGE);
export const setCreatedCategorySuccessFetchStatus = createAction(SET_CREATED_CATEGORY_SUCCESS_FETCH_STATUS);

export interface SetBlogLocalizationPayload {
    id: number
    language: Language
    key: LocalizationKey
    value: string
}

export const setBlogCategoryLocalization = createAction<SetBlogLocalizationPayload>(SET_BLOG_CATEGORY_LOCALIZATION);

export interface SetBlogCategoryResponsiblePayload {
    categoryID: number
    admin: Admin | undefined
}
export const setBlogCategoryResponsible = createAction<SetBlogCategoryResponsiblePayload>(SET_BLOG_CATEGORY_RESPONSIBLE);

export const saveBlogCategory = createAction<number>(SAVE_BLOG_CATEGORY);
export const fetchSaveBlogCategory = createAction<number>(FETCH_SAVE_BLOG_CATEGORY);
export const fetchSaveBlogCategorySuccess = createAction<BlogCategory>(FETCH_SAVE_BLOG_CATEGORY_SUCCESS);
export const fetchSaveBlogCategoryError = createAction<number>(FETCH_SAVE_BLOG_CATEGORY_ERROR);
export const resetSaveBlogCategoryFetchStatus = createAction<number>(RESET_SAVE_BLOG_CATEGORY_FETCH_STATUS);

export const deleteBlogCategory = createAction<number>(DELETE_BLOG_CATEGORY);
export const fetchDeleteBlogCategory = createAction<number>(FETCH_DELETE_BLOG_CATEGORY);
export const fetchDeleteBlogCategorySuccess = createAction<number>(FETCH_DELETE_BLOG_CATEGORY_SUCCESS);
export const fetchDeleteBlogCategoryError = createAction<number>(FETCH_DELETE_BLOG_CATEGORY_ERROR);
export const removeBlogCategoryFromState = createAction<number>(REMOVE_BLOG_CATEGORY_FROM_STATE);

export const loadBlogCategoryPosts = createAction<number>(LOAD_CATEGORY_BLOG_POSTS);
export const fetchLoadBlogCategoryPosts = createAction<number>(FETCH_LOAD_CATEGORY_BLOG_POSTS);

export interface FetchLoadBlogCategoryPostsSuccessPayload {
    categoryID: number
    posts: BlogPost[]
}

export const fetchLoadBlogCategoryPostsSuccess = createAction<FetchLoadBlogCategoryPostsSuccessPayload>(FETCH_LOAD_CATEGORY_BLOG_POSTS_SUCCESS);

export const fetchLoadBlogCategoryPostsError = createAction<number>(FETCH_LOAD_CATEGORY_BLOG_POSTS_ERROR);

export const resetLoadBlogCategoryPostsFetchStatus = createAction<number>(RESET_LOAD_CATEGORY_BLOG_POSTS_FETCH_STATUS);

export const addNewCategoryBlogPost = createAction<number>(ADD_NEW_CATEGORY_BLOG_POST);
export const setBlogCategoryPostLocalization = createAction<SetBlogLocalizationPayload>(SET_BLOG_CATEGORY_POST_LOCALIZATION);

export const uploadBlogPostImageProgressCreator = (postID: number) =>
    (progress: number) => uploadBlogPostImageProgress({
        progress,
        postID
    });

export const uploadBlogPostImageFinishCreator = (postID: number) =>
    (image: BlogImage) => uploadBlogPostImageFinish({
        image,
        postID
    });

export const uploadBlogPostImageErrorCreator = (postID: number) => () => uploadBlogPostImageError({postID});

export const uploadBlogPostImageProgress = createAction<UploadBlogPostImageProgressPayload>(UPLOAD_BLOG_POST_IMAGE_PROGRESS);
export const uploadBlogPostImageFinish = createAction<UploadBlogPostImageSuccessPayload>(UPLOAD_BLOG_POST_IMAGE_SUCCESS);
export const uploadBlogPostImageError = createAction<UploadBlogPostImageErrorPayload>(UPLOAD_BLOG_POST_IMAGE_ERROR);
export const removeBlogPostImage = createAction<number>(REMOVE_BLOG_POST_IMAGE);

export interface SetBlogPostTagsPayload {
    id: number;
    tags: BlogTag[]
}

export const setBlogPostTags = createAction<SetBlogPostTagsPayload>(SET_BLOG_POST_TAGS);

export const saveBlogPost = createAction<number>(SAVE_BLOG_POST);
export const fetchSaveBlogPost = createAction<number>(FETCH_SAVE_BLOG_POST);
export const fetchSaveBlogPostSuccess = createAction<BlogPost>(FETCH_SAVE_BLOG_POST_SUCCESS);
export const fetchSaveBlogPostError = createAction<number>(FETCH_SAVE_BLOG_POST_ERROR);
export const resetSaveBlogPostFetchStatus = createAction<number>(RESET_SAVE_BLOG_POST_FETCH_STATUS);
export const setCreatedPostSuccessFetchStatus = createAction(SET_CREATED_POST_SUCCESS_FETCH_STATUS);

export const deleteBlogPost = createAction<number>(DELETE_BLOG_POST);
export const fetchDeleteBlogPost = createAction<number>(FETCH_DELETE_BLOG_POST);
export const fetchDeleteBlogPostSuccess = createAction<number>(FETCH_DELETE_BLOG_POST_SUCCESS);
export const fetchDeleteBlogPostError = createAction<number>(FETCH_DELETE_BLOG_POST_ERROR);
export const removeBlogPostFromState = createAction<number>(REMOVE_BLOG_POST_FROM_STATE);

export interface SetBlogPostStatePayload {
    id: number;
    state: BlogPostState;
}

export const updateBlogPostState = createAction<SetBlogPostStatePayload>(UPDATE_BLOG_POST_STATE);
export const fetchUpdateBlogPostState = createAction<SetBlogPostStatePayload>(FETCH_UPDATE_BLOG_POST_STATE);
export const fetchUpdateBlogPostStateSuccess = createAction<SetBlogPostStatePayload>(FETCH_UPDATE_BLOG_POST_STATE_SUCCESS);
export const fetchUpdateBlogPostStateError = createAction<SetBlogPostStatePayload>(FETCH_UPDATE_BLOG_POST_STATE_ERROR);
export const resetUpdateBlogPostStateFetchStatus = createAction<number>(RESET_UPDATE_BLOG_POST_STATE_FETCH_STATUS);

export interface HighlightBlogPostPayload {
    id: number;
    highlighted: boolean;
}

export const highlightBlogPost = createAction<HighlightBlogPostPayload>(HIGHLIGHT_BLOG_POST);
export const fetchHighlightBlogPost = createAction<HighlightBlogPostPayload>(FETCH_HIGHLIGHT_BLOG_POST);
export const fetchHighlightBlogPostSuccess = createAction<HighlightBlogPostPayload>(FETCH_HIGHLIGHT_BLOG_POST_SUCCESS);
export const fetchHighlightBlogPostError = createAction<HighlightBlogPostPayload>(FETCH_HIGHLIGHT_BLOG_POST_ERROR);
export const resetHighlightBlogPostFetchStatus = createAction<number>(RESET_HIGHLIGHT_BLOG_POST_FETCH_STATUS);

export const setBlogPostState = createAction<SetBlogPostStatePayload>(SET_BLOG_POST_STATE);