import {UserState} from '../../types/types';
import FetchStatus from '../../api/FetchStatus';
import {createReducer} from '@reduxjs/toolkit';
import {FETCH_LOGIN_USER, fetchLoginUserError, fetchLoginUserSuccess, logout} from './types';


const initialState: UserState = {
    jwt: undefined,
    data: undefined,
    loginFetchStatus: FetchStatus.DEFAULT
};

export default createReducer<UserState>(initialState, builder =>
    builder.addCase(logout, (state) => ({
        ...initialState
    })).addCase(FETCH_LOGIN_USER, (state) => ({
        ...state,
        ...{
            loginFetchStatus: FetchStatus.ACTIVE
        }
    })).addCase(fetchLoginUserSuccess, (state, action) => ({
        ...state,
        ...{
            loginFetchStatus: FetchStatus.SUCCESS,
            jwt: action.payload.token,
            data: action.payload.user
        }
    })).addCase(fetchLoginUserError, state => ({
        ...state,
        ...{
            loginFetchStatus: FetchStatus.ERROR,
            jwt: undefined,
            data: undefined
        }
    }))
);