import React from 'react';
import {makeStyles, Theme} from '@material-ui/core/styles';
import {TextField} from '@material-ui/core';
import {IftaProps} from './Ifta.types';
import classnames from 'classnames';

const useStyles = makeStyles((theme: Theme) => ({
    shrinkLabel: {
        marginLeft: theme.spacing(2),
        marginTop: theme.spacing(3.5),
        fontSize: 10,
        lineHeight: 1,
        color: theme.palette.secondary.main,
        fontWeight: 400,
        zIndex: 2,
        letterSpacing: 0,
        transform: 'translate(0, 1.5px)',
        [theme.breakpoints.down('sm')]: {
            marginLeft: theme.spacing(2)
        }
    },
    input: {
        ...theme.input,
        color: theme.palette.secondary.main,
        boxSizing: 'initial',
        background: 'white',
        fontSize: 16,
        padding: theme.spacing(3, 2, 1.5),
        fontWeight: 400,
        position: 'relative',
        zIndex: 1,
        '&::placeholder': {
            color: '#BFC4CE',
            fontWeight: 400
        },
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(3, 2, 1.5),
        }
    },
    textField: {
        width: '100%',
        marginTop: -theme.spacing(2)
    },
    adorned: {
        position: 'absolute',
        right: theme.spacing(2)
    }
}));

const Ifta: React.FC<IftaProps> = ({
                                       label, name, onChange, onKeyUp, placeholder,
                                       value,
                                       type, endAdornment,
                                       multiline, rows, onFocus, onBlur,
                                       defaultValue, className, classes, ...rest
                                   }) => {

    const styles = useStyles();

    const params = {
        ...rest,
        multiline: multiline,
        rows: rows,
        name: name,
        value: value,
        placeholder: placeholder,
        className: classnames(styles.textField, className, classes?.root),
        label: label,
        type: type,
        defaultValue,
        onChange,
        onKeyUp,
        onBlur,
        InputLabelProps: {
            ...(rest as any)?.InputLabelProps,
            shrink: true,
            classes: {
                shrink: classnames(styles.shrinkLabel, classes?.label),
            }
        },
        InputProps: {
            ...(rest as any)?.InputProps,
            classes: {
                input: classnames(styles.input, classes?.input)
            },
            disableUnderline: true,
            endAdornment: endAdornment
        },
        inputProps: {
            ...(rest as any)?.inputProps
        }
    }


    return (<TextField {...params} />);
}

export default Ifta;