import React, {useEffect, useMemo, useState} from 'react';
import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    Grid,
    makeStyles,
    Typography
} from '@material-ui/core';
import {Theme} from '@material-ui/core/styles/createTheme';
import {AppState, Gender, Hotel, PricingModel, SnackbarVariant} from '../../../types/types';
import {useDispatch, useSelector} from 'react-redux';
import {useLocalization} from '../../../localization/localization';
import DialogTitle from '@material-ui/core/DialogTitle';
import IftaSelect from '../../forms/IftaSelect';
import Ifta from '../../forms/Ifta';
import {denormalize} from '../../../helper/helperFunctions';
import classNames from 'classnames';
import {
    addApplicationMessage,
    createHotel,
    resetCreateOrUpdateHotelFetchStatus
} from '../../../reducer/application/types';
import FetchStatus from '../../../api/FetchStatus';

interface HotelDialogProps {
    hotel?: Hotel;
    open: boolean;
    close: () => void;
}

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        width: 586,
        maxWidth: 'auto'
    },
    dialogTitle: {
        '&>h2': {
            color: '#193150',
            fontSize: 24,
            fontWeight: 600,
            letterSpacing: -0.5,
            '&>span': {
                fontWeight: 400
            }
        },
        marginBottom: 0,
        paddingBottom: theme.spacing(1)
    },
    title: {
        color: '#193150',
        fontWeight: 600,
        fontSize: 16,
        marginBottom: theme.spacing(1)
    },
    grid: {
        marginBottom: theme.spacing(2)
    },
    actions: {
        borderTop: '1px solid #C1C5CB',
        padding: theme.spacing(2.5, 3)
    },
    button: {
        padding: theme.spacing(1.75, 2),
        fontSize: 16,
        lineHeight: '19px',
        fontWeight: 600,
        textTransform: 'none',
        boxShadow: 'none',
        minHeight: 0,
        '&:hover': {
            boxShadow: 'none'
        }
    },
    cancel: {
        color: '#193150',
        border: '1px solid #193150',
        background: 'white'
    },
    submit: {},
    progress: {
        width: '16px !important',
        height: '16px !important',
        color: 'white',
        marginRight: theme.spacing(1)
    }
}));

const HotelDialog: React.FC<HotelDialogProps> = ({hotel, open, close}) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const messages = useLocalization();
    const [pricingModel, setPricingModel] = useState<PricingModel>(hotel?.pricingModel || PricingModel.Fix);
    const [frameContractNumber, setFrameContractNumber] = useState<string>(hotel?.FrameContractNumber?.number || '');
    const [objectNumber, setObjectNumber] = useState<string>(hotel?.objectNumber || '');
    const [hotelName, setHotelName] = useState<string>(hotel?.name || '');
    const [street, setStreet] = useState<string>(hotel?.street || '');
    const [streetNumber, setStreetNumber] = useState<string>(hotel?.streetNumber || '');
    const [zip, setZip] = useState<string>(hotel?.zip || '');
    const [city, setCity] = useState<string>(hotel?.city || '');
    const [country, setCountry] = useState<number | undefined>(hotel?.Country?.id || undefined);
    const [gender, setGender] = useState<number | undefined>(hotel?.User?.gender !== undefined ? hotel?.User?.gender : undefined);
    const [firstName, setFirstName] = useState<string>(hotel?.User?.firstName || '');
    const [lastName, setLastName] = useState<string>(hotel?.User?.lastName || '');
    const [email, setEmail] = useState<string>(hotel?.User?.email || '');
    const [phone, setPhone] = useState<string>(hotel?.User?.phone || '');

    const {countries, fetchStatus} = useSelector((state: AppState) => ({
        countries: state.application.countries,
        fetchStatus: state.application.createOrUpdateHotelFetchStatus
    }));

    const getLabelForPricingModel = (pricingModel: PricingModel) => {
        switch (pricingModel) {
            case PricingModel.Provision:
                return messages['hotels.pricingModel.provision'];
            case PricingModel.Fix:
            default:
                return messages['hotels.pricingModel.fix'];
        }
    }

    const canBeSaved = useMemo(() => {
        let canBeSaved = pricingModel === PricingModel.Fix || (pricingModel === PricingModel.Provision && Boolean(frameContractNumber.trim()));
        canBeSaved = canBeSaved && Boolean(objectNumber.trim()) && Boolean(hotelName.trim()) && Boolean(street.trim()) && Boolean(zip.trim()) && Boolean(city.trim()) && Boolean(country)
            && gender !== undefined && Boolean(firstName.trim()) && Boolean(lastName.trim()) && Boolean(email.trim()) && Boolean(phone.trim());
        return canBeSaved;
    }, [pricingModel, frameContractNumber, objectNumber, hotelName, street, zip, city, country, gender, firstName, lastName, email, phone]);

    useEffect(() => {
        if (fetchStatus === FetchStatus.SUCCESS) {
            dispatch(addApplicationMessage({
                key: `save-hotel-success-${new Date().getTime()}`,
                variant: SnackbarVariant.SUCCESS,
                text: messages.hotels.edit.success
            }));
            dispatch(resetCreateOrUpdateHotelFetchStatus());
            close();
        } else if (fetchStatus === FetchStatus.ERROR) {
            dispatch(addApplicationMessage({
                key: `save-hotel-error-${new Date().getTime()}`,
                variant: SnackbarVariant.ERROR,
                text: messages.hotels.edit.error
            }));
            dispatch(resetCreateOrUpdateHotelFetchStatus());
        }
    }, [fetchStatus, dispatch, messages.hotels.edit.success, messages.hotels.edit.error, close]);

    return (
        <Dialog open={open} onClose={close} classes={{
            paper: classes.root
        }}>
            <DialogTitle className={classes.dialogTitle}>
                {hotelName.trim() || messages['hotels.actions.add.title']} {Boolean(hotel) &&
            <span>{messages.hotels.edit.title}</span>}
            </DialogTitle>
            <DialogContent>
                <Typography variant={'h4'} className={classes.title}>
                    {messages.hotels.edit.contractInformation.title}
                </Typography>
                <Grid container={true} spacing={2} className={classes.grid}>
                    <Grid item={true} xs={12} sm={5}>
                        <IftaSelect label={messages.hotels.edit.contractInformation.pricingModel.label}
                                    values={Object.values(PricingModel).map((it, index) => ({
                                        id: getLabelForPricingModel(it),
                                        value: it,
                                        label: getLabelForPricingModel(it)
                                    }))}
                                    onChange={e => setPricingModel(e.target.value)}
                                    emptyValue={messages.hotels.edit.contractInformation.pricingModel.placeholder}
                                    value={pricingModel}/>
                    </Grid>
                    <Grid item={true} xs={12} sm={7}>
                        {pricingModel === PricingModel.Provision &&
                        <Ifta label={messages.hotels.edit.contractInformation.frameContractNumber.label}
                              placeholder={messages.hotels.edit.contractInformation.frameContractNumber.placeholder}
                              value={frameContractNumber}
                              onChange={e => setFrameContractNumber(e.currentTarget.value)}
                              name={'frame-contract-number'}/>}
                    </Grid>
                    <Grid item={true} xs={12}>
                        <Ifta label={messages.hotels.edit.contractInformation.objectNumber.label}
                              placeholder={messages.hotels.edit.contractInformation.objectNumber.placeholder}
                              value={objectNumber}
                              onChange={e => setObjectNumber(e.currentTarget.value)}
                              name={'object-number'}/>
                    </Grid>
                </Grid>
                <Typography variant={'h4'} className={classes.title}>
                    {messages.hotels.edit.hotel.title}
                </Typography>
                <Grid container={true} spacing={2} className={classes.grid}>
                    <Grid item={true} xs={12}>
                        <Ifta label={messages.hotels.edit.hotel.name.label}
                              placeholder={messages.hotels.edit.hotel.name.placeholder}
                              value={hotelName}
                              onChange={e => setHotelName(e.currentTarget.value)}
                              name={'hotel-name'}/>
                    </Grid>
                    <Grid item={true} xs={12} sm={8}>
                        <Ifta label={messages.hotels.edit.hotel.street.label}
                              placeholder={messages.hotels.edit.hotel.street.placeholder}
                              value={street}
                              onChange={e => setStreet(e.currentTarget.value)}
                              name={'hotel-street'}/>
                    </Grid>
                    <Grid item={true} xs={12} sm={4}>
                        <Ifta label={messages.hotels.edit.hotel.streetNumber.label}
                              placeholder={messages.hotels.edit.hotel.streetNumber.placeholder}
                              value={streetNumber}
                              onChange={e => setStreetNumber(e.currentTarget.value)}
                              name={'hotel-street-number'}/>
                    </Grid>
                    <Grid item={true} xs={12} sm={4}>
                        <Ifta label={messages.hotels.edit.hotel.zip.label}
                              placeholder={messages.hotels.edit.hotel.zip.placeholder}
                              value={zip}
                              onChange={e => setZip(e.currentTarget.value)}
                              name={'hotel-zip'}/>
                    </Grid>
                    <Grid item={true} xs={12} sm={8}>
                        <Ifta label={messages.hotels.edit.hotel.city.label}
                              placeholder={messages.hotels.edit.hotel.city.placeholder}
                              value={city}
                              onChange={e => setCity(e.currentTarget.value)}
                              name={'hotel-city'}/>
                    </Grid>
                    <Grid item={true} xs={12}>
                        <IftaSelect label={messages.hotels.edit.hotel.country.label}
                                    values={denormalize(countries).sort((c1, c2) => c1.name.localeCompare(c2.name)).map((it) => ({
                                        id: it.id.toString(),
                                        value: it.id,
                                        label: it.name
                                    }))}
                                    onChange={e => setCountry(e.target.value)}
                                    emptyValue={messages.hotels.edit.hotel.country.placeholder}
                                    value={country}/>
                    </Grid>
                </Grid>
                <Typography variant={'h4'} className={classes.title}>
                    {messages.hotels.edit.contactPerson.title}
                </Typography>
                <Grid container={true} spacing={2} className={classes.grid}>
                    <Grid item={true} xs={12} sm={4}>
                        <IftaSelect label={messages.hotels.edit.contactPerson.gender.label}
                                    values={[{
                                        id: '0',
                                        value: Gender.Male,
                                        label: messages['hotels.add.gender.0']
                                    }, {
                                        id: '1',
                                        value: Gender.Female,
                                        label: messages['hotels.add.gender.1']
                                    }, {
                                        id: '2',
                                        value: Gender.None,
                                        label: messages['hotels.add.gender.2']
                                    }]}
                                    onChange={e => setGender(e.target.value)}
                                    emptyValue={messages.hotels.edit.contactPerson.gender.placeholder}
                                    value={gender}/>
                    </Grid>
                    <Grid item={true} xs={12} sm={4}>
                        <Ifta label={messages.hotels.edit.contactPerson.firstName.label}
                              placeholder={messages.hotels.edit.contactPerson.firstName.placeholder}
                              value={firstName}
                              onChange={e => setFirstName(e.currentTarget.value)}
                              name={'first-name'}/>
                    </Grid>
                    <Grid item={true} xs={12} sm={4}>
                        <Ifta label={messages.hotels.edit.contactPerson.lastName.label}
                              placeholder={messages.hotels.edit.contactPerson.lastName.placeholder}
                              value={lastName}
                              onChange={e => setLastName(e.currentTarget.value)}
                              name={'last-name'}/>
                    </Grid>
                    <Grid item={true} xs={12} sm={6}>
                        <Ifta label={messages.hotels.edit.contactPerson.email.label}
                              placeholder={messages.hotels.edit.contactPerson.email.placeholder}
                              value={email}
                              onChange={e => setEmail(e.currentTarget.value)}
                              name={'email'}/>
                    </Grid>
                    <Grid item={true} xs={12} sm={6}>
                        <Ifta label={messages.hotels.edit.contactPerson.phone.label}
                              placeholder={messages.hotels.edit.contactPerson.phone.placeholder}
                              value={phone}
                              onChange={e => setPhone(e.currentTarget.value)}
                              name={'phone'}/>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions className={classes.actions}>
                <Button className={classNames(classes.button, classes.cancel)} variant={'contained'} onClick={close}>
                    {messages.hotels.edit.cancel}
                </Button>
                <Button className={classNames(classes.button, classes.submit)} variant={'contained'} color={'primary'}
                        disabled={!canBeSaved || fetchStatus === FetchStatus.ACTIVE} onClick={() => {
                    if (country !== undefined && gender !== undefined) {
                        dispatch(createHotel({
                            id: hotel?.id || -1,
                            pricingModel,
                            frameContractNumber,
                            objectNumber,
                            hotelName,
                            street,
                            streetNumber,
                            zip,
                            city,
                            country,
                            gender,
                            firstName,
                            lastName,
                            email,
                            phone
                        }));
                    }
                }}>
                    {fetchStatus === FetchStatus.ACTIVE && <CircularProgress className={classes.progress} />}
                    {Boolean(hotel) ? messages.hotels.edit.save : messages.hotels.edit.saveCreate}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default HotelDialog;