import {checkServerResponse, requestConfig, RequestMethod} from './RequestHelper';

export const Route = {
    LOGIN_VERIFY: '/login/verify',
    LOAD_HOTELS: '/admin/hotels',
    LOAD_COUNTRIES: '/admin/countries',
    SET_HOTEL_INACTIVE: '/admin/hotels/inactive',
    SET_HOTEL_ACTIVE: '/admin/hotels/active',
    STAR_HOTEL: '/admin/hotels/star',
    UNSTAR_HOTEL: '/admin/hotels/unstar',
    SAVE_HOTEL: '/admin/hotels/update',
    SET_HOTEL_STATE: '/admin/hotels/state',
    DELETE_HOTEL: '/admin/hotels/delete',
    CREATE_OR_UPDATE_HOTEL: '/admin/hotels/createOrUpdate',
    SEND_NEW_TOKEN_MAIL: '/admin/hotels/token/regenerate',
    CHANGE_PASSWORD: '/admin/change/password',
    LOAD_ADMINS: '/admin/admins',
    Blog: {
        LOAD_CATEGORIES: '/admin/blog/categories',
        UPLOAD_IMAGE: '/admin/blog/image/upload',
        UPLOAD_POST_IMAGE: '/admin/blog/post/image/upload',
        UPDATE_OR_CREATE_CATEGORY: '/admin/blog/categories/category',
        LOAD_CATEGORY_POSTS: (id: number) => `/admin/blog/categories/category/${id}/posts`,
        UPDATE_OR_CREATE_POST: (id: number) => `/admin/blog/categories/category/${id}/posts`,
        DELETE_POST: '/admin/blog/categories/post',
        HIGHLIGHT_POST: '/admin/blog/categories/post/highlight',
        SET_POST_STATE: '/admin/blog/categories/post/state',
    }
};

export const get = async (url: string, body?: any, jwt?: string): Promise<any> =>
    checkServerResponse(await fetch(`${process.env.REACT_APP_BASE_URL}${url}?`, requestConfig(RequestMethod.GET, undefined, jwt)));

export const post = async (url: string, body: any, jwt?: string): Promise<any> =>
    checkServerResponse(await fetch(`${process.env.REACT_APP_BASE_URL}${url}`, requestConfig(RequestMethod.POST, body, jwt)));

export const put = async (url: string, body: any, jwt?: string): Promise<any> =>
    checkServerResponse(await fetch(`${process.env.REACT_APP_BASE_URL}${url}`, requestConfig(RequestMethod.PUT, body, jwt)));

export const deleteRequest = async (url: string, body: any, jwt?: string): Promise<any> =>
    checkServerResponse(await fetch(`${process.env.REACT_APP_BASE_URL}${url}`, requestConfig(RequestMethod.DELETE, body, jwt)));