import React, {useContext} from 'react';
import LocalizedStrings, {LocalizedStringsMethods} from 'react-localization';
import {useEffect} from 'react';
import {useSelector} from 'react-redux';
import {AppState, Language} from '../types/types';

interface Input {
    label: string;
    placeholder: string;
}

export interface IMessage extends LocalizedStringsMethods {
    /***********************************************************************************
     * General wordings
     ***********************************************************************************/

    'app.name': string;
    'menu.home': string;
    'menu.escales': string;
    'menu.destinations': string;
    'menu.about.us': string;
    'menu.contact': string;
    'menu.rent': string;
    'menu.logout': string;
    'close': string;
    'changelog': string;
    'admin': string;
    'hotel': string;
    'change.password': string;
    'password.reset': string;


    /***********************************************************************************
     * Admin section
     ***********************************************************************************/

    'login.headline': string;
    'login.description': string;
    'login.email.placeholder': string;
    'login.password.placeholder': string;
    'login.submit': string;
    'login.success': string;
    'login.error': string;
    'hotels.table.header.name': string;
    'hotels.table.header.statistics': string;
    'hotels.table.header.user': string;
    'hotels.table.header.email': string;
    'hotels.table.header.phone': string;
    'hotels.table.header.active': string;
    'hotels.table.header.since': string;
    'hotels.table.header.action': string;
    'hotels.table.header.objectNumber': string;
    'hotels.table.header.model': string;
    'hotels.table.header.state': string;
    'hotels.table.header.contact': string;
    'hotels.table.header.language': string;
    'hotels.table.clock': string;
    'hotel.changes.save.success': string;
    'hotel.changes.save.error': string;
    'hotel.actions.edit': string;
    'hotel.actions.delete': string;
    'hotel.actions.star': string;
    'hotel.actions.unstar': string;
    'hotels.actions.delete.cancel': string;
    'hotels.actions.delete.submit': string;
    'hotels.actions.delete.title': string;
    'hotels.actions.delete.content': string;
    'hotels.add': string;

    'hotels.pricingModel.fix': string;
    'hotels.pricingModel.provision': string;
    'hotels.state.registration': string;
    'hotels.state.pending': string;
    'hotels.state.activated': string;
    'hotels.state.edited': string;
    'hotels.state.deactivated': string;
    'hotels.contact.show': string;

    /***********************************************************************************
     * Add Accomodation
     ***********************************************************************************/

    'hotels.add.name': string;
    'hotels.add.gender': string;
    'hotels.add.gender.-1': string;
    'hotels.add.gender.0': string;
    'hotels.add.gender.1': string;
    'hotels.add.first.name': string;
    'hotels.add.last.name': string;
    'hotels.add.email': string;
    'hotels.add.phone': string;
    'hotels.actions.add.title': string;
    'hotels.actions.add.cancel': string;
    'hotels.actions.add.submit': string;
    'hotels.actions.add.success': string;
    'hotels.actions.add.error': string;
    'hotels.add.token.dialog': string;
    'hotel.actions.token.re.send': string;
    'hotels.actions.token.re.send.success': string;
    'hotels.actions.token.re.send.error': string;
    'hotel.actions.deactivate': string;
    'hotel.actions.activate': string;
    'hotel.actions.checked': string;


    'hotels.actions.add.highlights.1': string;
    'hotels.actions.add.highlights.2': string;
    'hotels.highlights.beach': string;
    'hotels.highlights.wellness': string;
    'hotels.highlights.nature': string;
    'hotels.highlights.mountains': string;
    'hotels.highlights.city': string;
    'hotels.highlights.groups': string;
    'hotels.highlights.sport': string;
    'hotels.actions.highlight.submit': string;


    /***********************************************************************************
     * Changelog
     ***********************************************************************************/


    'legal.headline': string;

    'legal.changelog.1.0': string;
    'changelog.caption.1.0': string;

    'legal.changelog.1.0.1': string;
    'changelog.caption.1.0.1': string;

    'legal.changelog.1.0.2': string;
    'changelog.caption.1.0.2': string;

    'legal.changelog.1.0.3': string;
    'changelog.caption.1.0.3': string;

    'legal.changelog.1.0.4': string;
    'changelog.caption.1.0.4': string;
    'legal.changelog.1.0.5': string;
    'changelog.caption.1.0.5': string;
    'legal.changelog.1.0.6': string;
    'changelog.caption.1.0.6': string;
    'legal.changelog.1.0.7': string;
    'changelog.caption.1.0.7': string;
    'legal.changelog.1.0.8': string;
    'changelog.caption.1.0.8': string;
    'legal.changelog.1.0.9': string;
    'changelog.caption.1.0.9': string;
    'legal.changelog.1.1.0.1': string;
    'changelog.caption.1.1.0.1': string;
    'legal.changelog.1.1.0.2': string;
    'changelog.caption.1.1.0.2': string;
    'legal.changelog.1.1.1': string;
    'changelog.caption.1.1.1': string;
    'legal.changelog.1.1.2': string;
    'changelog.caption.1.1.2': string;
    'legal.changelog.1.1.3': string;
    'changelog.caption.1.1.3': string;

    upload: {
        drop: string;
        progress: string;
        active: string;
    };
    dialogs: {
        confirm: {
            cancel: string;
            submit: string;
        }
    };

    hotels: {
        information: {
            title: string;
            registeredAt: string;
            activeSince: string;
            pricingModel: string;
            objectNumber: string;
            contact: string;
            phone: string;
            email: string;
        };
        statistics: {
            email: string;
            phone: string;
            website: string;
        };
        edit: {
            title: string;
            cancel: string;
            save: string;
            saveCreate: string;
            contractInformation: {
                title: string;
                pricingModel: Input;
                frameContractNumber: Input;
                objectNumber: Input;
            };
            hotel: {
                title: string;
                name: Input;
                street: Input;
                streetNumber: Input;
                zip: Input;
                city: Input;
                country: Input;
            };
            contactPerson: {
                title: string;
                gender: Input;
                firstName: Input;
                lastName: Input;
                email: Input;
                phone: Input;
            };
        };
    };

    blog: {
        title: string
        categories: {
            breadcrumb: string;
            title: string;
            details: string;
            create: {
                link: string;
                title: string;
                submit: string;
                cancel: string;
            };
            manageEntries: string;
            edit: {
                title: string;
                submit: string;
                cancel: string;
            };
            manage: {
                name: Input
                description: Input
                responsible: Input
                upload: {
                    button: string;
                    subLine: string;
                    hint: string;
                };
                delete: {
                    title: string;
                    description: string;
                    success: string;
                    error: string;
                };
                success: string;
                error: string;
            };
            upload: {
                button: string;
                description: string;
                subLine: string;
            };
        };
        posts: {
            breadcrumbBlog: string;
            breadcrumbCategories: string;
            breadcrumbNewPost: string;
            title: string;
            create: {
                link: string;
                title: string;
                defaultName: string;
            };
            manage: {
                title: string;
            };
            upload: {
                button: string;
                description: string;
                subLine: string;
            };
        };
    }

}

export const messages = {
    [Language.GERMAN]: {
        /***********************************************************************************
         * General wordings
         ***********************************************************************************/

        'app.name': 'Rolli-Hotels.de',
        'menu.home': 'Home',
        'menu.escales': 'Escales-Verlag',
        'menu.destinations': 'Reiseziele',
        'menu.about.us': 'Über uns',
        'menu.contact': 'Kontakt',
        'menu.rent': 'Vermieten',
        'menu.logout': 'Logout',
        'close': 'Schließen',
        'changelog': 'Changelog',
        'admin': 'Admin',
        'hotel': 'Hotel',
        'change.password': 'Passwort ändern',
        'password.reset': 'Coming Soon',


        /***********************************************************************************
         * Admin section
         ***********************************************************************************/

        'login.headline': 'Verwalten Sie Ihre Unterkünfte.',
        'login.description': 'Sollten Sie noch keinen Zugang haben, melden Sie sich bei Ihrem Admin.',
        'login.email.placeholder': 'E-Mail Adresse',
        'login.password.placeholder': 'Passwort',
        'login.submit': 'Login',
        'login.success': 'Sie wurden erfolgreich eingeloggt.',
        'login.error': 'Bitte überprüfen Sie Ihre E-Mail und Ihr Passwort.',
        'hotels.table.header.name': 'Unterkunft',
        'hotels.table.header.statistics': 'Statistik',
        'hotels.table.header.user': 'Name',
        'hotels.table.header.email': 'E-Mail',
        'hotels.table.header.phone': 'Telefon',
        'hotels.table.header.active': 'Registriert / Aktiv seit',
        'hotels.table.header.since': 'Mitglied seit',
        'hotels.table.header.action': 'Aktion',
        'hotels.table.header.objectNumber': 'Objektnummer',
        'hotels.table.header.model': 'Modell',
        'hotels.table.header.state': 'Status',
        'hotels.table.header.contact': 'Kontakt',
        'hotels.table.header.language': 'Sprache',
        'hotels.table.clock': '{0} Uhr',
        'hotel.changes.save.success': 'Ihre Änderungen wurden gespeichert.',
        'hotel.changes.save.error': 'Ihre Änderungen konnten nicht gespeichert werden.',
        'hotel.actions.edit': 'Kontakt bearbeiten',
        'hotel.actions.delete': 'Kontakt löschen',
        'hotel.actions.star': 'Hotel hervorheben',
        'hotel.actions.unstar': 'Hotel gleichstellen',
        'hotel.actions.deactivate': 'Hotel deaktiveren',
        'hotel.actions.activate': 'Hotel aktivieren',
        'hotel.actions.checked': 'Geprüft und freischalten',
        'hotels.actions.delete.cancel': 'Abbrechen',
        'hotels.actions.delete.submit': 'Löschen',
        'hotels.actions.delete.title': 'Möchten Sie diese Unterkunft löschen?',
        'hotels.actions.delete.content': 'Wenn Sie diese Unterkunft löschen ist dieses unwiderruflich gelöscht.',
        'hotels.add': 'Unterkunft hinzufügen',

        'hotels.pricingModel.fix': 'Fixkosten',
        'hotels.pricingModel.provision': 'Provision',
        'hotels.state.registration': 'Im Registrierungsprozess',
        'hotels.state.pending': 'Prüfung ausstehend',
        'hotels.state.activated': 'Aktiv & freigeschaltet',
        'hotels.state.edited': 'Nachträglich bearbeitet',
        'hotels.state.deactivated': 'Deaktiviert vom Admin',
        'hotels.contact.show': 'Anzeigen',

        /***********************************************************************************
         * Add Accomodation
         ***********************************************************************************/

        'hotels.add.name': 'Name der Unterkunft',
        'hotels.add.gender': 'Anrede',
        'hotels.add.gender.-1': 'Anrede',
        'hotels.add.gender.0': 'Herr',
        'hotels.add.gender.1': 'Frau',
        'hotels.add.gender.2': 'Keine Angabe',
        'hotels.add.first.name': 'Vorname',
        'hotels.add.last.name': 'Nachname',
        'hotels.add.email': 'E-Mail',
        'hotels.add.phone': 'Telefon',
        'hotels.actions.add.title': 'Unterkunft hinzufügen',
        'hotels.actions.add.cancel': 'Abbrechen',
        'hotels.actions.add.submit': 'Unterkunft hinzufügen',
        'hotels.actions.add.success': 'Die Unterkunft wurde erfolgreich hinzugefügt.',
        'hotels.actions.add.error': 'Die Unterkunft wurde konnte nicht hinzugefügt werden.',
        'hotels.add.token.dialog': 'Zugangspasswort',
        'hotel.actions.token.re.send': 'Code (neu) verschicken',
        'hotels.actions.token.re.send.success': 'Ein neuer Zugangscode wurde verschickt.',
        'hotels.actions.token.re.send.error': 'Ein neuer Zugangscode konte nicht verschickt werden. Bitte versuchen Sie es später erneut.',


        'hotels.actions.add.highlights.1': 'In welcher Kategorie möchten Sie die Unterkunft',
        'hotels.actions.add.highlights.2': 'hervorheben?',
        'hotels.highlights.beach': 'Am Wasser',
        'hotels.highlights.wellness': 'Mit Wellness',
        'hotels.highlights.nature': 'In der Natur',
        'hotels.highlights.mountains': 'In den Bergen',
        'hotels.highlights.city': 'Mit Stadt und Kultur',
        'hotels.highlights.groups': 'Für Gruppen',
        'hotels.highlights.sport': 'Mit sportlichen Angeboten',
        'hotels.actions.highlight.submit': 'Hervorheben',


        /***********************************************************************************
         * Blog
         ***********************************************************************************/

        language: {
            german: 'Deutsch',
            english: 'Englisch',
        },

        dialogs: {
            confirm: {
                cancel: 'Abbrechen',
                submit: 'Bestätigen'
            }
        },
        upload: {
            drop: 'Jetzt loslassen',
            progress: 'Fast fertig',
            active: 'Upload läuft'
        },

        hotels: {
            information: {
                title: 'Unterkunft',
                registeredAt: 'Registriert am',
                activeSince: 'Aktiv seit',
                pricingModel: 'Preismodell',
                objectNumber: 'Objekt Nr.',
                contact: 'Ansprechpartner',
                phone: 'Telefon',
                email: 'E-Mail'
            },
            statistics: {
                email: 'E-Mail',
                phone: 'Telefon',
                website: 'Website'
            },
            edit: {
                title: '(bearbeiten)',
                cancel: 'Abbrechen und zurück',
                save: 'Änderungen speichern',
                saveCreate: 'Unterkunft erstellen',
                success: 'Die Unterkunft wurde gespeichert.',
                error: 'Die Unterkunft konnte nicht gespeichert werden.',
                contractInformation: {
                    title: 'Vertragsinformationen',
                    pricingModel: {
                        label: 'Preismodell',
                        placeholder: 'Bitte Modell wählen'
                    },
                    frameContractNumber: {
                        label: 'Rahmenvertragsnummer',
                        placeholder: 'RHT-XXXX'
                    },
                    objectNumber: {
                        label: 'Objektnummer',
                        placeholder: '1234567890-XXXXXX'
                    }
                },
                hotel: {
                    title: 'Unterkunft',
                    name: {
                        label: 'Unterkunftsname',
                        placeholder: 'Rollstuhl GmbH'
                    },
                    street: {
                        label: 'Straße',
                        placeholder: 'Rollstuhlgerechte Straße'
                    },
                    streetNumber: {
                        label: 'Hausnummer',
                        placeholder: '123',
                    },
                    zip: {
                        label: 'Postleitzahl',
                        placeholder: '12345'
                    },
                    city: {
                        label: 'Stadt',
                        placeholder: 'Barrierefreie Stadt'
                    },
                    country: {
                        label: 'Land',
                        placeholder: 'Rollstuhlgerechtes Land'
                    }
                },
                contactPerson: {
                    title: 'Ansprechpartner',
                    gender: {
                        label: 'Anrede',
                        placeholder: 'Geschlecht'
                    },
                    firstName: {
                        label: 'Vorname',
                        placeholder: 'Max'
                    },
                    lastName: {
                        label: 'Nachname',
                        placeholder: 'Mustermann'
                    },
                    email: {
                        label: 'E-Mail Adresse (geschäftlich)',
                        placeholder: 'mustermann@unterkunft.de'
                    },
                    phone: {
                        label: 'Telefon (geschäftlich)',
                        placeholder: '+49 160 123456789'
                    }
                }
            }
        },

        blog: {
            title: 'Blog',
            categories: {
                breadcrumb: 'Blog / Kategorien',
                title: 'Kategorien verwalten',
                details: 'Beiträge verwalten',
                create: {
                    link: 'Neue Kategorie erstellen',
                    title: 'Kategorie erstellen',
                    submit: 'Kategorie erstellen',
                    cancel: 'Abbrechen und nicht erstellen'
                },
                manageEntries: 'Beiträge verwalten',
                edit: {
                    title: 'Kategorie bearbeiten',
                    submit: 'Änderungen speichern',
                    cancel: 'Abbrechen und nicht speichern'
                },
                manage: {
                    name: {
                        label: 'Kategorie Name',
                        placeholder: 'Wie heißt diese Kategorie?'
                    },
                    description: {
                        label: 'Kategorie Beschreibung',
                        placeholder: 'Beschreibe die Kategorie mit maximal 200 Zeichen.'
                    },
                    responsible: {
                        label: 'Kategorie Verantwortlicher',
                        placeholder: 'Wer verwaltet diese Kategorie zukünftig?'
                    },
                    upload: {
                        button: 'Kategorie-Bild hochladen',
                        subLine: 'oder einfach hier hereinziehen',
                        hint: 'min. 1200px breit'
                    },
                    delete: {
                        title: 'Kategorie löschen bestätigen',
                        description: 'Möchten Sie die Kategorie "{0}" wirklich löschen?',
                        success: 'Die Kategorie wurde erfolgreich gelöscht',
                        error: 'Die Kategorie konnte nicht gelöscht werden.'
                    },
                    success: 'Die Kategorie wurde erfolgreich gespeichert.',
                    error: 'Leider ist ein Fehler aufgetreten, bitte versuchen Sie es später nochmal.',
                },
                upload: {
                    button: 'Kategorie-Bild hochladen',
                    description: 'oder einfach hier hereinziehen',
                    subLine: 'min. 1200px breit'
                }
            },
            posts: {
                breadcrumbBlog: 'Blog',
                breadcrumbCategories: 'Kategorien',
                breadcrumbNewPost: 'Neuer Beitrag',
                title: 'Beiträge verwalten',
                create: {
                    link: 'Neuen Beitrag erstellen',
                    title: 'Beitrag erstellen',
                    defaultName: 'Neuer Blogbeitrag'
                },
                manage: {
                    title: 'Beitrag bearbeiten',
                    name: {
                        label: 'Blogbeitrag',
                        placeholder: 'Wie lautet der Titel dieses Beitrags?'
                    },
                    description: {
                        label: 'Kurzbeschreibung',
                        placeholder: 'Beschreibe den neuen Beitrag mit maximal 175 Zeichen.'
                    },
                    tags: {
                        label: 'Tags',
                        placeholder: 'Tagge den Beitrag damit er später gut gefunden werden kann.'
                    },
                    cancel: 'Abbrechen',
                    edit: 'Bearbeiten',
                    saveAndPublish: 'Speichern und live stellen',
                    delete: {
                        button: 'Löschen',
                        title: 'Beitrag löschen bestätigen',
                        description: 'Möchten Sie den Beitrag "{0}" wirklich löschen?',
                        success: 'Der Beitrag wurde erfolgreich gelöscht.',
                        error: 'Der Beitrag konnte nicht gelöscht werden.'
                    },
                    highlight: {
                        success: 'Der Beitrag wurde erfolgreich gehighlighted.',
                        error: 'Der Beitrag konnte nicht gehighlighted werden.'
                    },
                    unhighlight: {
                        success: 'Der Beitrag wurde erfolgreich als Highlight entfernt.',
                        error: 'Der Beitrag konnte nicht gehighlighted werden.'
                    },
                    active: {
                        success: 'Der Beitrag wurde erfolgreich aktiviert.',
                        error: 'Der Beitrag konnte nicht aktiviert werden.'
                    },
                    inactive: {
                        success: 'Der Beitrag wurde erfolgreich deaktiviert.',
                        error: 'Der Beitrag konnte nicht deaktiviert werden.'
                    },
                    save: {
                        button: 'Speichern',
                        success: 'Der Beitrag wurde erfolgreich gespeichert.',
                        error: 'Der Beitrag konnte nicht gespeichert werden.'
                    }
                },
                upload: {
                    button: 'Titelbild hochladen',
                    description: 'oder einfach hier hereinziehen',
                    subLine: 'min. 1200px breit'
                },
                editor: {
                    text: 'Text',
                    heading: 'Überschrift',
                    list: 'Liste',
                    warning: 'Warnung',
                    checklist: 'Checkliste',
                    quote: 'Zitat',
                    code: 'Code',
                    delimiter: 'Trenner',
                    rawHTML: 'HTML',
                    table: 'Tabelle',
                    link: 'Link',
                    marker: 'Markierung',
                    bold: 'Fett',
                    italic: 'Kursiv',
                    inlineCode: 'Inline Code',
                    underline: 'Unterstrichen',
                    colorPicker: 'Farbe',
                    image: 'Bild'
                }
            }
        },

        /***********************************************************************************
         * Changelog
         ***********************************************************************************/


        'legal.headline': 'Changelog',

        'legal.changelog.1.0': 'Version 1.0',
        'changelog.caption.1.0': 'Wir sind live!!! Wooohoooo.',

        'legal.changelog.1.0.1': 'Version 1.0.1',
        'changelog.caption.1.0.1': '<ul><li>Filter "Ferienwohnung-/Haus" unter Preise hinzugefügt</li></ul>',

        'legal.changelog.1.0.2': 'Version 1.0.2',
        'changelog.caption.1.0.2': '<ul><li>"Passwort ändern" im Admin-Bereich hinzugefügt</li></ul>',

        'legal.changelog.1.0.3': 'Version 1.0.3',
        'changelog.caption.1.0.3': '<ul><li>Ausfüllhilfe [PDF] auf Register (1) hinzugefügt</li><li>Ausfüllhilfe [PDF] der Bestätigungsmail hinzugefügt</li> <li>Unter Register (2) neue Checkbox <strong>Die Unterkunft: </strong>"Die Essbereiche oder das Restaurant sind stufenlos erreichbar." hinzugefügt</li><li>Unter Register (4) wurde "Bewegungsspielraum Bad min. 1 x 1 Meter" entfernt</li><li>Admin als Menüpunkt hinzugefügt (für eine einfachere Navigation)</li><li>Der Image Upload ist nun smarter</li><li>Die Länge des Zugangscodes wurde von 16 auf 8 Zeichen reduziert.</li><li>Die Mail "Code verschicken" enthält einen Link zur Ausfüllhilfe + 2 Ausfülltipps in der Signatur</li></ul>',

        'legal.changelog.1.0.4': 'Version 1.0.4',
        'changelog.caption.1.0.4': 'Mit dieser Code Änderung wird der Anmeldeprozess vorübergehend abgeändert:</br> Anstatt eine Anfrage zunächst zu prüfen und anschließend im Admin Bereich unter "Code verschicken" freizugeben, erhält der Kunde direkt eine E-Mail mit dem Zugangscode. </br></br> Nach der Rückstellung auf die manuelle Freigabe, sollte der Kunde nicht mehr blind fliegen und direkt eine Mail erhalten, die den Erhalt der Anfrage bestätigt und auf die Prüfung sowie eine Antwort in naher Zukunft hinweist.',

        'legal.changelog.1.0.5': 'Version 1.0.5',
        'changelog.caption.1.0.5': '<ul><li>Verteilerliste auf Wunsch umgestellt auf <a href="mailto:info@rolli-hotels.de">info@rolli-hotels.de</a></li> <li>Collage auf den Detailseiten: Einstellungen angepasst (weiter rausgezoomt)</li> <li>Collage auf den Detailseiten: Zoom-Animation ergänzt</li> <li>Kompatibilität optimiert: Support für Internet Explorer 11 und höher</li> <li>Freitextfelder umbenannt: Das Wort Freitext wurde ersetzt</li></ul>',

        'legal.changelog.1.0.6': 'Version 1.0.6',
        'changelog.caption.1.0.6': '<ul><li>Empty State: Button "Suche erneut starten" ergänzt, wenn die Suche ins leere geht</li> <li>Mail mit Zugangscode für Kunden angepasst: "Hotel" wird zu "Unterkunft" </li> <li>Über Uns Seite: "30 Jahre" auf "35 Jahre" geändert</li> <li>Über Uns Seite: Kontrast vom Text erhöht</li><li>Cover aktualisiert: 31. Auflage von "Handicapped Reisen"</li><li>Suchleiste für viele Einträge optimiert</li><li>Hotel wurde seitenweit auf Unterkunft umgeändert</li></ul>',

        'legal.changelog.1.0.7': 'Version 1.0.7',
        'changelog.caption.1.0.7': '<ul><li>Desktop: Suchleisten-Fehler behoben, bei dem ein Eintrag angeklickt und ein anderer ausgewählt wurde</li><li>Mobile: Bug auf iOS behoben bei dem Mobil keine Anfrage versendet werden konnte</li><li>Mobile: Problem mit dem Filter-Overlay behoben bei dem man, zu einem bestimmten Zeitpunkt, nicht mehr scrollen konnte</li><li>Mobile: Suchleiste gefiltert und zeigt nur noch die eingetragenen Standorte </li><li>Unterkunft Cards vereinheitlicht: alles gleich hoch, mehr als 3 Zeilen werden abgeschnitten, Preise nicht anzeigen, wenn nicht ausgefüllt und vieles mehr!</li><li>UX optimiert: Unterkunft-Urls zeigen nun den Unterkunftsnamen</li></ul>',

        'legal.changelog.1.0.8': 'Version 1.0.8',
        'changelog.caption.1.0.8': '<ul><li>Holidy-Home-Thomas: Standort korrigiert</li><li>Admin: Es wurde eine Pagination zu den Unterkunftseinträgen hinzugefügt</li><li>UX: Nach dem ersten Fertigstellen der Unterkunft wird der Button <strong>Fertigstellen</strong> zu <strong>Aktualisieren</strong></li><li>UX: Die Icons auf der Detailseite sind nun klickbar und starten eine neue Suche nach Kategorie</li><li>UX: Suchanfragen können nun per "Enter"-Taste gestartet werden</li>  <li>UX: gefiltert können nun per "Enter"-Taste aktiviert bzw. bestätigt werden</li><li>Sicherheitsupdates</li><li>UX: Das Passwort kann nun unter Admin und Vermieten angezeigt werden</li><li>Bug: Problem behoben bei dem das Titelbild nicht angezeigt werden konnte</li><li>UI: Register (5) - Visuelle Anpassungen so das mehr als 15 weitere Bilder hinzugefügt werden können</li><li>E-Mail: Richtige Reihenfolge für Anreise & Abreise</li><li>UX: Problem behoben bei dem manchmal die Anfragenleiste die unteren Bilder bedeckt hat</li>  <li>Bug: Der Image Uploader wurde überarbeitet</li>  </ul>',

        'legal.changelog.1.0.9': 'Version 1.0.9 (Erweiterung: Telefonanfrage & FAQ)',
        'changelog.caption.1.0.9': '<ul> <li>Ergänzung der Möglichkeit auch per Telefon die Hotels anzufragen</li> <li> Eine FAQ Seite wurde hinzugefügt, um häufig gestellte Fragen für voraus zu klären</li> <li>Performance-Update: Infinity Loader - Hotels werden jetzt nach und nach geladen und nicht mehr alle auf einmal</li> <li>Ausfüllhilfe wurde überarbeitet und geupdatet</li> <li>Anfragemodul auf der Detailseite wurde angepasst, damit die oberen Bilder nicht mehr verdeckt werden.</li> </ul>',

        'legal.changelog.1.1.0.1': 'Version 1.1.0 (SEO-Update: Technologie)',
        'changelog.caption.1.1.0.1': '<ul><li>IMAGES: Überarbeitung der angeboteten Bildgrößen und Bildformate</li> <li>IMAGES: ALle Bilder haben Alt-Attribut & Title-Attribut erhalten</li> <li>PERFORMANCE: Relevante Inhalte werden geladen, andere Inhalte werden aufgeschoben und nachgeladen</li> <li>PERFORMANCE: Logo in der Dateigröße verkleinert</li> <li>PERFORMANCE: Optimierte Bildkompression für eine bessere Ladegeschwindigkeit</li> <li>PERFORMANCE: Text und Dateikomprimierung aktiviert</li> <li>TECHNOLOGIE: Automatisierte Sitemap für statische Inhalte & Unterkünfte</li> <li>TECHNOLOGIE: Klar definierte Deutsche & Englische Inhalte</li> <li>PERFORMANCE: Fonts werden nachgeladen</li> </ul>',

        'legal.changelog.1.1.0.2': 'Version 1.1.0 (SEO-Update: Content)',
        'changelog.caption.1.1.0.2': '<ul><li>HOME: Mehr Struktur in der Hierarchie // Neue Headlines und Sublines ergänzt</li> <li>HOME: Neuer Beschreibungstext für Kategorien eingefügt</li> <li>HOME: Panorama-Text erweitert</li> <li>HOME: Text unter dem Panoramabild neu hinzugefügt</li> <li>REISEZIELE: Mehr Struktur in der Hierarchie // Neue Headlines und Sublines ergänzt</li> <li>REISEZIELE: Smarte Divider ergänzt, welche nach bestimmter Anzahl Hotels zufällig angezeigt werden, um  Absprungstellen für die anderen Produkte der Escales GmbH zu bieten</li> <li>REISEZIELE: Großer SEO-Text über die Seite, Services und Unternehmen</li> <li>REISEZIELE: </li> <li>REISEZIELE: </li> </ul>',

        'legal.changelog.1.1.1': 'Version 1.1.1',
        'changelog.caption.1.1.1': '<ul><li>Anpassung der Suchleiste: Anderes Wording & Optimierung des Dropdown auf einen Klick</li> <li>Anpassungen der Breite des Anfragemoduls, damit Personen und Zimmeranzahl voll ausgeschrieben werden können</li> <li>Nummer-Eingabefelder gehen nicht mehr ins Minus</li> </ul>',

        'legal.changelog.1.1.2': 'Version 1.1.2',
        'changelog.caption.1.1.2': '<ul><li>Einbindung aller Hotels in Sitemap => 115 erkannte Unterseiten bei Google</li><li>Performance-Optimierungen (Mobile Page-Speed +30 Punkte, Desktop +10 Punkte)</li><li>Angabe der Webseite über Registrierungsprozess</li><li>Tracking der Klicks auf den Webseite-Button</li><li>Anzeigen der Anzahl der Klicks auf den Webseiten-Button in Admin-UI</li><li>Einbindung der Google Search Console zur Optimierung der Suchanfragen</li><li>Optimierung der Sub-Seiten für SEO-Performance</li> </ul>',

        'legal.changelog.1.1.3': 'Version 1.1.3 (Coming Soon)',
        'changelog.caption.1.1.3': '<ul><li>...</li> </ul>',
    }
}

const localizedStrings = new LocalizedStrings(messages);

const Localize = React.createContext(localizedStrings);

export function useLocalization() {

    const localized = useContext(Localize);
    const language: Language = useSelector<AppState, Language>(state => state.application.language.selected);
    localized.setLanguage(language);

    useEffect(() => {
        localized.setLanguage(language);
    }, [localized, language]);

    return localized;
}


function LocalizationProvider({children}: { children: any }) {
    return <Localize.Provider value={localizedStrings}>
        {children}
    </Localize.Provider>
}

export default LocalizationProvider;
