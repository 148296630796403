import {call, put, select, spawn, takeLatest} from 'redux-saga/effects';
import {Admin, AppState} from '../types/types';
import {get, Route} from '../api/Api';
import {fetchLoadAdmins, fetchLoadAdminsError, fetchLoadAdminsSuccess, LOAD_ADMINS} from '../reducer/application/types';


export function* loadAdmins() {
    const jwt: string | undefined = yield select((state: AppState) => state.user.jwt);
    if (jwt?.trim()) {
        try {
            yield put(fetchLoadAdmins());
            const admins: Admin[] = yield call(get, Route.LOAD_ADMINS, undefined, jwt);
            yield put(fetchLoadAdminsSuccess(admins));
        } catch (e) {
            yield put(fetchLoadAdminsError());
        }
    }
}

export function* loadAdminSaga() {
    yield takeLatest(LOAD_ADMINS, loadAdmins);
}

function* adminSaga() {
    yield spawn(loadAdminSaga);
}

export default adminSaga;