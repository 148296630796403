import React, {Suspense, useEffect, useState} from 'react';
import {BrowserRouter} from 'react-router-dom';
import {Route} from 'react-router';
import {MuiThemeProvider} from '@material-ui/core/styles';
import theme from './theme/theme';
import ScrollToTop from './helper/ScrollToTop';
import type {AppState} from '../types/types';
import {CssBaseline, makeStyles, Snackbar} from '@material-ui/core';
import FetchStatus from '../api/FetchStatus';
import Changelog from './sites/Changelog';
import {AppSnackbarContent} from '../helper/helperFunctions';
import Menu from './skeleton/Menu';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import {useDispatch, useSelector} from 'react-redux';
import {changePassword, loadAdmins, setLanguage, setShowChangePasswordDialog} from '../reducer/application/types';
import Hotels from './sites/Hotels';
import LocalizationProvider from '../localization/localization';
import {Language} from '../types/types';
import Login from './sites/Login';
import {Helmet} from 'react-helmet';

import NexaLightTTF from '../fonts/nexa-light-webfont.ttf';
import NexaLightWoff2 from '../fonts/nexa-light-webfont.woff2';
import NexaLightWoff from '../fonts/nexa-light-webfont.woff';
import NexaRegularTTF from '../fonts/nexa-regular-webfont.ttf';
import NexaRegularWoff2 from '../fonts/nexa-regular-webfont.woff2';
import NexaRegularWoff from '../fonts/nexa-regular-webfont.woff';
import NexaBoldTTF from '../fonts/nexa-bold-webfont.ttf';
import NexaBoldWoff2 from '../fonts/nexa-bold-webfont.woff2';
import NexaBoldWoff from '../fonts/nexa-bold-webfont.woff';
import Overview from './sites/blog/categories/Overview';
import PostOverview from './sites/blog/posts/Overview';
import {loadCategories} from '../reducer/blog/types';
import EditBlogPost from './sites/blog/posts/post/EditBlogPost';
import Notifier from './skeleton/notifications/Notifier';
import {SnackbarProvider} from 'notistack';


const useStyles = makeStyles(() => ({
    root: {},
    input: {
        fontSize: 16,
        width: '100%',
        outline: 'none',
        padding: '18px 21px',
        boxSizing: 'border-box',
        borderRadius: 5,
        border: '1px solid #DDDFE7',
        transition: 'all 0.3s cubic-bezier(0.55, 0, 0.6, 1)',
        color: '#778198',
        fontFamily: 'Nexa, sans-serif',
        '&:focus': {
            color: '#2F89FC',
            boxShadow: '0 10px 20px 0 rgba(0,0,0,0.1)',
            border: '1px solid white',
        }
    },
    dialog: {
        padding: '8px 24px'
    },
    row: {
        marginBottom: 16,
        width: 400
    },
    errorSnackbar: {
        fontWeight: 600,
        fontSize: 14,
        background: 'white',
        color: '#F91C3D'
    },
    successSnackbar: {
        fontWeight: 600,
        fontSize: 14,
        background: 'white',
        color: '#0DB7DF'
    }
}));

const App: React.FC = () => {
    const classes = useStyles();
    const {
        changePasswordFetchStatus,
        jwt,
        loginFetchStatus,
        showChangePasswordDialog
    } = useSelector((state: AppState) => ({
        jwt: state.user.jwt,
        loginFetchStatus: state.user.loginFetchStatus,
        showChangePasswordDialog: state.application.showChangePasswordDialog,
        changePasswordFetchStatus: state.application.changePasswordFetchStatus
    }));

    const [snackbar, setSnackbar] = useState<{
        showSnackbar: boolean
        snackbarText: string
        snackbarIcon?: any
        snackbarLevel: FetchStatus
    }>({
        showSnackbar: false,
        snackbarText: '',
        snackbarLevel: FetchStatus.SUCCESS,
    });

    const [password, setPassword] = useState('');
    const [confirmation, setConfirmation] = useState('');

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setLanguage(navigator.language.replace('-', '_') as Language));
    }, [dispatch]);

    useEffect(() => {
        if (loginFetchStatus === FetchStatus.SUCCESS) {
            setSnackbar({
                showSnackbar: true,
                snackbarText: 'Sie wurden erfolgreich eingeloggt.',
                snackbarLevel: FetchStatus.SUCCESS
            });
        } else if (loginFetchStatus === FetchStatus.ERROR) {
            setSnackbar({
                showSnackbar: true,
                snackbarText: 'Bitte überprüfen Sie Ihre E-Mail und Ihr Passwort.',
                snackbarLevel: FetchStatus.ERROR
            });
        }
    }, [loginFetchStatus]);

    useEffect(() => {
        if (changePasswordFetchStatus === FetchStatus.SUCCESS) {
            setSnackbar({
                showSnackbar: true,
                snackbarText: 'Ihr Passwort wurde erfolgreich geändert.',
                snackbarLevel: FetchStatus.SUCCESS
            });
            setPassword('');
            setConfirmation('');
        } else if (changePasswordFetchStatus === FetchStatus.ERROR) {
            setSnackbar({
                showSnackbar: true,
                snackbarText: 'Ihr Passwort konnte nicht geändert werden. Bitte versuchen Sie es später erneut.',
                snackbarLevel: FetchStatus.ERROR
            });
            setPassword('');
            setConfirmation('');
        }
    }, [changePasswordFetchStatus]);

    const resetPasswordState = () => {
        setPassword('');
        setConfirmation('');
    }

    useEffect(() => {
        if (jwt) {
            dispatch(loadAdmins());
            dispatch(loadCategories());
        }
    }, [jwt, dispatch]);

    return (
        <BrowserRouter>
            <Suspense fallback={<div/>}>
                <Helmet>
                    <link rel={'preload'} href={NexaBoldTTF} as={'font'} crossOrigin={'anonymous'}/>
                    <link rel={'preload'} href={NexaBoldWoff} as={'font'} crossOrigin={'anonymous'}/>
                    <link rel={'preload'} href={NexaBoldWoff2} as={'font'} crossOrigin={'anonymous'}/>
                    <link rel={'preload'} href={NexaLightTTF} as={'font'} crossOrigin={'anonymous'}/>
                    <link rel={'preload'} href={NexaLightWoff} as={'font'} crossOrigin={'anonymous'}/>
                    <link rel={'preload'} href={NexaLightWoff2} as={'font'} crossOrigin={'anonymous'}/>
                    <link rel={'preload'} href={NexaRegularTTF} as={'font'} crossOrigin={'anonymous'}/>
                    <link rel={'preload'} href={NexaRegularWoff} as={'font'} crossOrigin={'anonymous'}/>
                    <link rel={'preload'} href={NexaRegularWoff2} as={'font'} crossOrigin={'anonymous'}/>
                </Helmet>
                <ScrollToTop>
                    <LocalizationProvider>
                        <MuiThemeProvider theme={theme}>
                            <SnackbarProvider anchorOrigin={{
                                horizontal: 'right',
                                vertical: 'bottom'
                            }} hideIconVariant={true} classes={{
                                variantError: classes.errorSnackbar,
                                variantSuccess: classes.successSnackbar
                            }}>
                                <CssBaseline/>
                                <Menu/>
                                <Notifier/>
                                <div className={classes.root}>
                                    {jwt ?
                                        <React.Fragment>
                                            <Route exact path='/' component={Hotels}/>
                                            <Route exact path='/changelog' component={Changelog}/>
                                            <Route exact path='/blog/categories' component={Overview}/>
                                            <Route exact path='/blog/categories/:categoryID/post/:blogPostID'
                                                   component={EditBlogPost}/>
                                            <Route exact path='/blog/categories/:categoryID/:action?'
                                                   component={PostOverview}/>
                                        </React.Fragment>
                                        : <Login/>
                                    }

                                    <Snackbar
                                        anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'right'
                                        }}
                                        open={snackbar.showSnackbar}
                                        autoHideDuration={6000}
                                        onClose={() => setSnackbar({...snackbar, ...{showSnackbar: false}})}>
                                        <AppSnackbarContent
                                            onClose={() => setSnackbar({...snackbar, ...{showSnackbar: false}})}
                                            message={snackbar.snackbarText}
                                            icon={snackbar.snackbarIcon}
                                            level={snackbar.snackbarLevel}
                                        />
                                    </Snackbar>
                                    <Dialog
                                        classes={{paper: classes.dialog}}
                                        open={showChangePasswordDialog}
                                        onClose={() => {
                                            dispatch(setShowChangePasswordDialog(false));
                                            resetPasswordState();
                                        }}>
                                        <DialogTitle>Passwort ändern</DialogTitle>
                                        <DialogContent>
                                            <div className={classes.row}>
                                                <input type={'password'} className={classes.input}
                                                       placeholder={'Neues Passwort'}
                                                       onChange={(e) => setPassword(e.currentTarget.value)}/>
                                            </div>
                                            <div className={classes.row}>
                                                <input type={'password'} className={classes.input}
                                                       placeholder={'Neues Passwort bestätigen'}
                                                       onChange={(e) => setConfirmation(e.currentTarget.value)}/>
                                            </div>
                                        </DialogContent>
                                        <DialogActions>
                                            <Button onClick={() => {
                                                dispatch(setShowChangePasswordDialog(false));
                                                resetPasswordState();
                                            }} color={'secondary'}>
                                                Abbrechen
                                            </Button>
                                            <Button onClick={() => {
                                                if (password?.trim() && password === confirmation) {
                                                    dispatch(changePassword({
                                                        password,
                                                        confirmation
                                                    }))
                                                } else {
                                                    setSnackbar({
                                                        showSnackbar: true,
                                                        snackbarText: 'Die beiden Passwörter stimmen nicht überein.',
                                                        snackbarLevel: FetchStatus.ERROR
                                                    });
                                                }
                                            }} color={'primary'} autoFocus
                                                    disabled={changePasswordFetchStatus === FetchStatus.ACTIVE}>
                                                Passwort ändern
                                            </Button>
                                        </DialogActions>
                                    </Dialog>
                                </div>
                            </SnackbarProvider>
                        </MuiThemeProvider>
                    </LocalizationProvider>
                </ScrollToTop>
            </Suspense>
        </BrowserRouter>
    );

}

export default App;
