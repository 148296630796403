import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import type {Hotel} from '../../types/types';
import {HotelLogType, HotelState, Language, PricingModel, SnackbarVariant} from '../../types/types';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import classNames from 'classnames';
import moment from 'moment-timezone';
import FetchStatus from '../../api/FetchStatus';
import MoreIcon from '@material-ui/icons/MoreHoriz';
import StarIcon from '@material-ui/icons/Star';
import StarBorder from '@material-ui/icons/StarBorder';
import {Button, makeStyles, Tooltip} from '@material-ui/core';
import {Theme} from '@material-ui/core/styles/createTheme';
import {useLocalization} from '../../localization/localization';
import HotelInformation from './hotels/HotelInformation';
import HotelMenu from './hotels/HotelMenu';
import {useDispatch} from 'react-redux';
import {addApplicationMessage, resetHotelFetchStatus} from '../../reducer/application/types';
import english from '../../images/icons/english.svg';
import german from '../../images/icons/german.svg';


interface HotelRowProps {
    hotel: Hotel;
    selected: boolean;
    openDeleteDialog: (callback: () => void) => void;
    sendNewTokenMailFetchStatus: FetchStatus;
    openHighlightDialog: () => void;
    editHotel: () => void;
}

const useStyles = makeStyles((theme: Theme) => ({
    row: {
        marginBottom: 16,
        boxShadow: '0 0 1px 0 rgba(31,31,31,0.2), 0 1px 4px 0 rgba(53,53,53,0.15)',
        background: 'white',
        border: 'none',
        transition: 'all 0.3s cubic-bezier(0.55, 0, 0.6, 1)',
        borderRadius: 4,
        '& td:first-child': {
            borderTopLeftRadius: 4,
            borderBottomLeftRadius: 4
        },
        '& td:last-child': {
            borderTopRightRadius: 4,
            borderBottomRightRadius: 4
        }
    },
    starred: {
        '&>td': {
            borderTop: '2px solid #30E3CA',
            borderBottom: '2px solid #30E3CA'
        },

    },
    incomplete: {
        '&>td': {
            borderTop: '2px solid #FF5F5F',
            borderBottom: '2px solid #FF5F5F'
        },
        '& $firstCell': {
            borderLeft: '2px solid #FF5F5F'
        },
        '& $lastCell': {
            borderRight: '2px solid #FF5F5F'
        }
    },
    cell: {
        color: theme.palette.primary.light,
        fontSize: 14,
        lineHeight: '19px',
        border: 'none',
        position: 'relative'
    },

    input: {
        ...theme.input,
        padding: 12
    },
    flexCell: {
        display: 'flex'
    },
    smallInputMargin: {
        marginRight: 8
    },
    fetching: {
        opacity: 0.3
    },
    hotelRequestsBadge: {
        background: theme.palette.primary.main,
        color: 'white',
        fontSize: 13,
        letterSpacing: -0.55,
        width: 32,
        height: 32,
        borderRadius: '50%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 3
    },
    hotelPhoneCallsBadge: {
        zIndex: 2,
        background: '#13C5AC',
        color: 'white',
        marginLeft: -5
    },
    hotelWebsiteRequestBadge: {
        zIndex: 1,
        background: theme.palette.secondary.dark,
        color: 'white',
        marginLeft: -5
    },
    badge1: {
        right: -22,
        zIndex: 3
    },
    badge2: {
        zIndex: 2,
        right: -58
    },
    badge3: {
        zIndex: 1,
        right: -90
    },
    actionButton: {
        marginRight: theme.spacing(2),
        marginBottom: 1
    },
    switch: {
        marginBottom: 1
    },
    firstCell: {},
    lastCell: {},
    starIcon: {
        width: 26,
        height: 26,
        color: '#FFDC64',
        margin: '0 20px'
    },
    unstarred: {
        color: '#193150'
    },
    badgeSurround: {
        display: 'flex'
    },
    contactButton: {
        fontWeight: 600,
        fontSize: 14
    },
    tooltip: {
        background: 'none'
    },
    languageIcon: {
        width: 24,
        height: 24,
        borderRadius: 4,
        margin: '0 auto',
        display: 'block'
    }
}));

const HotelRow: React.FC<HotelRowProps> = ({
                                               hotel,
                                               openDeleteDialog,
                                               openHighlightDialog,
                                               selected,
                                               sendNewTokenMailFetchStatus,
                                               editHotel
                                           }) => {
    const classes = useStyles();
    const popperRef = useRef<HTMLButtonElement>(null);
    const messages = useLocalization();
    const dispatch = useDispatch();

    const [anchorElement, setAnchorElement] = useState<HTMLElement | null>(null);

    useEffect(() => {
        if (hotel.fetchStatus === FetchStatus.SUCCESS) {
            dispatch(addApplicationMessage({
                key: `save-hotel-success-${new Date().getTime()}`,
                variant: SnackbarVariant.SUCCESS,
                text: messages['hotel.changes.save.success']
            }));
            dispatch(resetHotelFetchStatus(hotel.id));
        } else if (hotel.fetchStatus === FetchStatus.ERROR) {
            dispatch(addApplicationMessage({
                key: `save-hotel-success-${new Date().getTime()}`,
                variant: SnackbarVariant.ERROR,
                text: messages['hotel.changes.save.error']
            }));
            dispatch(resetHotelFetchStatus(hotel.id));
        }
    }, [hotel.fetchStatus, dispatch, messages, hotel.id]);

    // @ts-ignore
    const getClass = useCallback((appendix: string | number) => classes[`badge${appendix}`], [classes]);

    const hotelState = useMemo(() => {
        switch (hotel.state) {
            case HotelState.Activated:
                return messages['hotels.state.activated'];
            case HotelState.Deactivated:
                return messages['hotels.state.deactivated'];
            case HotelState.Edited:
                return messages['hotels.state.edited'];
            case HotelState.Pending:
                return messages['hotels.state.pending'];
            case HotelState.Registration:
                return messages['hotels.state.registration'];
        }
    }, [hotel.state, messages]);

    return (
        <TableRow
            className={classes.row}
            hover
            role={'checkbox'}
            aria-checked={selected}
            tabIndex={-1}
            selected={selected}>
            <TableCell padding={'checkbox'}
                       className={classNames(classes.cell, classes.firstCell)}>
                {hotel.starred ?
                    <StarIcon className={classes.starIcon}/> :
                    <StarBorder className={classNames(classes.starIcon, classes.unstarred)}/>}
            </TableCell>
            <TableCell className={classes.cell}>
                <div className={classes.badgeSurround}>
                    <Typography className={classNames(classes.hotelRequestsBadge, classes.badge1)}>
                        <span>{hotel.HotelRequests?.length || 0}</span>
                    </Typography>
                    <Typography
                        className={classNames(classes.hotelRequestsBadge, classes.hotelPhoneCallsBadge, getClass(2))}>
                        <span>{hotel.HotelLogs?.filter(log => log.type === HotelLogType.SHOW_PHONE_NUMBER).length || 0}</span>
                    </Typography>
                    <Typography
                        className={classNames(classes.hotelRequestsBadge, classes.hotelWebsiteRequestBadge, getClass(3))}>
                        <span>{hotel.HotelLogs?.filter(log => log.type === HotelLogType.SHOW_WEBSITE).length || 0}</span>
                    </Typography>
                </div>
            </TableCell>
            <TableCell className={classes.cell}>
                {hotel.name}
            </TableCell>
            <TableCell className={classes.cell}>
                {hotel.objectNumber || ''}
            </TableCell>
            <TableCell className={classes.cell}>
                {hotel.pricingModel && (hotel.pricingModel === PricingModel.Fix ? messages['hotels.pricingModel.fix'] : hotel.FrameContractNumber?.number.toLocaleUpperCase())}
            </TableCell>
            <TableCell className={classes.cell}>
                {hotelState}
            </TableCell>
            <TableCell className={classes.cell}>
                <div>
                    {messages.formatString(messages['hotels.table.clock'], moment(hotel.createdAt).format('DD.MM.YYYY - HH:mm'))}
                </div>
                <div>
                    {hotel.publishingDate && messages.formatString(messages['hotels.table.clock'], moment(hotel.publishingDate).format('DD.MM.YYYY - HH:mm'))}
                </div>
            </TableCell>
            <TableCell className={classes.cell}>
                <Tooltip title={<HotelInformation hotel={hotel}/>} classes={{tooltip: classes.tooltip}}
                         interactive={true}>
                    <Button variant={'text'} color={'primary'} className={classes.contactButton}>
                        {messages['hotels.contact.show']}
                    </Button>
                </Tooltip>
            </TableCell>
            <TableCell className={classes.cell}>
                <img src={hotel.User.language === Language.GERMAN ? german : english} className={classes.languageIcon}
                     alt={hotel.User.language} title={hotel.User.language}/>
            </TableCell>
            <TableCell className={classes.cell}>
                <IconButton className={classes.actionButton} onClick={(e) => setAnchorElement(e.currentTarget)}
                            ref={popperRef}>
                    <MoreIcon/>
                </IconButton>
                <HotelMenu hotel={hotel} anchorElement={anchorElement} hide={() => setAnchorElement(null)}
                           editHotel={editHotel}
                           openDeleteDialog={openDeleteDialog} openHighlightDialog={openHighlightDialog}
                           sendNewTokenMailFetchStatus={sendNewTokenMailFetchStatus}/>
            </TableCell>
        </TableRow>
    );
}

export default HotelRow;
