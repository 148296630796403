import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {AppState, Hotel, SnackbarVariant, Tag} from '../../types/types';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import Typography from '@material-ui/core/Typography';
import HotelRow from './HotelRow';
import {denormalize, getTagForName, normalize} from '../../helper/helperFunctions';
import FetchStatus from '../../api/FetchStatus';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import {alpha} from '@material-ui/core/styles/colorManipulator';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import beach from '../../images/src/icons/beach.svg';
import city from '../../images/src/icons/city.svg';
import mountains from '../../images/src/icons/mountains.svg';
import nature from '../../images/src/icons/nature.svg';
import wellness from '../../images/src/icons/wellness.svg';
import group from '../../images/src/icons/group.svg';
import sport from '../../images/src/icons/sport.svg';
import classNames, * as classnames from 'classnames';
import {makeStyles} from '@material-ui/core';
import {Theme} from '@material-ui/core/styles/createTheme';
import {useDispatch, useSelector} from 'react-redux';
import {
    addApplicationMessage, loadCountries,
    loadHotels, resetNewHotelToken,
    setHotelPage,
    setHotelStarStatus,
    setRowsPerHotelPage
} from '../../reducer/application/types';
import {useLocalization} from '../../localization/localization';
import HotelDialog from './hotels/HotelDialog';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        background: '#F8FAFF',
        minHeight: '100vh',
        paddingTop: 150,
        paddingBottom: 32
    },
    body: {
        background: '#F8FAFF',
    },
    table: {
        borderCollapse: 'separate',
        borderSpacing: '0 16px'
    },
    row: {
        marginBottom: 16,
        boxShadow: '0 2px 6px -2px rgba(0,0,0,0.12)',
        background: 'white',
        border: 'none',
    },
    starred: {
        background: '#FFDC65'
    },
    cell: {
        color: theme.palette.primary.light,
        fontSize: 14,
        lineHeight: '19px'
    },
    headerCell: {
        color: '#193150',
        fontSize: 15,
        fontWeight: 600,
        border: 'none',
        paddingBottom: 0
    },
    hotels: {
        width: '90%',
        margin: '0 auto',
    },
    deleteButton: {
        background: '#F91C3D',
        color: 'white',
        marginLeft: 8,
        '&:hover': {
            background: alpha('#F91C3D', 0.12)
        }
    },
    dialog: {
        padding: '8px 24px'
    },
    actionsSurround: {
        width: '90%',
        margin: '0 auto',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-start'
    },
    explanationSurround: {
        display: 'flex',
        alignItems: 'center'
    },
    dot: {
        width: 12,
        height: 12,
        borderRadius: 6,
        marginRight: theme.spacing(0.5)
    },
    blue: {
        background: theme.palette.primary.main
    },
    green: {
        background: '#13C5AC'
    },
    black: {
        background: '#193150'
    },
    explanation: {
        marginRight: theme.spacing(1.5),
        fontSize: 11,
        color: '#102434',
        letterSpacing: -0.18,
        lineHeight: 1
    },
    addButton: {
        textTransform: 'none',
        fontSize: 14,
        background: 'white',
        color: '#193150',
        fontWeight: 600,
        padding: theme.spacing(2, 3),
        boxShadow: '0 0 1px 0 rgba(25,49,80,0.3), 0 0 10px 0 rgba(0,0,0,0.08)',
        '&:hover': {
            boxShadow: '0 0 1px 0 rgba(25,49,80,0.3), 0 0 10px 0 rgba(0,0,0,0.08)'
        }
    },
    addIcon: {
        width: 16,
        height: 16,
        marginRight: theme.spacing(0.5)
    },
    input: theme.input,
    addRow: {
        display: 'flex',
        marginBottom: 24,
        justifyContent: 'space-between'
    },
    col: {
        width: '47.5%'
    },
    genderCol: {
        width: '20%'
    },
    nameCol: {
        width: '35%'
    },
    submitAddButton: {
        marginLeft: 16
    },
    genderPopperSurround: {
        boxShadow: '0 6px 16px -4px rgba(0,0,0,0.3)',
        background: 'white',
        borderRadius: 4,
        width: 105,
    },
    genderButton: {
        textTransform: 'none',
        fontSize: 16,
        outline: 'none',
        padding: '20px 21px',
        lineHeight: 1,
        textAlign: 'left',
        justifyContent: 'left',
        width: 105,
        boxSizing: 'border-box',
        borderRadius: 5,
        border: '1px solid #DDDFE7',
        transition: 'all 0.3s cubic-bezier(0.55, 0, 0.6, 1)',
        color: '#778198',
    },
    iconSurround: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '50%',
        width: 70,
        height: 70,
        background: 'linear-gradient(225deg, #2F89FC 0%, #30E3CA 100%)',
        boxShadow: '0 2px 12px 0 rgba(0,0,0,0.08)',
        opacity: 0.3,
        transition: 'all 0.3s cubic-bezier(0.55, 0, 0.6, 1)',
        cursor: 'pointer'
    },
    activeIconSurround: {
        opacity: 1
    },
    tagName: {
        marginTop: theme.spacing(1),
        textAlign: 'center'
    },
    activeTagName: {
        fontWeight: 600
    },
    selectHighlightTagSurround: {
        display: 'flex',
        justifyContent: 'space-between',
        margin: theme.spacing(3, 0)
    },
    tagSurround: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '13%'
    },
    tagImage: {
        width: '70%'
    },
    highlightDialog: {
        maxWidth: 900
    }
}));

const Hotels: React.FC = () => {
    const classes = useStyles();


    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [showAddDialog, setShowAddDialog] = useState(false);
    const [showTokenDialog, setShowTokenDialog] = useState(false);
    const [showHighlightDialog, setShowHighlightDialog] = useState(false);
    const [deleteCallback, setDeleteCallback] = useState<(() => void) | null>(null);
    const [highlightID, setHighlightID] = useState<number>(-1);
    const [highlightName, setHighlightName] = useState<string>('');
    const [selectedHighlightCategory, setSelectedHighlightCategory] = useState<number>(-1);
    const [editedHotel, setEditedHotel] = useState<Hotel | undefined>(undefined);

    const dispatch = useDispatch();
    const messages = useLocalization();

    const {
        sendNewTokenMailFetchStatus, selectedHotels, createToken,
        hotelPage, hotels, numberOfHotels, rowsPerHotelPage, tags
    } = useSelector((state: AppState) => ({
        hotels: denormalize(state.application.hotels).sort((h1, h2) => h2.id - h1.id).slice(state.application.hotelPage * state.application.rowsPerHotelPage, (state.application.hotelPage + 1) * state.application.rowsPerHotelPage),
        tags: denormalize(state.application.tags),
        selectedHotels: state.application.selectedHotels,
        createToken: state.application.createdToken,
        sendNewTokenMailFetchStatus: state.application.sendNewTokenMailFetchStatus,
        hotelPage: state.application.hotelPage,
        rowsPerHotelPage: state.application.rowsPerHotelPage,
        numberOfHotels: denormalize(state.application.hotels).length
    }));
    const normalizedHotels = useMemo(() => normalize(hotels, 'id'), [hotels]);

    useEffect(() => {
        dispatch(loadHotels());
        dispatch(loadCountries());
    }, [dispatch]);


    useEffect(() => {
        if (sendNewTokenMailFetchStatus === FetchStatus.SUCCESS) {
            dispatch(addApplicationMessage({
                variant: SnackbarVariant.SUCCESS,
                key: `new-token-message-${new Date().getTime()}`,
                text: messages['hotels.actions.token.re.send.success']
            }));
        } else if (sendNewTokenMailFetchStatus === FetchStatus.ERROR) {
            dispatch(addApplicationMessage({
                variant: SnackbarVariant.ERROR,
                key: `new-token-message-${new Date().getTime()}`,
                text: messages['hotels.actions.token.re.send.error']
            }));
        }
    }, [sendNewTokenMailFetchStatus, messages, dispatch]);

    useEffect(() => {
        if (Boolean(createToken)) {
            setShowTokenDialog(true);
        }
    }, [createToken]);

    const openDeleteDialog = useCallback((callback: () => void | null) => {
        setShowDeleteDialog(true);
        setDeleteCallback(callback)
    }, []);

    const isTagSelected = useCallback((tagID: number) => {
        return selectedHighlightCategory === tagID || (selectedHighlightCategory === -1 && Boolean(normalizedHotels[highlightID]?.HotelHighlights?.map(it => it.tagID).includes(tagID)));
    }, [selectedHighlightCategory, normalizedHotels, highlightID]);

    let beachTag: Tag | undefined = undefined;
    let wellnessTag: Tag | undefined = undefined;
    let natureTag: Tag | undefined = undefined;
    let mountainsTag: Tag | undefined = undefined;
    let cityTag: Tag | undefined = undefined;
    let groupsTag: Tag | undefined = undefined;
    let sportTag: Tag | undefined = undefined;
    if (tags) {
        beachTag = getTagForName(tags, 'beach');
        wellnessTag = getTagForName(tags, 'wellness');
        natureTag = getTagForName(tags, 'nature');
        mountainsTag = getTagForName(tags, 'mountains');
        cityTag = getTagForName(tags, 'city');
        groupsTag = getTagForName(tags, 'groups');
        sportTag = getTagForName(tags, 'sport');
    }

    return (
        <div className={classes.root}>
            <div className={classes.actionsSurround}>
                <div className={classes.explanationSurround}>
                    <span className={classnames(classes.dot, classes.blue)}/>
                    <Typography variant={'body1'} className={classes.explanation}>
                        {messages.hotels.statistics.email}
                    </Typography>
                    <span className={classnames(classes.dot, classes.green)}/>
                    <Typography variant={'body1'} className={classes.explanation}>
                        {messages.hotels.statistics.phone}
                    </Typography>
                    <span className={classnames(classes.dot, classes.black)}/>
                    <Typography variant={'body1'} className={classes.explanation}>
                        {messages.hotels.statistics.website}
                    </Typography>
                </div>
                <Button variant={'contained'} className={classes.addButton}
                        onClick={() => {
                            setEditedHotel(undefined);
                            setShowAddDialog(true);
                        }}>
                    <AddIcon className={classes.addIcon}/>
                    {messages['hotels.add']}
                </Button>
            </div>
            <div className={classes.hotels}>
                <Table
                    className={classes.table}
                    aria-labelledby={'hotels-table'}
                    size={'medium'}>
                    <TableHead>
                        <TableRow>
                            <TableCell className={classes.headerCell}/>
                            <TableCell className={classes.headerCell} align={'left'} padding={'default'}>
                                {messages['hotels.table.header.statistics']}
                            </TableCell>
                            <TableCell className={classes.headerCell} align={'left'} padding={'default'}>
                                {messages['hotels.table.header.name']}
                            </TableCell>
                            <TableCell className={classes.headerCell} align={'left'} padding={'default'}>
                                {messages['hotels.table.header.objectNumber']}
                            </TableCell>
                            <TableCell className={classes.headerCell} align={'left'} padding={'default'}>
                                {messages['hotels.table.header.model']}
                            </TableCell>
                            <TableCell className={classes.headerCell} align={'left'} padding={'default'}>
                                {messages['hotels.table.header.state']}
                            </TableCell>
                            <TableCell className={classes.headerCell} align={'left'} padding={'default'}>
                                {messages['hotels.table.header.active']}
                            </TableCell>
                            <TableCell className={classes.headerCell} align={'left'} padding={'default'}>
                                {messages['hotels.table.header.contact']}
                            </TableCell>
                            <TableCell className={classes.headerCell} align={'left'} padding={'default'}>
                                {messages['hotels.table.header.language']}
                            </TableCell>
                            <TableCell className={classes.headerCell} align={'left'} padding={'default'}>
                                {messages['hotels.table.header.action']}
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {hotels.map((hotel) =>
                            <HotelRow hotel={hotel}
                                      key={`enhanced-table-checkbox-${hotel.id}`}
                                      selected={selectedHotels.includes(hotel.id)}
                                      openDeleteDialog={openDeleteDialog}
                                      sendNewTokenMailFetchStatus={sendNewTokenMailFetchStatus}
                                      openHighlightDialog={() => {
                                          setShowHighlightDialog(true);
                                          setHighlightID(hotel.id);
                                          setHighlightName(hotel.name)
                                      }}
                                      editHotel={() => {
                                          setEditedHotel(hotel);
                                          setShowAddDialog(true);
                                      }}/>)}
                    </TableBody>
                </Table>
                <TablePagination
                    onPageChange={() => {
                    }}
                    labelRowsPerPage={'Unterkünfte pro Seite'}
                    labelDisplayedRows={({from, to, count}) => `${from}-${to === -1 ? count : to} von ${count}`}
                    rowsPerPageOptions={[10, 25, 50, 100]}
                    component={'div'}
                    count={numberOfHotels}
                    rowsPerPage={rowsPerHotelPage}
                    page={hotelPage}
                    onChangePage={(e, page) => dispatch(setHotelPage(page))}
                    onChangeRowsPerPage={e => {
                        dispatch(setRowsPerHotelPage(Number(e.target.value)))
                    }}
                />
            </div>
            <Dialog
                classes={{paper: classes.dialog}}
                open={showDeleteDialog}
                onClose={() => setShowDeleteDialog(false)}>
                <DialogTitle>{messages['hotels.actions.delete.title']}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {messages['hotels.actions.delete.content']}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setShowDeleteDialog(false)} color={'secondary'}>
                        {messages['hotels.actions.delete.cancel']}
                    </Button>
                    <Button onClick={() => {
                        if (deleteCallback) {
                            deleteCallback();
                        }
                        setShowDeleteDialog(false);
                    }} color={'primary'} className={classes.deleteButton}>
                        {messages['hotels.actions.delete.submit']}
                    </Button>
                </DialogActions>
            </Dialog>
            {showAddDialog && <HotelDialog open={showAddDialog} close={() => setShowAddDialog(false)} hotel={editedHotel}/>}
            <Dialog onClose={() => {
                setShowTokenDialog(false);
                dispatch(resetNewHotelToken());
            }} open={showTokenDialog}>
                <DialogTitle>{messages['hotels.add.token.dialog']}</DialogTitle>
                <List>
                    <ListItem>
                        <ListItemText primary={createToken}/>
                    </ListItem>
                    <ListItem button onClick={() => setShowTokenDialog(false)}>
                        <ListItemText primary={messages['close']}/>
                    </ListItem>
                </List>
            </Dialog>
            <Dialog
                classes={{paper: classes.highlightDialog}}
                open={showHighlightDialog}
                onClose={() => setShowHighlightDialog(false)}>
                <DialogTitle>{messages['hotels.actions.add.highlights.1']} "{highlightName}" {messages['hotels.actions.add.highlights.2']}</DialogTitle>
                <DialogContent>
                    <div className={classes.selectHighlightTagSurround}>
                        {beachTag &&
                        <div className={classes.tagSurround}
                             onClick={() => setSelectedHighlightCategory(beachTag?.id || -1)}>
                            <div
                                className={classNames(classes.iconSurround, {
                                    [classes.activeIconSurround]: isTagSelected(beachTag.id)
                                })}>
                                <img alt={''} src={beach} className={classes.tagImage}/>
                            </div>
                            <Typography variant={'body1'}
                                        className={classNames(classes.tagName, {
                                            [classes.activeTagName]: isTagSelected(beachTag.id)
                                        })}>
                                {messages['hotels.highlights.beach']}
                            </Typography>
                        </div>}
                        {wellnessTag &&
                        <div className={classes.tagSurround}
                             onClick={() => setSelectedHighlightCategory(wellnessTag?.id || -1)}>
                            <div
                                className={classNames(classes.iconSurround, {
                                    [classes.activeIconSurround]: isTagSelected(wellnessTag.id)
                                })}>
                                <img alt={''} src={wellness} className={classes.tagImage}/>
                            </div>
                            <Typography variant={'body1'}
                                        className={classNames(classes.tagName, {
                                            [classes.activeTagName]: isTagSelected(wellnessTag.id)
                                        })}>
                                {messages['hotels.highlights.wellness']}
                            </Typography>
                        </div>
                        }
                        {natureTag &&
                        <div className={classes.tagSurround}
                             onClick={() => setSelectedHighlightCategory(natureTag?.id || -1)}>
                            <div
                                className={classNames(classes.iconSurround, {
                                    [classes.activeIconSurround]: isTagSelected(natureTag.id)
                                })}>
                                <img alt={''} src={nature} className={classes.tagImage}/>
                            </div>
                            <Typography variant={'body1'}
                                        className={classNames(classes.tagName, {
                                            [classes.activeTagName]: isTagSelected(natureTag.id)
                                        })}>
                                {messages['hotels.highlights.nature']}
                            </Typography>
                        </div>}
                        {mountainsTag &&
                        <div className={classes.tagSurround}
                             onClick={() => setSelectedHighlightCategory(mountainsTag?.id || -1)}>
                            <div
                                className={classNames(classes.iconSurround, {
                                    [classes.activeIconSurround]: isTagSelected(mountainsTag.id)
                                })}>
                                <img alt={''} src={mountains} className={classes.tagImage}/>
                            </div>
                            <Typography variant={'body1'}
                                        className={classNames(classes.tagName, {
                                            [classes.activeTagName]: isTagSelected(mountainsTag.id)
                                        })}>
                                {messages['hotels.highlights.mountains']}
                            </Typography>
                        </div>}
                        {cityTag &&
                        <div className={classes.tagSurround}
                             onClick={() => setSelectedHighlightCategory(cityTag?.id || -1)}>
                            <div
                                className={classNames(classes.iconSurround, {
                                    [classes.activeIconSurround]: isTagSelected(cityTag.id)
                                })}>
                                <img alt={''} src={city} className={classes.tagImage}/>
                            </div>
                            <Typography variant={'body1'}
                                        className={classNames(classes.tagName, {
                                            [classes.activeTagName]: isTagSelected(cityTag.id)
                                        })}>
                                {messages['hotels.highlights.city']}
                            </Typography>
                        </div>}
                        {groupsTag &&
                        <div className={classes.tagSurround}
                             onClick={() => setSelectedHighlightCategory(groupsTag?.id || -1)}>
                            <div
                                className={classNames(classes.iconSurround, {
                                    [classes.activeIconSurround]: isTagSelected(groupsTag.id)
                                })}>
                                <img alt={''} src={group} className={classes.tagImage}/>
                            </div>
                            <Typography variant={'body1'}
                                        className={classNames(classes.tagName, {
                                            [classes.activeTagName]: isTagSelected(groupsTag.id)
                                        })}>
                                {messages['hotels.highlights.groups']}
                            </Typography>
                        </div>}
                        {sportTag &&
                        <div className={classes.tagSurround}
                             onClick={() => setSelectedHighlightCategory(sportTag?.id || -1)}>
                            <div
                                className={classNames(classes.iconSurround, {
                                    [classes.activeIconSurround]: isTagSelected(sportTag.id)
                                })}>
                                <img alt={''} src={sport} className={classes.tagImage}/>
                            </div>
                            <Typography variant={'body1'}
                                        className={classNames(classes.tagName, {
                                            [classes.activeTagName]: isTagSelected(sportTag.id)
                                        })}>
                                {messages['hotels.highlights.sport']}
                            </Typography>
                        </div>}
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => {
                        setShowHighlightDialog(false);
                        setHighlightID(-1);
                        setSelectedHighlightCategory(-1);
                        setHighlightName('');
                    }} color={'secondary'}>
                        {messages['hotels.actions.add.cancel']}
                    </Button>
                    <Button onClick={() => {
                                if (highlightID > -1) {
                                    dispatch(setHotelStarStatus({
                                        id: highlightID,
                                        categoryIDs: [selectedHighlightCategory]
                                    }));
                                    setShowHighlightDialog(false);
                                    setHighlightID(-1);
                                    setSelectedHighlightCategory(-1);
                                    setHighlightName('');
                                }
                            }} color={'primary'}
                            className={classes.submitAddButton} variant={'contained'} autoFocus>
                        {messages['hotels.actions.highlight.submit']}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default Hotels;
