import React, {CSSProperties, useEffect, useState} from 'react';
import {Dictionary} from '@reduxjs/toolkit';
import {makeStyles} from '@material-ui/core/styles';
import {Theme} from '@material-ui/core/styles/createTheme';
import classnames from 'classnames';
import {useWindowSize} from '../../../hooks/hooks';
import Image from '../../helper/Image';

interface BlogImageProps {
    imageSet: Dictionary<Dictionary<string>>
    heightRatio?: number
    placement?: 'top' | 'bottom' | 'center'
    alt?: string
    title?: string
    className?: string
    fullWidth?: boolean
}

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        position: 'relative',
        overflow: 'hidden',
        width: '100%',
        [theme.breakpoints.down('xs')]: {
            borderRadius: 0
        }
    },
    image: {
        position: 'absolute',
        top: 0,
        left: '50%',
        height: '100%',
        transform: 'translate(-50%, 0)'
    }
}));

const BlogImage: React.FC<BlogImageProps> = ({imageSet, heightRatio, placement,
                                                 alt, title, className, fullWidth = false}) => {

    const classes = useStyles();

    const [imageSurroundRef, setImageSurroundRef] = useState<HTMLDivElement | null>(null);
    const [imageLoaded, setImageLoaded] = useState(false);
    const [imageRef, setImageRef] = useState<HTMLImageElement | null>(null);
    const [imageStyle, setImageStyle] = useState<CSSProperties>({
        height: '100%'
    });
    const [imageSurroundStyle, setImageSurroundStyle] = useState<CSSProperties>({});

    const windowSize = useWindowSize();

    useEffect(() => {
        if (imageSurroundRef && imageRef && imageLoaded) {
            if (heightRatio) {
                if (Math.round(imageSurroundRef.clientWidth * heightRatio) !== Math.round(imageSurroundStyle.height as number || 0)) {
                    setImageSurroundStyle({
                        height: imageSurroundRef.clientWidth * heightRatio
                    });
                }
            }
            const imageRatio = imageRef.clientWidth / imageRef.clientHeight;
            const surroundRatio = imageSurroundRef.clientWidth / imageSurroundRef.clientHeight;
            if (fullWidth || surroundRatio > imageRatio) {
                let yShift = -50;
                let top = '50%';
                let bottom = 'auto';
                if (placement) {
                    switch (placement) {
                        case 'top':
                            yShift = 0;
                            top = '0';
                            break;
                        case 'bottom':
                            top = 'auto';
                            bottom = '0';
                            yShift = 0;
                            break;
                        default:
                            yShift = -55;
                            top = '50%';
                            bottom = 'auto';
                            break;
                    }
                }
                setImageStyle({
                    width: '100%',
                    height: 'auto',
                    left: 0,
                    top,
                    bottom,
                    transform: `translate(0, ${yShift}%)`
                });
            } else {
                setImageStyle({
                    height: '100%',
                    width: 'auto',
                    top: 0,
                    left: '50%',
                    transform: 'translate(-50%, 0)'
                });
            }
        }
    }, [imageLoaded, imageRef, imageSurroundRef, placement, heightRatio, windowSize, imageSurroundStyle, fullWidth]);

    if (Boolean(imageSet)) {
        return (
            <div className={classnames(classes.root, className)} ref={(ref) => {
                if (!imageSurroundRef && ref) {
                    setImageSurroundRef(ref);
                }
            }} style={imageSurroundStyle}>
                <Image srcSet={imageSet} alt={alt} className={classes.image} title={title} imageRef={(ref) => {
                    if (!imageRef && ref) {
                        setImageRef(ref);
                    }
                }} style={imageStyle} onLoad={() => setImageLoaded(true)}/>
            </div>
        );
    }
    return null;
}

export default BlogImage;