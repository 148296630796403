import React, {useState} from 'react';
import heroJPGLG from '../../images/dest/login/hero-lg@1x.jpg';
import heroJPGLG2x from '../../images/dest/login/hero-lg@2x.jpg';
import heroWebPLG from '../../images/dest/login/hero-lg@1x.webp';
import heroWebPLG2x from '../../images/dest/login/hero-lg@2x.webp';
import heroJPGMD from '../../images/dest/login/hero-md@1x.jpg';
import heroJPGMD2x from '../../images/dest/login/hero-md@2x.jpg';
import heroWebPMD from '../../images/dest/login/hero-md@1x.webp';
import heroWebPMD2x from '../../images/dest/login/hero-md@2x.webp';
import heroJPGSM from '../../images/dest/login/hero-sm@1x.jpg';
import heroJPGSM2x from '../../images/dest/login/hero-sm@2x.jpg';
import heroWebPSM from '../../images/dest/login/hero-sm@1x.webp';
import heroWebPSM2x from '../../images/dest/login/hero-sm@2x.webp';
import heroJPGXS from '../../images/dest/login/hero-xs@1x.jpg';
import heroJPGXS2x from '../../images/dest/login/hero-xs@2x.jpg';
import heroWebPXS from '../../images/dest/login/hero-xs@1x.webp';
import heroWebPXS2x from '../../images/dest/login/hero-xs@2x.webp';
import Image from '../helper/Image';
import {Typography, Button, makeStyles, useMediaQuery} from '@material-ui/core';
import FetchStatus from '../../api/FetchStatus';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import IconButton from '@material-ui/core/IconButton';
import {Theme} from '@material-ui/core/styles/createTheme';
import {useDispatch, useSelector} from 'react-redux';
import {useLocalization} from '../../localization/localization';
import {loginUser} from '../../reducer/user/types';
import {AppState} from '../../types/types';


const useStyles = makeStyles((theme: Theme) => ({
    root: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            paddingTop: 100
        }
    },
    loginSurround: {
        width: '50%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            margin: '0 auto'
        }
    },
    content: {
        maxWidth: 400,
        [theme.breakpoints.down('sm')]: {
            width: '90%'
        }
    },
    heroSurround: {
        width: '50%',
        position: 'relative',
        height: '100vh',
        overflow: 'hidden'
    },
    image: {
        ...theme.fullSizeImage
    },
    inputSurround: {
        maxWidth: 400,
        marginBottom: 16,
        position: 'relative'
    },
    button: {
        ...theme.gradientButton,
        width: 400,
        marginBottom: 16,
        textAlign: 'center',
        [theme.breakpoints.down('sm')]: {
            width: '100%'
        }
    },
    input: theme.input,
    visibilityButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: 3
    },
    headline: {
        color: '#102434',
        fontSize: 40,
        lineHeight: '50px',
        fontWeight: 600,
        marginBottom: 14
    },
    description: {
        color: '#8696A2',
        marginBottom: 24,
        fontSize: 18,
        lineHeight: '28px',
        maxWidth: 400
    },
    passwordSurround: {
        textAlign: 'center',
    },
    password: {
        color: '#B4B9C0',
        fontSize: 15,
        maxWidth: 400,
        cursor: 'pointer',
        textDecoration: 'none',
        transition: 'all 0.25s cubic-bezier(0.55, 0, 0.55, 1)',
        '&:hover': {
            color: theme.palette.primary.main,
        }
    },
}));

const Login: React.FC = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const messages = useLocalization();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const isWidthUpMD = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
    const fetchStatus = useSelector((state: AppState) => state.user.loginFetchStatus);

    const submit = () => {
        if (email?.trim() && password?.trim()) {
            dispatch(loginUser({
                email,
                password
            }));
        }
    }

    return (
        <div className={classes.root}>
            <div className={classes.loginSurround}>
                <div className={classes.content}>
                    <Typography variant={'h2'} className={classes.headline}>
                        {messages['login.headline']}
                    </Typography>
                    <Typography variant={'body1'} className={classes.description}>
                        {messages['login.description']}
                    </Typography>
                    <div className={classes.inputSurround}>
                        <input type={'text'} name={'email'} className={classes.input}
                               placeholder={messages['login.email.placeholder']}
                               onKeyUp={(e) => {
                                   if (e.keyCode === 13) {
                                       submit();
                                   }
                               }}
                               onChange={(e) => setEmail(e.currentTarget.value.trim())}/>
                    </div>
                    <div className={classes.inputSurround}>
                        <input type={showPassword ? 'text' : 'password'} name={'password'} className={classes.input}
                               placeholder={messages['login.password.placeholder']}
                               onKeyUp={(e) => {
                                   if (e.keyCode === 13) {
                                       submit();
                                   }
                               }}
                               onChange={(e) => setPassword(e.currentTarget.value?.trim())}/>
                        <IconButton className={classes.visibilityButton} arial-label={'show-hide-password'} onClick={() => setShowPassword(!showPassword)}>
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                    </div>
                    <div>
                        <Button className={classes.button} onClick={submit}
                                disabled={fetchStatus === FetchStatus.ACTIVE}>
                            {messages['login.submit']}
                        </Button>
                    </div>
                </div>
            </div>
            {isWidthUpMD &&
                <div className={classes.heroSurround}>
                    <Image alt={''} srcSet={{
                        webP: {
                            lg2x: heroWebPLG2x,
                            lg: heroWebPLG,
                            md2x: heroWebPMD2x,
                            md: heroWebPMD,
                            sm2x: heroWebPSM2x,
                            sm: heroWebPSM,
                            xs2x: heroWebPXS2x,
                            xs: heroWebPXS
                        },
                        fallback: {
                            lg2x: heroJPGLG2x,
                            lg: heroJPGLG,
                            md2x: heroJPGMD2x,
                            md: heroJPGMD,
                            sm2x: heroJPGSM2x,
                            sm: heroJPGSM,
                            xs2x: heroJPGXS2x,
                            xs: heroJPGXS
                        }
                    }} className={classes.image}/>
                </div>}
        </div>
    );
}

export default Login;
