import React from 'react';
import {Link} from 'react-router-dom';
import Button from '@material-ui/core/Button';
import classNames from 'classnames';
import logo from '../../images/src/logo/logo.svg';
import {makeStyles} from '@material-ui/core';
import {Theme} from '@material-ui/core/styles/createTheme';
import {useLocalization} from '../../localization/localization';
import {useDispatch, useSelector} from 'react-redux';
import {AppState} from '../../types/types';
import {setShowChangePasswordDialog} from '../../reducer/application/types';
import {logout} from '../../reducer/user/types';


const useStyles = makeStyles((theme: Theme) => ({
    root: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '14px 0',
        boxShadow: '0 0 8px 0 rgba(0,0,0,0.2)',
        position: 'fixed',
        zIndex: 100,
        width: '100%',
        background: 'white',
        [theme.breakpoints.down('xs')]: {
            boxShadow: 'none',
            background: 'white',
            justifyContent: 'flex-start'
        }
    },
    inner: {
        ...theme.body,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        [theme.breakpoints.down('xs')]: {
            width: '90%',
            margin: '0 auto'
        }
    },
    link: {
        ...theme.link,
        color: '#4A4A4A',
        fontSize: 16,
        marginRight: 40,
        [theme.breakpoints.down('xs')]: {
            marginRight: 0,
            fontSize: 24,
            lineHeight: '24px',
            fontWeight: 600,
            marginBottom: 24
        }
    },
    button: {
        textTransform: 'none',
        fontSize: 16,
        padding: '8px 16px',
        fontWeight: 600,
        letterSpacing: 0.5,
    },
    buttonLabel: {
        background: 'linear-gradient(225deg, #2F89FC 0%, #30E3CA 100%)',
        WebkitBackgroundClip: 'text',
        WebkitTextFillColor: 'transparent'
    },
    logo: {
        height: 60,
        marginLeft: 0,
        [theme.breakpoints.down('xs')]: {
            height: 52,
            marginBottom: 0
        }
    },
    menuIcon: {
        fontSize: 32,
        color: theme.palette.primary.light
    },
    mobileMenu: {
        background: 'white',
        position: 'fixed',
        display: 'none',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        top: 80,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: 1000,
    },
    mobileMenuActive: {
        display: 'flex'
    },
    gradientLink: {
        background: 'linear-gradient(225deg, #2F89FC 0%, #30E3CA 100%)',
        WebkitBackgroundClip: 'text',
        WebkitTextFillColor: 'transparent'
    },
    noMargin: {
        margin: 0
    },
    linkActive: {
        color: '#2F89FC'
    }
}));

const Menu: React.FC = () => {
    const classes = useStyles();
    const messages = useLocalization();
    const dispatch = useDispatch();

    const {jwt} = useSelector((state: AppState) => ({
        jwt: state.user.jwt
    }));
    
    return (
        <div className={classes.root}>
            <div className={classes.inner}>
                <Link to={'/'} className={classNames(classes.link, classes.noMargin)}>
                    <img className={classes.logo} src={logo} alt={messages['app.name']}/>
                </Link>
                {Boolean(jwt) &&
                    <div>
                        <Link to={'/'} className={classes.link}>
                            {messages['admin']}
                        </Link>
                        <Link to={'/blog/categories'} className={classes.link}>
                            {messages.blog.title}
                        </Link>
                        <span onClick={() => dispatch(setShowChangePasswordDialog(true))} className={classes.link}>
                            {messages['change.password']}
                        </span>
                        <Link to={'/changelog'} className={classes.link}>
                            {messages['changelog']}
                        </Link>
                        <Button className={classes.button} variant={'contained'}
                                color={'primary'} onClick={() => dispatch(logout())}>
                            {messages['menu.logout']}
                        </Button>
                    </div>}
            </div>
        </div>
    );
}


export default Menu;
