import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Theme} from '@material-ui/core/styles/createTheme';
import {useHistory, useParams} from 'react-router';
import {Link} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {
    addNewBlogCategory,
    deleteBlogPost,
    loadBlogCategoryPosts, removeBlogPostFromState
} from '../../../../reducer/blog/types';
import {AppState, BlogPostAction, Language, LocalizationKey} from '../../../../types/types';
import FetchStatus from '../../../../api/FetchStatus';
import {Grid, Typography} from '@material-ui/core';
import {useLocalization} from '../../../../localization/localization';
import {
    AppSnackbarContent,
    getLocalizationValueForLanguageAndKey,
    mapImageToSrcSet,
    notUndefined
} from '../../../../helper/helperFunctions';
import BlogImage from '../BlogImage';
import {Skeleton} from '@material-ui/lab';
import classnames from 'classnames';
import {Add} from '@material-ui/icons';
import Post from './Post';
import Snackbar from '@material-ui/core/Snackbar';
import ConfirmationDialog from '../../../skeleton/dialogs/ConfirmationDialog';
import Back from '../../../icons/Back';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        ...theme.body,
        paddingTop: 100
    },
    breadcrumb: {
        fontSize: 20,
        color: theme.palette.primary.light,
        lineHeight: '32px',
        marginTop: theme.spacing(4.5),
        display: 'flex',
        '& span': {
            fontWeight: 600
        },
        '& $categoryName': {
            color: theme.palette.primary.main,
            fontWeight: 400
        }
    },
    categoryName: {},
    title: {
        fontSize: 45,
        color: '#193150',
        fontWeight: 600,
        lineHeight: '68px',
        marginBottom: theme.spacing(1.5)
    },
    titleImage: {
        width: '100%',
        height: 600,
        borderRadius: 6
    },
    categoryTitleSurround: {
        position: 'relative',
        marginBottom: theme.spacing(10)
    },
    categoryTitle: {
        position: 'absolute',
        fontSize: 62,
        lineHeight: '68px',
        color: '#102434',
        padding: theme.spacing(3, 4, 2.5),
        fontWeight: 600,
        borderRadius: 4,
        background: 'white',
        boxShadow: '0 0 1px 0 rgba(0,0,0,0.2), 0 4px 18px 0 rgba(0,0,0,0.08), 0 1px 24px 0 rgba(0,0,0,0.04)',
        bottom: 0,
        left: '50%',
        transform: 'translate(-50%, 50%)',
        textAlign: 'center',
        whiteSpace: 'nowrap'
    },
    categoryDescription: {
        maxWidth: 1030,
        width: '90%',
        margin: '0 auto',
        color: theme.palette.primary.light,
        fontSize: 20,
        lineHeight: '32px',
        textAlign: 'center',
        marginBottom: theme.spacing(6)
    },
    grid: {
        maxWidth: 1238,
        margin: '0 auto'
    },
    loader: {
        height: 453
    },
    skeleton: {
        height: '100%',
        borderRadius: 6
    },
    gridItem: {
        borderRadius: 6,
        background: 'white',
        transition: theme.defaultTransition,
        boxShadow: '0 0 1px 0 rgba(16,36,52,0.25), 0 4px 12px 0 rgba(0,0,0,0.06)',
        height: 413,
        padding: '0px !important',
        '&:hover': {
            boxShadow: '0 16px 32px 0 rgba(61,66,69,0.12), 0 0 1px 0 rgba(16,36,52,0.25), 0 4px 15px 0 rgba(46,49,52,0.12);'
        }
    },
    addItem: {
        cursor: 'pointer',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        padding: `${theme.spacing(8)}px !important`,
        boxSizing: 'border-box'
    },
    addIcon: {
        color: '#193150',
        marginBottom: theme.spacing(0),
        width: 126,
        height: 126
    },
    addText: {
        color: '#193150',
        fontWeight: 600,
        fontSize: 36,
        lineHeight: '44px',
        textAlign: 'center'
    },
    link: theme.link,
    postItem: {
        minHeight: 413
    },
    delete: {
        fontSize: 20,
        color: theme.palette.primary.light,
        lineHeight: '32px'
    },
    back: {
        width: 24,
        height: 24,
        color: theme.palette.primary.light,
        marginRight: theme.spacing(1),
        marginTop: 3
    }
}));

const Overview: React.FC = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const messages = useLocalization();
    const {push} = useHistory();

    const {categoryID, action} = useParams<{ categoryID?: string, action?: string }>();
    const usedCategoryID = useMemo(() => parseInt(categoryID || '-2', 10), [categoryID]);
    const [deletePost, setDeletePost] = useState<number | null>(null);

    const {category, posts, postsByCategory, fetchStatus} = useSelector((state: AppState) => ({
        category: state.blog.categories[usedCategoryID],
        fetchStatus: state.blog.fetchStatus.posts[usedCategoryID],
        posts: state.blog.posts,
        postsByCategory: state.blog.postsByCategory[usedCategoryID]
    }));

    const [snackbar, setSnackbar] = useState<{
        showSnackbar: boolean
        snackbarText: string
        snackbarIcon?: any,
        snackbarLevel: FetchStatus
    }>({
        showSnackbar: false,
        snackbarText: '',
        snackbarLevel: FetchStatus.SUCCESS
    });

    const openSnackBar = useCallback((text: string, icon: any, level: FetchStatus) => setSnackbar({
        showSnackbar: true,
        snackbarText: text,
        snackbarIcon: icon,
        snackbarLevel: level
    }), []);

    useEffect(() => {
        if (usedCategoryID > -2 && !postsByCategory) {
            dispatch(loadBlogCategoryPosts(usedCategoryID));
        }
    }, [usedCategoryID, postsByCategory, dispatch]);

    useEffect(() => {
        const post = posts[deletePost || -2];
        if (post?.deleteFetchStatus === FetchStatus.SUCCESS) {
            openSnackBar(messages.blog.posts.manage.delete.success, null, FetchStatus.SUCCESS);
            setDeletePost(null);
            dispatch(removeBlogPostFromState(post.id));
        } else if (post?.deleteFetchStatus === FetchStatus.ERROR) {
            openSnackBar(messages.blog.posts.manage.delete.error, null, FetchStatus.ERROR);
        }
    }, [messages.blog.posts.manage.delete, posts, deletePost, dispatch, openSnackBar]);

    useEffect(() => {
        if (action === BlogPostAction.SAVED) {
            openSnackBar(messages.blog.posts.manage.save.success, null, FetchStatus.SUCCESS);
            push(`/blog/categories/${usedCategoryID}`);
        } else if (action === BlogPostAction.DELETED) {
            openSnackBar(messages.blog.posts.manage.delete.success, null, FetchStatus.SUCCESS);
            push(`/blog/categories/${usedCategoryID}`);
        } else if (usedCategoryID > -2 && action === BlogPostAction.FETCH) {
            dispatch(loadBlogCategoryPosts(usedCategoryID));
            push(`/blog/categories/${usedCategoryID}`);
        }
    }, [action, openSnackBar, messages.blog.posts.manage, usedCategoryID, push, dispatch]);

    const usedPosts = useMemo(() => postsByCategory?.map(it => posts[it]).filter(notUndefined) || [], [posts, postsByCategory]);

    if (category) {

        let content;
        const addItem = (
            <Grid item={true} xs={12} sm={6} md={4}>
                <Link to={`/blog/categories/${usedCategoryID}/post/-1`} className={classes.link}>
                    <div className={classnames(classes.gridItem, classes.addItem)}
                         onClick={() => {
                             dispatch(addNewBlogCategory());
                         }}>
                        <Add className={classes.addIcon}/>
                        <Typography variant={'body1'} className={classes.addText}>
                            {messages.blog.posts.create.link}
                        </Typography>
                    </div>
                </Link>
            </Grid>
        )

        if (fetchStatus === FetchStatus.ACTIVE) {
            content = (<Grid container={true} spacing={5} className={classes.grid}>
                {addItem}
                {[...new Array(9)].map((_, index) => <Grid xs={12} sm={6} md={4}
                                                           key={`blog-category-loader-item-${index}`}
                                                           item={true} className={classes.loader}>
                    <Skeleton variant={'rect'} animation={'wave'} className={classes.skeleton}/>
                </Grid>)}
            </Grid>)
        } else {
            content = (<Grid container={true} spacing={5} className={classes.grid}>
                {addItem}
                {usedPosts.filter(it => it.id > -1).map(it => (
                    <Grid item={true} xs={12} sm={6} md={4} className={classes.postItem}
                          key={`post-item-${it.id}`}>
                        <Post post={it} onDelete={() => setDeletePost(it.id)} openSnackbar={openSnackBar}/>
                    </Grid>
                ))}
            </Grid>);
        }

        const categoryName = getLocalizationValueForLanguageAndKey(category.Localizations, LocalizationKey.BlogCategoryName, Language.GERMAN);

        const imageSet = category.BlogImages[0]?.path ? mapImageToSrcSet(category.BlogImages[0]?.path, category.BlogImages[0]?.type) : undefined;

        return (
            <div className={classes.root}>
                <Typography variant={'h3'} className={classes.breadcrumb}>
                    <Link to={'/blog/categories'} className={classes.link}>
                        <Back className={classes.back}/>
                    </Link>
                    <div>
                        <span>{messages.blog.posts.breadcrumbBlog}</span> /&nbsp;
                        <span>&nbsp;
                            <Link to={`/blog/categories`} className={classes.link}>
                        {messages.blog.posts.breadcrumbCategories}
                        </Link>
                    </span> /&nbsp;
                        <span className={classes.categoryName}>{categoryName}</span>
                    </div>
                </Typography>
                <Typography variant={'h1'} className={classes.title}>
                    {messages.blog.posts.title}
                </Typography>
                <div className={classes.categoryTitleSurround}>
                    {imageSet !== undefined &&
                    <BlogImage imageSet={imageSet} placement={'center'} className={classes.titleImage}
                               fullWidth={true}/>}
                    <Typography variant={'h2'} className={classes.categoryTitle}>
                        {categoryName}
                    </Typography>
                </div>
                <Typography variant={'body1'} className={classes.categoryDescription}>
                    {getLocalizationValueForLanguageAndKey(category.Localizations, LocalizationKey.BlogCategoryDescription, Language.GERMAN)}
                </Typography>
                {content}
                <Snackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right'
                    }}
                    open={snackbar.showSnackbar}
                    autoHideDuration={2000}
                    onClose={() => setSnackbar({...snackbar, ...{showSnackbar: false}})}>
                    <AppSnackbarContent
                        onClose={() => setSnackbar({
                            ...snackbar, ...{
                                showSnackbar: false
                            }
                        })}
                        message={snackbar.snackbarText}
                        icon={snackbar.snackbarIcon}
                        level={snackbar.snackbarLevel}
                    />
                </Snackbar>
                <ConfirmationDialog onSubmit={() => dispatch(deleteBlogPost(deletePost || -2))}
                                    title={messages.blog.posts.manage.delete.title} open={deletePost !== null}
                                    activeFetch={posts[deletePost || -1]?.deleteFetchStatus === FetchStatus.ACTIVE}
                                    onCancel={() => setDeletePost(null)}>
                    <Typography variant={'body1'} className={classes.delete}>
                        {messages.formatString(messages.blog.posts.manage.delete.description,
                            getLocalizationValueForLanguageAndKey(posts[deletePost || -2]?.Localizations || [], LocalizationKey.BlogPostName, Language.GERMAN))}
                    </Typography>
                </ConfirmationDialog>
            </div>
        );
    }
    return null;
}

export default Overview;