import React, {useCallback, useEffect, useState} from 'react';
import {Grid, makeStyles, Typography} from '@material-ui/core';
import {Theme} from '@material-ui/core/styles';
import {useLocalization} from '../../../../localization/localization';
import {useDispatch, useSelector} from 'react-redux';
import {AppState, BlogCategory, Language, LocalizationKey} from '../../../../types/types';
import FetchStatus from '../../../../api/FetchStatus';
import {Skeleton} from '@material-ui/lab';
import {
    addNewBlogCategory,
    deleteBlogCategory,
    loadCategories,
    removeBlogCategoryFromState
} from '../../../../reducer/blog/types';
import {Add} from '@material-ui/icons';
import * as classnames from 'classnames';
import EditCategoryDialog from './EditCategoryDialog';
import Snackbar from '@material-ui/core/Snackbar';
import {
    AppSnackbarContent,
    denormalize,
    getLocalizationValueForLanguageAndKey
} from '../../../../helper/helperFunctions';
import Category from './Category';
import ConfirmationDialog from '../../../skeleton/dialogs/ConfirmationDialog';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        ...theme.body,
        paddingTop: 100
    },
    grid: {},
    loader: {
        height: 453
    },
    skeleton: {
        height: '100%',
        borderRadius: 6
    },
    breadcrumb: {
        fontSize: 20,
        color: theme.palette.primary.light,
        lineHeight: '32px',
        marginTop: theme.spacing(4.5)
    },
    title: {
        color: '#193150',
        fontWeight: 600,
        fontSize: 45,
        lineHeight: '68px',
        marginBottom: theme.spacing(2) + 20
    },
    gridItem: {
        borderRadius: 6,
        background: 'white',
        transition: theme.defaultTransition,
        boxShadow: '0 0 1px 0 rgba(16,36,52,0.25), 0 4px 12px 0 rgba(0,0,0,0.06)',
        height: 453,
        padding: '0px !important',
        '&:hover': {
            boxShadow: '0 16px 32px 0 rgba(61,66,69,0.12), 0 0 1px 0 rgba(16,36,52,0.25), 0 4px 15px 0 rgba(46,49,52,0.12);'
        }
    },
    categoryItem: {
        height: 493,
    },
    addItem: {
        cursor: 'pointer',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        padding: `${theme.spacing(8)}px !important`,
        boxSizing: 'border-box'
    },
    addIcon: {
        color: '#193150',
        marginBottom: theme.spacing(0),
        width: 126,
        height: 126
    },
    addText: {
        color: '#193150',
        fontWeight: 600,
        fontSize: 36,
        lineHeight: '44px',
        textAlign: 'center'
    },
    delete: {
        fontSize: 20,
        color: theme.palette.primary.light,
        lineHeight: '32px'
    }
}));

const Overview: React.FC = () => {
    const classes = useStyles();
    const messages = useLocalization();
    const dispatch = useDispatch();
    const [showPopover, setShowPopover] = useState(false);
    const [activeCategory, setActiveCategory] = useState<number | null>(null);
    const [deleteCategory, setDeleteCategory] = useState<number | null>(null);
    const {fetchStatus, categories} = useSelector((state: AppState) => ({
        fetchStatus: state.blog.fetchStatus.categories,
        categories: state.blog.categories
    }));

    const [snackbar, setSnackbar] = useState<{
        showSnackbar: boolean
        snackbarText: string
        snackbarIcon?: any,
        snackbarLevel: FetchStatus
    }>({
        showSnackbar: false,
        snackbarText: '',
        snackbarLevel: FetchStatus.SUCCESS
    });

    const openSnackBar = useCallback((text: string, icon: any, level: FetchStatus) => setSnackbar({
        showSnackbar: true,
        snackbarText: text,
        snackbarIcon: icon,
        snackbarLevel: level
    }), []);

    useEffect(() => {
        dispatch(loadCategories());
    }, [dispatch]);

    useEffect(() => {
        const category = categories[deleteCategory || -2];
        if (category?.deleteFetchStatus === FetchStatus.SUCCESS) {
            openSnackBar(messages.blog.categories.manage.delete.success, null, FetchStatus.SUCCESS);
            setDeleteCategory(null);
            dispatch(removeBlogCategoryFromState(category.id));
        } else if (category?.deleteFetchStatus === FetchStatus.ERROR) {
            openSnackBar(messages.blog.categories.manage.delete.error, null, FetchStatus.ERROR);
        }
    }, [messages.blog.categories.manage.delete, categories, deleteCategory, dispatch, openSnackBar]);

    let content;

    if (fetchStatus === FetchStatus.ACTIVE) {
        content = <Grid container={true} spacing={5} className={classes.grid}>
            {[...new Array(9)].map((_, index) => <Grid xs={12} sm={6} md={4} key={`blog-category-loader-item-${index}`}
                                                       item={true} className={classes.loader}>
                <Skeleton variant={'rect'} animation={'wave'} className={classes.skeleton}/>
            </Grid>)}
        </Grid>
    } else {
        content = (
            <Grid container={true} spacing={5} className={classes.grid}>
                <Grid item={true} xs={12} sm={6} md={4}>
                    <div className={classnames(classes.gridItem, classes.addItem)}
                         onClick={() => {
                             dispatch(addNewBlogCategory());
                             setActiveCategory(-1);
                             setShowPopover(true);
                         }}>
                        <Add className={classes.addIcon}/>
                        <Typography variant={'body1'} className={classes.addText}>
                            {messages.blog.categories.create.link}
                        </Typography>
                    </div>
                </Grid>
                {denormalize(categories).filter(it => it.id > -1).map(it => (
                    <Grid item={true} xs={12} sm={6} md={4} className={classes.categoryItem}
                          key={`category-item-${it.id}`}>
                        <Category category={it} onEdit={() => {
                            setActiveCategory(it.id);
                            setShowPopover(true);
                        }} onDelete={() => setDeleteCategory(it.id)}/>
                    </Grid>
                ))}
            </Grid>
        )
    }

    return (
        <div className={classes.root}>
            <Typography variant={'h3'} className={classes.breadcrumb}>
                {messages.blog.categories.breadcrumb}
            </Typography>
            <Typography variant={'h1'} className={classes.title}>
                {messages.blog.categories.title}
            </Typography>
            {content}
            {activeCategory !== null && categories[activeCategory] !== undefined &&
            <EditCategoryDialog open={showPopover} close={() => {
                setShowPopover(false);
            }} openSnackbar={openSnackBar} activeFetch={false} category={categories[activeCategory] as BlogCategory}/>}

            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right'
                }}
                open={snackbar.showSnackbar}
                autoHideDuration={2000}
                onClose={() => setSnackbar({...snackbar, ...{showSnackbar: false}})}>
                <AppSnackbarContent
                    onClose={() => setSnackbar({
                        ...snackbar, ...{
                            showSnackbar: false
                        }
                    })}
                    message={snackbar.snackbarText}
                    icon={snackbar.snackbarIcon}
                    level={snackbar.snackbarLevel}
                />
            </Snackbar>
            <ConfirmationDialog onSubmit={() => dispatch(deleteBlogCategory(deleteCategory || -2))}
                                title={messages.blog.categories.manage.delete.title} open={deleteCategory !== null}
                                activeFetch={categories[deleteCategory || -1]?.deleteFetchStatus === FetchStatus.ACTIVE}
                                onCancel={() => setDeleteCategory(null)}>
                <Typography variant={'body1'} className={classes.delete}>
                    {messages.formatString(messages.blog.categories.manage.delete.description,
                        getLocalizationValueForLanguageAndKey(categories[deleteCategory || -2]?.Localizations || [], LocalizationKey.BlogCategoryName, Language.GERMAN))}
                </Typography>
            </ConfirmationDialog>
        </div>
    );
}

export default Overview;