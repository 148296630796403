import {call, take, put, select, spawn} from 'redux-saga/effects';
import {post, Route} from '../api/Api';
import type {AppState} from '../types/types';
import {
    fetchLoginUser, fetchLoginUserError,
    fetchLoginUserSuccess,
    FetchLoginUserSuccessPayload,
    LOGIN_USER,
    LoginUserPayload
} from '../reducer/user/types';
import {PayloadAction} from '@reduxjs/toolkit';
import {
    CHANGE_PASSWORD,
    ChangePasswordPayload,
    fetchChangePassword, fetchChangePasswordError,
    fetchChangePasswordSuccess
} from '../reducer/application/types';

export function* loginUserSaga(): Generator<any, any, any> {
    while (true) {
        try {
            const action: PayloadAction<LoginUserPayload> = yield take(LOGIN_USER);
            if (action.payload.email?.trim() && action.payload.password?.trim()) {
                yield put(fetchLoginUser());
                const user: FetchLoginUserSuccessPayload = yield call(post, Route.LOGIN_VERIFY, {
                    email: action.payload.email.trim(),
                    password: action.payload.password.trim()
                });
                if (user) {
                    yield put(fetchLoginUserSuccess(user));
                } else {
                    yield put(fetchLoginUserError());
                }
            }
        } catch (e) {
            yield put(fetchLoginUserError());
        }
    }
}

export function* changePasswordSaga(): Generator<any, any, any> {
    while (true) {
        try {
            const action: PayloadAction<ChangePasswordPayload> = yield take(CHANGE_PASSWORD);
            const jwt = yield select((state: AppState) => state.user.jwt);
            if (jwt &&  action.payload.password?.trim() && action.payload.password.trim() === action.payload.confirmation?.trim()) {
                yield put(fetchChangePassword());
                const user = yield call(post, Route.CHANGE_PASSWORD, {
                    password: action.payload.password.trim(),
                    confirmation: action.payload.confirmation.trim()
                }, jwt);
                if (user) {
                    yield put(fetchChangePasswordSuccess());
                } else {
                    yield put(fetchChangePasswordError());
                }
            }
        } catch (e) {
            yield put(fetchChangePasswordError());
        }
    }
}

/**
 * The root saga of the web app. It calls all other sagas which will be used in the web app.
 */
function* userSaga() {
    yield spawn(loginUserSaga);
    yield spawn(changePasswordSaga);
}

export default userSaga;