import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Theme} from '@material-ui/core/styles/createTheme';
import {useHistory, useParams} from 'react-router';
import {useDispatch, useSelector} from 'react-redux';
import {AppState, BlogPostAction, BlogPostState, BlogTag, Language, LocalizationKey} from '../../../../../types/types';
import {
    addNewCategoryBlogPost,
    deleteBlogPost,
    loadBlogCategoryPosts,
    removeBlogPostFromState,
    removeBlogPostImage,
    resetSaveBlogPostFetchStatus,
    saveBlogPost,
    setBlogCategoryPostLocalization,
    setBlogPostState,
    setBlogPostTags,
    uploadBlogPostImageErrorCreator,
    uploadBlogPostImageFinishCreator,
    uploadBlogPostImageProgressCreator,
    uploadImage
} from '../../../../../reducer/blog/types';
import {Button, IconButton, Typography} from '@material-ui/core';
import {
    AppSnackbarContent,
    getLocalizationValueForLanguageAndKey,
    mapImageToSrcSet
} from '../../../../../helper/helperFunctions';
import {useLocalization} from '../../../../../localization/localization';
import FetchStatus from '../../../../../api/FetchStatus';
import Upload from '../../../../forms/Upload';
import Ifta from '../../../../forms/Ifta';
import * as classnames from 'classnames';
import Image from '../../../../helper/Image';
import {Clear, Visibility, VisibilityOff} from '@material-ui/icons';
import IftaAutocomplete from '../../../../forms/IftaAutocomplete';
import {fade} from '@material-ui/core/styles/colorManipulator';
import Editor from './Editor';
import {Link} from 'react-router-dom';
import Snackbar from '@material-ui/core/Snackbar';
import ConfirmationDialog from '../../../../skeleton/dialogs/ConfirmationDialog';
import {ToggleButton, ToggleButtonGroup} from '@material-ui/lab';
import Back from '../../../../icons/Back';
import germanIcon from '../../../../../images/icons/german.svg';
import englishIcon from '../../../../../images/icons/english.svg';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        ...theme.body,
        paddingTop: 100
    },
    breadcrumb: {
        fontSize: 20,
        color: theme.palette.primary.light,
        lineHeight: '32px',
        marginTop: theme.spacing(4.5),
        display: 'flex',
        '&>span': {
            fontWeight: 600
        },
        '& $postName': {
            color: theme.palette.primary.main,
            fontWeight: 400
        }
    },
    postName: {},
    header: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    content: {
        maxWidth: 750,
        width: '90%',
        margin: '0 auto'
    },
    title: {
        fontSize: 45,
        color: '#193150',
        fontWeight: 600,
        lineHeight: '68px',
        marginBottom: theme.spacing(1.5)
    },
    postTitle: {
        fontWeight: 'bold',
        fontSize: 65,
        lineHeight: '68px',
        color: '#E4E4E4',
        textAlign: 'center',
        marginBottom: theme.spacing(4),
        transition: 'all 0.3s cubic-bezier(0.55, 0, 0.6, 1)'
    },
    activePostTitle: {
        color: theme.palette.primary.main
    },
    upload: {
        border: `2px dashed ${theme.palette.primary.light}`,
        height: 390,
        width: '100%',
        borderRadius: 8,
        marginBottom: theme.spacing(2)
    },
    inputSurround: {
        marginBottom: theme.spacing(2)
    },
    imageSurround: {
        position: 'relative',
        width: '100%'
    },
    previewImage: {
        position: 'absolute',
        maxWidth: `calc(100% - ${theme.spacing(4)}px)`,
        maxHeight: `calc(100% - ${theme.spacing(4)}px)`,
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)'
    },
    deleteImageButton: {
        width: 24,
        height: 24,
        backgroundColor: '#FF5252',
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1)
    },
    deleteImageIcon: {
        color: 'white',
        width: 17,
        height: 17
    },
    actionSurround: {
        display: 'flex',
        justifyContent: 'center',
        marginBottom: theme.spacing(10)
    },
    actionButton: {
        fontSize: 18,
        fontWeight: 600,
        height: 52,
        minWidth: 163,
        textTransform: 'none',
        background: 'white',
        boxShadow: 'none',
        color: '#193150',
        border: '1px solid #193150',
        marginRight: theme.spacing(2),
        '&:hover': {
            boxShadow: 'none',
            background: fade('#193150', 0.25)
        }
    },
    saveButton: {
        color: theme.palette.primary.main,
        borderColor: theme.palette.primary.main,
        '&:hover': {
            background: fade(theme.palette.primary.main, 0.25)
        }
    },
    saveAndPublishButton: {
        background: theme.palette.primary.main,
        borderColor: theme.palette.primary.main,
        color: 'white',
        width: 300,
        marginRight: 0,
        '&:hover': {
            background: fade(theme.palette.primary.main, 0.25)
        }
    },
    deleteButton: {
        color: '#FF5252',
        borderColor: '#FF5252',
        '&:hover': {
            background: fade('#FF5252', 0.25)
        }
    },
    link: theme.link,
    delete: {
        fontSize: 20,
        color: theme.palette.primary.light,
        lineHeight: '32px'
    },
    quickActionsSurround: {
        display: 'flex',
        flex: 0,
        height: 42
    },
    visibilityGroup: {
        boxShadow: '0 0 1px 0 rgba(0,0,0,0.2), 0 4px 8px 0 rgba(0,0,0,0.12)'
    },
    visibilityButton: {
        background: 'white !important',
        color: '#193150 !important',
        border: 'none',
        padding: theme.spacing(1)
    },
    selectedVisibilityButton: {
        background: '#193150 !important',
        color: 'white !important'
    },
    languageButton: {
        boxShadow: '0 0 1px 0 rgba(0,0,0,0.2), 0 4px 8px 0 rgba(0,0,0,0.12)',
        background: 'white',
        textTransform: 'none',
        fontSize: 15,
        fontWeight: 600,
        color: '#193150',
        marginLeft: theme.spacing(2),
        height: 42,
        width: 130
    },
    back: {
        width: 24,
        height: 24,
        color: theme.palette.primary.light,
        marginRight: theme.spacing(1),
        marginTop: 3
    },
    languageIcon: {
        width: 24,
        height: 24,
        marginRight: theme.spacing(1)
    }
}));

const EditBlogPost: React.FC = () => {
    const classes = useStyles();
    const {categoryID, blogPostID} = useParams<{ categoryID?: string, blogPostID?: string }>();
    const [language, setLanguage] = useState(Language.GERMAN);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const dispatch = useDispatch();
    const messages = useLocalization();
    const {push} = useHistory();

    const [snackbar, setSnackbar] = useState<{
        showSnackbar: boolean
        snackbarText: string
        snackbarIcon?: any,
        snackbarLevel: FetchStatus
    }>({
        showSnackbar: false,
        snackbarText: '',
        snackbarLevel: FetchStatus.SUCCESS
    });

    const openSnackBar = useCallback((text: string, icon: any, level: FetchStatus) => setSnackbar({
        showSnackbar: true,
        snackbarText: text,
        snackbarIcon: icon,
        snackbarLevel: level
    }), []);

    const {usedCategoryID, usedBlogPostID} = useMemo(() => ({
        usedCategoryID: parseInt(categoryID || '-2', 10),
        usedBlogPostID: parseInt(blogPostID || '-2', 10)
    }), [categoryID, blogPostID]);

    const {category, post, jwt, categoryFetchStatus} = useSelector((state: AppState) => ({
        category: state.blog.categories[usedCategoryID],
        categoryFetchStatus: state.blog.fetchStatus.posts[usedCategoryID],
        post: state.blog.posts[usedBlogPostID],
        jwt: state.user.jwt
    }));


    const imageSet = useMemo(() => {
        if (post) {
            const path = post.BlogImages[0]?.path || '';
            const type = post.BlogImages[0]?.type || '';
            return mapImageToSrcSet(path, type);
        }
        return undefined;
    }, [post]);

    if (!category && categoryFetchStatus === undefined) {
        // Fetch blog posts for category
        dispatch(loadBlogCategoryPosts(usedCategoryID));
    }

    if (usedBlogPostID && !post && usedCategoryID) {
        if (usedBlogPostID === -1) {
            // Create new blog post in the state
            dispatch(addNewCategoryBlogPost(usedCategoryID));
        }
    }

    useEffect(() => {
        if (post?.saveFetchStatus === FetchStatus.SUCCESS) {
            dispatch(resetSaveBlogPostFetchStatus(post.id));
            if (post.id === -1) {
                dispatch(removeBlogPostFromState(-1));
            }
            openSnackBar(messages.blog.posts.manage.save.success, null, FetchStatus.SUCCESS);
            push(`/blog/categories/${usedCategoryID}/${BlogPostAction.SAVED}`);
        } else if (post?.saveFetchStatus === FetchStatus.ERROR) {
            openSnackBar(messages.blog.posts.manage.save.error, null, FetchStatus.ERROR);
        }
    }, [post, push, usedCategoryID, openSnackBar, messages.blog.posts.manage.save, dispatch]);

    useEffect(() => {
        if (post?.deleteFetchStatus === FetchStatus.SUCCESS) {
            push(`/blog/categories/${usedCategoryID}/${BlogPostAction.DELETED}`);
            dispatch(removeBlogPostFromState(post.id));
        } else if (post?.deleteFetchStatus === FetchStatus.ERROR) {
            openSnackBar(messages.blog.posts.manage.delete.error, null, FetchStatus.ERROR);
        }
    }, [post, push, usedCategoryID, openSnackBar, messages.blog.posts.manage.delete.error, dispatch]);

    if (category && post && jwt) {

        const categoryName = getLocalizationValueForLanguageAndKey(category.Localizations, LocalizationKey.BlogCategoryName, language);
        const postName = getLocalizationValueForLanguageAndKey(post.Localizations, LocalizationKey.BlogPostName, language);
        const postDescription = getLocalizationValueForLanguageAndKey(post.Localizations, LocalizationKey.BlogPostDescription, language);
        const postContent = getLocalizationValueForLanguageAndKey(post.Localizations, LocalizationKey.BlogPostContent, language);

        const enabledSave = postName && postDescription && postContent && Boolean(imageSet);

        return (
            <div className={classes.root}>
                <Typography variant={'h3'} className={classes.breadcrumb}>
                    <Link to={`/blog/categories/${categoryID}`} className={classes.link}>
                        <Back className={classes.back}/>
                    </Link>
                    <span>{messages.blog.posts.breadcrumbBlog}</span>&nbsp;/&nbsp;
                    <span>
                        <Link to={`/blog/categories`}
                              className={classes.link}>{messages.blog.posts.breadcrumbCategories}
                        </Link>
                    </span>&nbsp;/&nbsp;
                    <span>
                        <Link to={`/blog/categories/${usedCategoryID}/${BlogPostAction.FETCH}`}
                              className={classes.link}>
                            {categoryName}
                        </Link>
                    </span>&nbsp;/&nbsp;
                    <span className={classes.postName}>{postName || messages.blog.posts.breadcrumbNewPost}</span>
                </Typography>
                <div className={classes.header}>
                    <Typography variant={'h1'} className={classes.title}>
                        {usedBlogPostID === -1 ? messages.blog.posts.create.title : messages.blog.posts.manage.title}
                    </Typography>
                    <div className={classes.quickActionsSurround}>
                        <ToggleButtonGroup value={post.state} exclusive={true} onChange={(e, value) => {
                            if (value) {
                                dispatch(setBlogPostState({id: post.id, state: value}));
                            }
                        }} aria-label={'post visibility'} className={classes.visibilityGroup}>
                            <ToggleButton value={BlogPostState.INACTIVE} aria-label={BlogPostState.INACTIVE} classes={{
                                root: classes.visibilityButton,
                                selected: classes.selectedVisibilityButton
                            }}>
                                <VisibilityOff/>
                            </ToggleButton>
                            <ToggleButton value={BlogPostState.ACTIVE} aria-label={BlogPostState.ACTIVE} classes={{
                                root: classes.visibilityButton,
                                selected: classes.selectedVisibilityButton
                            }}>
                                <Visibility/>
                            </ToggleButton>
                        </ToggleButtonGroup>
                        <Button variant={'contained'} className={classes.languageButton} onClick={() => {
                            const nextLanguage = language === Language.GERMAN ? Language.ENGLISH : Language.GERMAN;
                            if (nextLanguage === Language.ENGLISH) {
                                // Check if english values are available, if not, populate it with the german values
                                const name = getLocalizationValueForLanguageAndKey(post.Localizations, LocalizationKey.BlogPostName, nextLanguage);
                                if (!name) {
                                    dispatch(setBlogCategoryPostLocalization({
                                        language: Language.ENGLISH,
                                        value: getLocalizationValueForLanguageAndKey(post.Localizations, LocalizationKey.BlogPostName, Language.GERMAN),
                                        key: LocalizationKey.BlogPostName,
                                        id: post.id
                                    }));
                                    dispatch(setBlogCategoryPostLocalization({
                                        language: Language.ENGLISH,
                                        value: getLocalizationValueForLanguageAndKey(post.Localizations, LocalizationKey.BlogPostDescription, Language.GERMAN),
                                        key: LocalizationKey.BlogPostDescription,
                                        id: post.id
                                    }));
                                    dispatch(setBlogCategoryPostLocalization({
                                        language: Language.ENGLISH,
                                        value: getLocalizationValueForLanguageAndKey(post.Localizations, LocalizationKey.BlogPostContent, Language.GERMAN),
                                        key: LocalizationKey.BlogPostContent,
                                        id: post.id
                                    }));
                                }
                            }
                            setLanguage(nextLanguage);
                        }}>
                            <img src={language === Language.GERMAN ? germanIcon : englishIcon} alt={language.toString()} title={language.toString()} className={classes.languageIcon} />
                            {language === Language.GERMAN ? messages.language.german : messages.language.english}
                        </Button>
                    </div>
                </div>
                <div className={classes.content}>
                    <Typography variant={'h2'} className={classnames(classes.postTitle, {
                        [classes.activePostTitle]: Boolean(postName)
                    })}>
                        {postName || messages.blog.posts.create.defaultName}
                    </Typography>
                    <div className={classes.imageSurround}>
                        <Upload progress={post.imageUploadProgress || 0} className={classes.upload}
                                buttonText={messages.blog.posts.upload.button} callback={(file) => {
                            dispatch(uploadImage({
                                onProgress: uploadBlogPostImageProgressCreator(post.id),
                                onFinish: uploadBlogPostImageFinishCreator(post.id),
                                onError: uploadBlogPostImageErrorCreator(post.id),
                                file
                            }));
                        }} description={messages.blog.posts.upload.description}
                                subLine={messages.blog.posts.upload.subLine}
                                fetchStatus={post?.imageUploadFetchStatus || FetchStatus.DEFAULT}/>
                        {imageSet !== undefined && <>
                            <Image srcSet={imageSet} alt={''} className={classes.previewImage}/>
                            <IconButton className={classes.deleteImageButton}
                                        onClick={() => dispatch(removeBlogPostImage(post.id))}>
                                <Clear className={classes.deleteImageIcon}/>
                            </IconButton>
                        </>}
                    </div>

                    <div className={classes.inputSurround}>
                        <Ifta value={postName || ''} label={messages.blog.posts.manage.name.label}
                              onChange={(e) => dispatch(setBlogCategoryPostLocalization({
                                  id: usedBlogPostID,
                                  key: LocalizationKey.BlogPostName,
                                  language,
                                  value: e.currentTarget.value
                              }))} placeholder={messages.blog.posts.manage.name.placeholder} name={'blog-post-name'}/>
                    </div>
                    <div className={classes.inputSurround}>
                        <Ifta value={postDescription || ''} label={messages.blog.posts.manage.description.label}
                              multiline={true}
                              rows={4}
                              onChange={(e) => dispatch(setBlogCategoryPostLocalization({
                                  id: usedBlogPostID,
                                  key: LocalizationKey.BlogPostDescription,
                                  language,
                                  value: e.currentTarget.value
                              }))} placeholder={messages.blog.posts.manage.description.placeholder}
                              name={'blog-post-description'}/>
                    </div>
                    <div className={classes.inputSurround}>
                        <IftaAutocomplete<BlogTag> options={post.BlogTags || []}
                                                   label={messages.blog.posts.manage.tags.label}
                                                   placeholder={messages.blog.posts.manage.tags.placeholder}
                                                   id={'blog-tags-select'}
                                                   value={post.BlogTags || []} onChange={(e, value) => {
                            dispatch(setBlogPostTags({
                                id: post.id,
                                tags: value.map<BlogTag>(it => typeof it === 'string' ? {
                                    id: -1,
                                    name: it
                                } : it)
                            }));
                        }} getOptionLabel={(it) => it.name}
                                                   creatable={true}/>
                    </div>
                </div>
                <Editor category={category} jwt={jwt} language={language} post={post}/>
                <div className={classes.actionSurround}>
                    {post.id > -1 &&
                    <Button variant={'contained'} className={classnames(classes.actionButton, classes.deleteButton)}
                            onClick={() => setShowDeleteDialog(true)}>
                        {messages.blog.posts.manage.delete.button}
                    </Button>}
                    <Link to={`/blog/categories/${usedCategoryID}/${BlogPostAction.FETCH}`} className={classes.link}>
                        <Button variant={'contained'} className={classnames(classes.actionButton)}>
                            {messages.blog.posts.manage.cancel}
                        </Button>
                    </Link>
                    <Button disabled={!enabledSave} variant={'contained'}
                            className={classnames(classes.actionButton, classes.saveButton)}
                            onClick={() => dispatch(saveBlogPost(post.id))}>
                        {messages.blog.posts.manage.save.button}
                    </Button>
                    <Button disabled={!enabledSave} variant={'contained'}
                            className={classnames(classes.actionButton, classes.saveAndPublishButton)} onClick={() => {
                        // Assure state is set to active
                        dispatch(setBlogPostState({
                            id: post.id,
                            state: BlogPostState.ACTIVE
                        }));
                        dispatch(saveBlogPost(post.id))
                    }}>
                        {messages.blog.posts.manage.saveAndPublish}
                    </Button>
                </div>
                <Snackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right'
                    }}
                    open={snackbar.showSnackbar}
                    autoHideDuration={2000}
                    onClose={() => setSnackbar({...snackbar, ...{showSnackbar: false}})}>
                    <AppSnackbarContent
                        onClose={() => setSnackbar({
                            ...snackbar, ...{
                                showSnackbar: false
                            }
                        })}
                        message={snackbar.snackbarText}
                        icon={snackbar.snackbarIcon}
                        level={snackbar.snackbarLevel}
                    />
                </Snackbar>
                <ConfirmationDialog onSubmit={() => dispatch(deleteBlogPost(post.id))}
                                    title={messages.blog.posts.manage.delete.title} open={showDeleteDialog}
                                    activeFetch={post.deleteFetchStatus === FetchStatus.ACTIVE}
                                    onCancel={() => setShowDeleteDialog(false)}>
                    <Typography variant={'body1'} className={classes.delete}>
                        {messages.formatString(messages.blog.posts.manage.delete.description, postName)}
                    </Typography>
                </ConfirmationDialog>
            </div>
        );
    }
    return null;
}

export default EditBlogPost;