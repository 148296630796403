import React from 'react';
import {makeStyles, Typography} from '@material-ui/core';
import classNames from 'classnames';
import {Theme} from '@material-ui/core/styles/createTheme';
import {useLocalization} from '../../localization/localization';


const useStyles = makeStyles((theme: Theme) => ({
    root: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '90%',
        margin: '0 auto 85px'
    },
    headline: {
        marginTop: 150
    },
    subline: {
        marginTop: 32
    },
    comingSoon: {
        opacity: 0.3
    },

    link: theme.link,
    caption: {
    },
}));

const Changelog: React.FC = () => {
    const classes = useStyles();
    const messages = useLocalization();

    return (
        <div className={classes.root}>
            <div>
                <Typography variant={'h2'} className={classes.headline}>
                    {messages['legal.headline']}
                </Typography>


                <Typography variant={'h3'} className={classes.subline}>
                    {messages['legal.changelog.1.0']}
                </Typography>
                <Typography variant={'body1'} dangerouslySetInnerHTML={{__html: messages['changelog.caption.1.0']}}/>


                <Typography variant={'h3'} className={classes.subline}>
                    {messages['legal.changelog.1.0.1']}
                </Typography>
                <Typography variant={'body1'} dangerouslySetInnerHTML={{__html: messages['changelog.caption.1.0.1']}}/>

                <Typography variant={'h3'} className={classes.subline}>
                    {messages['legal.changelog.1.0.2']}
                </Typography>
                <Typography variant={'body1'} dangerouslySetInnerHTML={{__html: messages['changelog.caption.1.0.2']}}/>


                <Typography variant={'h3'} className={classes.subline}>
                    {messages['legal.changelog.1.0.3']}
                </Typography>
                <Typography variant={'body1'} dangerouslySetInnerHTML={{__html: messages['changelog.caption.1.0.3']}}/>


                <Typography variant={'h3'} className={classes.subline}>
                    {messages['legal.changelog.1.0.4']}
                </Typography>
                <Typography variant={'body1'} dangerouslySetInnerHTML={{__html: messages['changelog.caption.1.0.4']}}/>


                <Typography variant={'h3'} className={classes.subline}>
                    {messages['legal.changelog.1.0.5']}
                </Typography>
                <Typography variant={'body1'} dangerouslySetInnerHTML={{__html: messages['changelog.caption.1.0.5']}}/>


                <Typography variant={'h3'} className={classes.subline}>
                    {messages['legal.changelog.1.0.6']}
                </Typography>
                <Typography variant={'body1'} dangerouslySetInnerHTML={{__html: messages['changelog.caption.1.0.6']}}/>


                <Typography variant={'h3'} className={classes.subline}>
                    {messages['legal.changelog.1.0.7']}
                </Typography>
                <Typography variant={'body1'} dangerouslySetInnerHTML={{__html: messages['changelog.caption.1.0.7']}}/>


                <Typography variant={'h3'} className={classes.subline}>
                    {messages['legal.changelog.1.0.8']}
                </Typography>
                <Typography variant={'body1'} dangerouslySetInnerHTML={{__html: messages['changelog.caption.1.0.8']}}/>


                <Typography variant={'h3'} className={classes.subline}>
                    {messages['legal.changelog.1.0.9']}
                </Typography>
                <Typography variant={'body1'} dangerouslySetInnerHTML={{__html: messages['changelog.caption.1.0.9']}}/>


                <Typography variant={'h3'} className={classes.subline}>
                    {messages['legal.changelog.1.1.0.1']}
                </Typography>
                <Typography variant={'body1'} dangerouslySetInnerHTML={{__html: messages['changelog.caption.1.1.0.1']}}/>


                <Typography variant={'h3'} className={classes.subline}>
                    {messages['legal.changelog.1.1.0.2']}
                </Typography>
                <Typography variant={'body1'} dangerouslySetInnerHTML={{__html: messages['changelog.caption.1.1.0.2']}}/>


                <Typography variant={'h3'} className={classes.subline}>
                    {messages['legal.changelog.1.1.1']}
                </Typography>
                <Typography variant={'body1'} dangerouslySetInnerHTML={{__html: messages['changelog.caption.1.1.1']}}/>


                <Typography variant={'h3'} className={classes.subline}>
                    {messages['legal.changelog.1.1.2']}
                </Typography>
                <Typography variant={'body1'} dangerouslySetInnerHTML={{__html: messages['changelog.caption.1.1.2']}}/>


                <Typography variant={'h3'} className={classNames(classes.subline, classes.comingSoon)}>
                    {messages['legal.changelog.1.1.3']}
                </Typography>
                <Typography variant={'body1'} className={classes.comingSoon} dangerouslySetInnerHTML={{__html: messages['changelog.caption.1.1.3']}}/>

            </div>
        </div>
    );
}

export default Changelog;
