import {createMuiTheme, ThemeOptions, Theme} from '@material-ui/core/styles';
import {FontFaceFontDisplayProperty} from 'csstype';

import NexaLightTTF from '../../fonts/nexa-light-webfont.ttf';
import NexaLightWoff2 from '../../fonts/nexa-light-webfont.woff2';
import NexaLightWoff from '../../fonts/nexa-light-webfont.woff';
import NexaRegularTTF from '../../fonts/nexa-regular-webfont.ttf';
import NexaRegularWoff2 from '../../fonts/nexa-regular-webfont.woff2';
import NexaRegularWoff from '../../fonts/nexa-regular-webfont.woff';
import NexaBoldTTF from '../../fonts/nexa-bold-webfont.ttf';
import NexaBoldWoff2 from '../../fonts/nexa-bold-webfont.woff2';
import NexaBoldWoff from '../../fonts/nexa-bold-webfont.woff';


const FontNexaLight = {
    fontFamily: 'Nexa',
    fontStyle: 'normal',
    fontWeight: 300,
    fontDisplay: ('swap' as FontFaceFontDisplayProperty),
    src: `
    local('Nexa Light'),
    url(${NexaLightWoff2}) format('woff2'),
    url(${NexaLightWoff}) format('woff'),
    url(${NexaLightTTF}) format('ttf')
  `,
    unicodeRange:
        'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};

const FontNexaRegular = {
    fontFamily: 'Nexa',
    fontStyle: 'normal',
    fontWeight: 400,
    fontDisplay: ('swap' as FontFaceFontDisplayProperty),
    src: `
    local('Nexa Regular'),
    url(${NexaRegularWoff2}) format('woff2'),
    url(${NexaRegularWoff}) format('woff'),
    url(${NexaRegularTTF}) format('ttf')
  `,
    unicodeRange:
        'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};

const FontNexaBold = {
    fontFamily: 'Nexa',
    fontStyle: 'normal',
    fontWeight: 600,
    fontDisplay: ('swap' as FontFaceFontDisplayProperty),
    src: `
    local('Nexa Bold'),
    url(${NexaBoldWoff2}) format('woff2'),
    url(${NexaBoldWoff}) format('woff'),
    url(${NexaBoldTTF}) format('ttf')
  `,
    unicodeRange:
        'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};


function createTheme(options: ThemeOptions) {
    const theme = createMuiTheme(options);

    return createMuiTheme({
        ...theme, ...{
            spacing: 8,
            palette: {
                primary: {
                    light: '#5c646f',
                    main: '#2F89FC',
                    dark: '#102434',
                    contrastText: '#ffffff',
                },
                secondary: {
                    light: '#4A4A4A',
                    main: '#23262F',
                    dark: '#23262F',
                    contrastText: '#ffffff',
                }
            },
            typography: {
                fontFamily: 'Nexa, sans-serif',
                caption: {
                    fontSize: 16,
                    textTransform: 'uppercase',
                    fontWeight: 500
                },

                h1: {
                    fontSize: 46,
                    lineHeight: '52px',
                    [theme.breakpoints.down('sm')]: {
                        fontSize: 35,
                        lineHeight: '45px',
                    }
                },
                h2: {
                    fontSize: 34,
                    lineHeight: '39px',
                    letterSpacing: '1px',
                    [theme.breakpoints.down('sm')]: {
                        letterSpacing: '-0.5px',
                        fontSize: 32,
                        lineHeight: '42px',
                    }
                },
                h3: {
                    fontSize: 18,
                    fontWeight: 500,
                    lineHeight: 1,
                    letterSpacing: '-0.5px',
                    [theme.breakpoints.down('sm')]: {
                        fontSize: 20,
                        lineHeight: '28px',
                    }
                },
                body1: {
                    fontSize: 14,
                    lineHeight: '25px',
                    color: '#808080',
                    [theme.breakpoints.down('lg')]: {
                        fontSize: 14,
                        lineHeight: '22px'
                    },
                    [theme.breakpoints.down('sm')]: {
                        fontSize: 16,
                        lineHeight: '26px'
                    }
                },
                body2: {
                    fontSize: 20,
                    lineHeight: '35px'
                }
            },
            link: {
                color: 'inherit',
                textDecoration: 'none',
                cursor: 'pointer'
            },
            button: {
                background: '#B51F29',
                color: 'white',
                borderRadius: 0,
                fontSize: 15,
                padding: 15
            },
            fullSizeImage: {
                minHeight: '100%',
                minWidth: '100%',
                height: 'auto',
                width: 'auto',
                position: 'absolute',
                top: '-100%',
                bottom: '-100%',
                left: '-100%',
                right: '-100%',
                margin: 'auto',
                zIndex: 1
            },
            body: {
                width: '90%',
                margin: '0 auto'
            },
            input: {
                fontSize: 16,
                width: '100%',
                outline: 'none',
                padding: '18px 21px',
                boxSizing: 'border-box',
                borderRadius: 5,
                border: '1px solid #DDDFE7',
                transition: 'all 0.3s cubic-bezier(0.55, 0, 0.6, 1)',
                color: '#778198',
                fontFamily: 'Nexa, sans-serif',
                '&:focus': {
                    color: '#2F89FC',
                    boxShadow: '0 10px 20px 0 rgba(0,0,0,0.1)',
                    border: '1px solid white',
                }
            },
            gradientButton: {
                background: 'linear-gradient(195.53deg, #2F89FC 0%, #30E3CA 100%)',
                boxShadow: '0 4px 10px 0 rgba(0,0,0,0.2)',
                borderRadius: 5,
                color: theme.palette.primary.contrastText,
                fontSize: 14,
                textTransform: 'none',
                paddingTop: 14,
                paddingBottom: 14,
            },
            defaultTransition: 'all 0.3s cubic-bezier(.35,0,.25,1)',
            overrides: {
                MuiCssBaseline: {
                    '@global': {
                        '@font-face': [FontNexaBold, FontNexaLight, FontNexaRegular],
                        body: {
                            backgroundColor: 'white'
                        }
                    }
                }
            }
        }
    });
}
const theme: Theme = createTheme({});

export default theme;
