import React from 'react';
import classNames from 'classnames';
import {withStyles} from '@material-ui/core/styles/index';
import FetchStatus from '../api/FetchStatus';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import {Dictionary} from '@reduxjs/toolkit';
import {Language, Localization, LocalizationKey, Tag} from '../types/types';

export function notUndefined<T>(x: T | undefined): x is T {
    return x !== undefined;
}

export function normalize<T>(array: T[], normalizeKey: string = 'id', injected: Object = {}): Dictionary<T> {
    const normalized: Dictionary<T> = {};
    array.forEach((element: T) => normalized[(element as any)[normalizeKey]] = {...element, ...injected});
    return normalized;
}

export function denormalize<T>(dictionary: Dictionary<T>): Array<T> {
    return dictionary ? Object.keys(dictionary).map(key => dictionary[key]).filter(notUndefined) : [];
}

export const getTagForName = (tags: Tag[], name: string): Tag | undefined => tags.filter(tag => tag.name === name)[0];

export interface AppSnackbarContentProps {
    classes: any
    level: FetchStatus
    icon?: any
    message: string
    onClose?: () => void
}

export const AppSnackbarContent = withStyles((theme) => ({
    root: {
        color: theme.palette.primary.main,
        background: 'white',
        fontSize: 14,
        flexWrap: 'nowrap'
    },
    error: {
        color: 'red'
    },
    message: {
        lineHeight: '16px'
    }
}))((props: AppSnackbarContentProps) => {
    return (
        <SnackbarContent
            className={classNames(props.classes.root, props.level === FetchStatus.ERROR ? props.classes.error : '')}
            aria-describedby={'client-snackbar'}
            classes={{
                message: props.classes.message
            }}
            message={
                <span id={'client-snackbar'}>
                        {props.icon}
                    {props.message}
                    </span>
            }
            action={[
                <IconButton key={'close'} aria-label={'close'} color={'inherit'} onClick={props.onClose}>
                    <CloseIcon className={props.classes.icon}/>
                </IconButton>
            ]}
        />
    );
});


export const mapImageToSrcSet = (path: string, type: string): Dictionary<Dictionary<string>> | undefined => {
    if (Boolean(path) && Boolean(type)) {
        const basePath = `${process.env.REACT_APP_BASE_URL}/blog/`;
        return {
            webP: {
                lg2x: `${basePath}${path}-lg@2x.webp`,
                lg: `${basePath}${path}-lg@1x.webp`,
                md2x: `${basePath}${path}-md@2x.webp`,
                md: `${basePath}${path}-md@1x.webp`,
                sm2x: `${basePath}${path}-sm@2x.webp`,
                sm: `${basePath}${path}-sm@1x.webp`,
                xs2x: `${basePath}${path}-xs@2x.webp`,
                xs: `${basePath}${path}-xs.webp`
            },
            fallback: {
                lg2x: `${basePath}${path}-lg@2x.${type}`,
                lg: `${basePath}${path}-lg@1x.${type}`,
                md2x: `${basePath}${path}-md@2x.${type}`,
                md: `${basePath}${path}-md@1x.${type}`,
                sm2x: `${basePath}${path}-sm@2x.${type}`,
                sm: `${basePath}${path}-sm@1x.${type}`,
                xs2x: `${basePath}${path}-xs@2x.${type}`,
                xs: `${basePath}${path}-xs@1x.${type}`
            }
        }
    }
    return undefined;
};

export const getLocalizationValueForLanguageAndKey = (localizations: Localization[], key: LocalizationKey, language: Language) => localizations.filter(it => it.lang === language && it.key === key)[0]?.value || ''