import React, {FC, useCallback, useMemo, useRef, useState} from 'react';
import {makeStyles, Theme} from '@material-ui/core/styles';
import {Button, CircularProgress, Typography} from '@material-ui/core';
import {useLocalization} from '../../localization/localization';
import {CloudUpload} from '@material-ui/icons';
import * as classnames from 'classnames';
import FetchStatus from '../../api/FetchStatus';

interface UploadProps {
    progress: number
    callback: (file: File) => void
    fetchStatus: FetchStatus
    showIcon?: boolean
    className?: string
    buttonText?: string
    description?: string
    subLine?: string
    onDragOver?: () => void
    onDragOut?: () => void
}

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        width: '100%',
        height: '100%',
        transition: theme.defaultTransition,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center'
    },
    active: {
        background: '#AFCDFA',
        width: '100%',
        height: '100%',
        '& $icon': {
            color: 'white'
        }
    },
    icon: {
        transition: theme.defaultTransition,
        color: '#AFCDFA',
        width: 30,
        height: 30
    },
    progress: {
        width: `30px !important`,
        height: `30px !important`,
        color: '#AFCDFA'
    },
    fileInput: {
        display: 'none'
    },
    content: {
        color: '#B5D3FF',
        fontSize: 15,
        lineHeight: '18px',
        transition: theme.defaultTransition,
        textAlign: 'center'
    },
    contentActive: {
        color: 'white'
    },
    button: {
        textTransform: 'none',
        fontSize: 16
    },
    buttonIcon: {
        marginRight: theme.spacing(2)
    },
    description: {
        marginTop: theme.spacing(1),
        fontSize: 16,
        color: '#193150',
        lineHeight: '20px'
    },
    subLine: {
        fontSize: 16,
        lineHeight: '20px',
        color: theme.palette.primary.light
    }
}));

const Upload: FC<UploadProps> = ({
                                     progress, callback, showIcon = true, className,
                                     buttonText, subLine,
                                     description, fetchStatus, onDragOver,
                                     onDragOut
                                 }) => {

    const classes = useStyles();
    const [dragOver, setDragOver] = useState(false);
    const messages = useLocalization();
    const fileInputRef = useRef<HTMLInputElement>(null);


    const setDragOverCallback = useCallback((event: React.DragEvent<HTMLDivElement>) => {
        event.stopPropagation();
        event.preventDefault();
        setDragOver(true);
        onDragOver?.();
    }, [setDragOver, onDragOver]);

    const setDragOutCallback = useCallback((event: React.DragEvent<HTMLDivElement>) => {
        event.stopPropagation();
        event.preventDefault();
        setDragOver(false);
        onDragOut?.();
    }, [setDragOver, onDragOut]);

    const content = useMemo(() => {
        if (description !== undefined) {
            if (dragOver) {
                return (<Typography variant={'body1'}
                                   className={classes.description}>
                    {messages.upload.drop}
                </Typography>);
            } else if (progress > 0 && fetchStatus === FetchStatus.ACTIVE) {
                if (progress === 1) {
                    return (<Typography variant={'body1'}
                                        className={classes.progress}>
                        {messages.upload.progress}
                    </Typography>);
                }
                return (<Typography variant={'body1'}
                                    className={classes.progress}>
                    {messages.upload.active}
                </Typography>);
            }
            return <>
                {Boolean(description) && <Typography variant={'body1'} className={classes.description}>
                    {description}
                </Typography>}
                {Boolean(subLine) && <Typography variant={'body1'} className={classes.subLine}>
                    {subLine}
                </Typography>}
            </>;
        }
        return '';
    }, [dragOver, description, progress, messages, fetchStatus, classes, subLine]);

    const icon = useMemo(() => {
        if (showIcon) {
            if (progress !== 1 || fetchStatus !== FetchStatus.ACTIVE) {
                return <Button className={classes.button} variant={'contained'} color={'primary'}>
                    <CloudUpload className={classes.buttonIcon}/>
                    {buttonText}
                </Button>;
            }
            return <CircularProgress className={classes.progress}/>;
        }
        return null;
    }, [showIcon, progress, classes, fetchStatus, buttonText]);


    return (
        <div className={classnames(classes.root, {
            [classes.active]: dragOver
        }, className)} onDragEnter={setDragOverCallback}
             onDragOver={setDragOverCallback}
             onDrop={(event: React.DragEvent<HTMLDivElement>) => {
                 event.preventDefault();
                 event.stopPropagation();
                 setDragOutCallback(event);
                 if (event.nativeEvent?.dataTransfer?.files[0]) {
                     callback(event.nativeEvent.dataTransfer.files[0]);
                 }
             }} onDragLeave={setDragOutCallback}
             onDragEnd={setDragOutCallback}
             onClick={() => fileInputRef.current?.click()}>
            <input type={'file'} className={classes.fileInput} ref={fileInputRef}
                   onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                       if (event.target?.files?.[0]) {
                           callback(event.target.files[0]);
                       }
                   }}/>
            {icon}
            <Typography variant={'body1'} className={classnames(classes.content, {
                [classes.contentActive]: dragOver
            })}>
                {content}
            </Typography>
        </div>
    );
}

export default Upload;