import { Dictionary } from '@reduxjs/toolkit';
import FetchStatus from "../api/FetchStatus";

export enum Language {
    GERMAN = 'de_DE',
    ENGLISH = 'en_GB'
}

export enum Gender {
    Male,
    Female,
    None
}

export interface User {
    id: number;
    gender: number;
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    language: string;
}

export enum HotelLogType {
    SHOW_PHONE_NUMBER = 'SHOW_PHONE_NUMBER',
    SHOW_WEBSITE = 'SHOW_WEBSITE'
}

export interface HotelRequest {
}

export interface HotelLog {
    type: HotelLogType;
}

export interface Tag {
    id: number;
    name: string;
}

export interface Country {
    id: number;
    name: string;
}

export interface HotelHighlight {
    tagID: number;
}

export enum PricingModel {
    Fix = 'Fix',
    Provision = 'Provision'
}

export interface FrameContractNumber {
    id: number;
    number: string;
}

export enum HotelState {
    Registration = 'Registration',
    Pending = 'Pending',
    Activated = 'Activated',
    Edited = 'Edited',
    Deactivated = 'Deactivated'
}

export interface Hotel {
    id: number;
    name: string;
    street: string;
    streetNumber: string;
    zip: string;
    city: string;
    inactive: boolean;
    starred: boolean;
    createdAt: string;
    User: User;
    fetchStatus: number;
    registrationCompleted: boolean;
    HotelRequests?: HotelRequest[];
    HotelLogs?: HotelLog[];
    HotelHighlights?: HotelHighlight[];
    objectNumber?: string;
    pricingModel?: PricingModel;
    publishingDate?: string;
    FrameContractNumber?: FrameContractNumber;
    state: HotelState;
    Country: Country;
}

export interface Admin {
    id: number;
    gender: number;
    firstName: string;
    lastName: string;
    email: string;
}

export interface Localization {
    id: number;
    key: string;
    lang: Language;
    value: string;
}

export interface BlogImage {
    id: number;
    name: string;
    path: string;
    order: number;
    type: string;
}

export interface BlogCategory {
    id: number;
    views: number;
    createdAt?: string;
    updatedAt?: string;
    creator?: Admin;
    responsibleAdmin?: Admin;
    Localizations: Localization[];
    BlogImages: BlogImage[];
    imageUploadProgress?: number;
    imageUploadFetchStatus?: FetchStatus;
    saveFetchStatus?: FetchStatus;
    deleteFetchStatus?: FetchStatus;
    loadPostsFetchStatus?: FetchStatus;
}

export interface BlogTag {
    id: number;
    name: string;
}

export enum BlogPostState {
    INACTIVE = 'INACTIVE',
    ACTIVE = 'ACTIVE'
}

export interface BlogPost {
    id: number;
    views: number;
    Localizations: Localization[];
    BlogImages: BlogImage[];
    BlogTags: BlogTag[];
    state: BlogPostState;
    blogCategoryID: number;
    highlighted: boolean;
    imageUploadFetchStatus?: FetchStatus;
    imageUploadProgress?: number;
    saveFetchStatus?: FetchStatus;
    deleteFetchStatus?: FetchStatus;
    highlightFetchStatus?: FetchStatus;
    activateFetchStatus?: FetchStatus;
}

export interface ApplicationState {
    language: {
        selected: Language;
        available: Language[];
    };
    countries: Dictionary<Country>;
    messages: ApplicationMessage[];
    loadHotelsFetchStatus: FetchStatus;
    hotels: Dictionary<Hotel>;
    selectedHotels: number[];
    createOrUpdateHotelFetchStatus: FetchStatus;
    createdToken: null | string;
    sendNewTokenMailFetchStatus: FetchStatus;
    tags: Dictionary<Tag>;
    showChangePasswordDialog: boolean;
    changePasswordFetchStatus: FetchStatus;
    adminFetchStatus: FetchStatus;
    hotelPage: number;
    rowsPerHotelPage: number;
    admins: Dictionary<Admin>;
    loadCountriesFetchStatus: FetchStatus;
}


export interface UserState {
    jwt?: string;
    data?: User;
    loginFetchStatus: FetchStatus;
}

export interface BlogState {
    fetchStatus: {
        categories: FetchStatus;
        posts: Dictionary<FetchStatus>;
    };
    categories: Dictionary<BlogCategory>;
    posts: Dictionary<BlogPost>;
    postsByCategory: Dictionary<number[]>;
}

export enum BlogPostAction {
    SAVED = 'saved',
    DELETED = 'deleted',
    FETCH = 'fetch'
}

export enum LocalizationKey {
    BlogCategoryName = 'blogCategoryName',
    BlogCategoryDescription = 'blogCategoryDescription',
    BlogPostName = 'blogPostName',
    BlogPostDescription = 'blogPostDescription',
    BlogPostContent = 'blogPostContent'
}

export enum SnackbarVariant {
    ERROR = 'error',
    SUCCESS = 'success',
    INFO = 'info',
    WARNING = 'warning'
}

export interface ApplicationMessage {
    text: string;
    variant: SnackbarVariant;
    key: string;
    dismissed?: boolean;
    duration?: number;
}

export interface AppState {
    application: ApplicationState;
    user: UserState;
    blog: BlogState;
}
