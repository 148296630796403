import React, {useEffect, useRef, useState} from 'react';
import {BlogCategory, BlogPost, Language, LocalizationKey} from '../../../../../types/types';
import {makeStyles} from '@material-ui/core/styles';
import {Theme} from '@material-ui/core/styles/createTheme';
import EditorJS from '@editorjs/editorjs';
import Header from '@editorjs/header';
import List from '@editorjs/list';
import Marker from '@editorjs/marker';
import EditorImage from '@editorjs/image';
import {BASE_URL} from '../../../../../sagas/BlogSaga';
import {Route} from '../../../../../api/Api';
import Quote from '@editorjs/quote';
import Embed from '@editorjs/embed';
import Underline from '@editorjs/underline';
import TextColor from '@itech-indrustries/editor-js-text-color';
import {getLocalizationValueForLanguageAndKey} from '../../../../../helper/helperFunctions';
import {setBlogCategoryPostLocalization} from '../../../../../reducer/blog/types';
import {useDispatch} from 'react-redux';
import {useLocalization} from '../../../../../localization/localization';

interface EditorProps {
    category: BlogCategory;
    post: BlogPost;
    language: Language;
    jwt: string;
}

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        background: '#eef5fa',
        padding: theme.spacing(8, 6),
        borderRadius: 80,
        maxWidth: 848,
        margin: theme.spacing(4, 'auto', 4)
    },
    editor: {
        background: 'white',
        borderRadius: 8,
        boxShadow: '0 24px 24px -18px rgb(69 104 129 / 33%), 0 9px 45px 0 rgb(114 119 160 / 12%)',
        padding: theme.spacing(3, 0),
    },
}))

const Editor: React.FC<EditorProps> = ({language, post, jwt}) => {
    const [editorRef, setEditorRef] = useState<HTMLDivElement | null>(null);
    const editor = useRef<EditorJS | null>(null);
    const usedLanguage = useRef<Language>(language);
    const messages = useLocalization();
    const dispatch = useDispatch();
    const classes = useStyles();


    useEffect(() => {
        if (editorRef && !editor.current) {
            editor.current = new EditorJS({
                holder: editorRef,
                tools: {
                    header: Header,
                    list: List,
                    marker: Marker,
                    image: {
                        class: EditorImage,
                        config: {
                            endpoints: {
                                byFile: `${BASE_URL}${Route.Blog.UPLOAD_POST_IMAGE}`
                            },
                            additionalRequestHeaders: {
                                Authorization: `Bearer ${jwt}`
                            },
                            field: 'file'
                        }
                    },
                    quote: Quote,
                    embed: Embed,
                    underline: Underline,
                    ColorPicker: TextColor
                },
                i18n: {
                    messages: {
                        toolNames: {
                            'Text': messages.blog.posts.editor.text,
                            'Heading': messages.blog.posts.editor.heading,
                            'List': messages.blog.posts.editor.list,
                            'Warning': messages.blog.posts.editor.warning,
                            'Checklist': messages.blog.posts.editor.checklist,
                            'Quote': messages.blog.posts.editor.quote,
                            'Code': messages.blog.posts.editor.code,
                            'Delimiter': messages.blog.posts.editor.delimiter,
                            'Raw HTML': messages.blog.posts.editor.rawHTML,
                            'Table': messages.blog.posts.editor.table,
                            'Link': messages.blog.posts.editor.link,
                            'Marker': messages.blog.posts.editor.marker,
                            'Bold': messages.blog.posts.editor.bold,
                            'Italic': messages.blog.posts.editor.italic,
                            'InlineCode': messages.blog.posts.editor.inlineCode,
                            'Underline': messages.blog.posts.editor.underline,
                            'ColorPicker': messages.blog.posts.editor.colorPicker,
                            'Image': messages.blog.posts.editor.image
                        }
                    }
                },
                data: JSON.parse(getLocalizationValueForLanguageAndKey(post.Localizations, LocalizationKey.BlogPostContent, language) || '{}'),
                onChange: () => {
                    editor.current?.save().then((value) => {
                        dispatch(setBlogCategoryPostLocalization({
                            id: post.id,
                            value: JSON.stringify(value),
                            language: usedLanguage.current,
                            key: LocalizationKey.BlogPostContent
                        }))
                    });
                }
            });
        }
    }, [editorRef, messages, jwt, post.Localizations, language, dispatch, post.id]);

    useEffect(() => {
        // On language switch update the contents
        if (editor.current && language !== usedLanguage.current) {
            editor.current.clear();
            editor.current.render(JSON.parse(getLocalizationValueForLanguageAndKey(post.Localizations, LocalizationKey.BlogPostContent, language) || '{}'));
            usedLanguage.current = language;
        }
    }, [language, post.Localizations]);

    useEffect(() => {

        return () => {
            // Destroying editor instance
            editor.current?.destroy?.();
        }
    }, []);

    return (
        <div className={classes.root}>
            <div className={classes.editor} ref={it => {
                if (!editorRef && it) {
                    setEditorRef(it);
                }
            }}/>
        </div>
    );
}

export default Editor;