import React from 'react';

const Back: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {

    return (
        <svg viewBox={'0 0 24 24'} xmlns={'http://www.w3.org/2000/svg'} xmlnsXlink={'http://www.w3.org/1999/xlink'} {...props}>
            <defs>
                <polygon id={'back-icon-path-1'} points={'16 7 3.83 7 9.42 1.41 8 0 0 8 8 16 9.41 14.59 3.83 9 16 9'} />
            </defs>
            <g stroke={'none'} strokeWidth={'1'} fill={'none'} fillRule={'evenodd'}>
                <g transform={'translate(-97, -142)'}>
                    <g transform={'translate(101, 146)'}>
                        <mask id={'mask-2'} fill={'white'}>
                            <use xlinkHref={'#back-icon-path-1'} />
                        </mask>
                        <use fill={'currentColor'} xlinkHref={'#back-icon-path-1'} />
                    </g>
                </g>
            </g>
        </svg>
    );
}

export default Back;