import React from 'react';
import {Hotel, PricingModel} from '../../../types/types';
import {makeStyles, Typography} from '@material-ui/core';
import {Theme} from '@material-ui/core/styles/createTheme';
import {useLocalization} from '../../../localization/localization';
import moment from 'moment-timezone';

interface HotelInformationProps {
    hotel: Hotel;
}

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        background: 'white',
        boxShadow: '0 0 0 1px rgba(0,0,0,0.05), 0 16px 56px 0 rgba(0,0,0,0.16), 0 16px 40px 0 rgba(25,49,80,0.32)',
        padding: theme.spacing(2, 3),
        borderRadius: 8
    },
    headline: {
        color: '#193150',
        fontWeight: 600,
        fontSize: 14,
        lineHeight: '18px',
        marginBottom: 2,
        letterSpacing: -0.25
    },
    content: {
        color: '#646464',
        fontSize: 13,
        lineHeight: '18px',
        marginBottom: 0,
        letterSpacing: -0.23,
        '&>a': {
            ...theme.link,
            color: theme.palette.primary.main
        }
    },
    information: {
        margin: theme.spacing(2, 0)
    }
}));

const HotelInformation: React.FC<HotelInformationProps> = ({hotel}) => {
    const classes = useStyles();
    const messages = useLocalization();

    return (
        <div className={classes.root}>
            <Typography variant={'h4'} className={classes.headline}>
                {messages.hotels.information.title}
            </Typography>
            <Typography variant={'body1'} className={classes.content}>
                {hotel.name}
            </Typography>
            <Typography variant={'body1'} className={classes.content}>
                {hotel.street} {hotel.streetNumber}
            </Typography>
            <Typography variant={'body1'} className={classes.content}>
                {hotel.zip} {hotel.city}
            </Typography>
            <Typography variant={'body1'} className={classes.content}>
                {hotel.Country?.name}
            </Typography>
            <div className={classes.information}>
                <Typography variant={'body1'} className={classes.content}>
                    {messages.hotels.information.registeredAt}: {moment(hotel.createdAt).format('DD.MM.YYYY')}
                </Typography>
                <Typography variant={'body1'} className={classes.content}>
                    {messages.hotels.information.activeSince}: {hotel.publishingDate ? moment(hotel.publishingDate).format('DD.MM.YYYY') : '-'}
                </Typography>
                <Typography variant={'body1'} className={classes.content}>
                    {messages.hotels.information.pricingModel}: {hotel.pricingModel ? (hotel.pricingModel === PricingModel.Fix ? messages['hotels.pricingModel.fix'] : `${messages['hotels.pricingModel.provision']} (${hotel.FrameContractNumber?.number})`) : '-'}
                </Typography>
                <Typography variant={'body1'} className={classes.content}>
                    {messages.hotels.information.objectNumber}: {hotel.objectNumber || '-'}
                </Typography>
            </div>
            <Typography variant={'h4'} className={classes.headline}>
                {messages.hotels.information.contact}
            </Typography>
            <Typography variant={'body1'} className={classes.content}>
                {hotel.User.gender === 0 ? messages['hotels.add.gender.0'] : messages['hotels.add.gender.1']} {hotel.User.firstName} {hotel.User.lastName}
            </Typography>
            <Typography variant={'body1'} className={classes.content}>
                {messages.hotels.information.phone}: <a href={`tel:${hotel.User.phone}`}>{hotel.User.phone}</a>
            </Typography>
            <Typography variant={'body1'} className={classes.content}>
                {messages.hotels.information.email}: <a href={`mailto:${hotel.User.email}`}>{hotel.User.email}</a>
            </Typography>
        </div>
    )
}

export default HotelInformation;