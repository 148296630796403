import {BlogCategory, BlogPost, BlogPostState, BlogState, Localization} from '../../types/types';
import FetchStatus from '../../api/FetchStatus';
import {createReducer} from '@reduxjs/toolkit';
import {
    addNewBlogCategory,
    addNewCategoryBlogPost,
    fetchDeleteBlogCategory,
    fetchDeleteBlogCategoryError,
    fetchDeleteBlogCategorySuccess,
    fetchDeleteBlogPost,
    fetchDeleteBlogPostError,
    fetchDeleteBlogPostSuccess,
    fetchHighlightBlogPost,
    fetchHighlightBlogPostError,
    fetchHighlightBlogPostSuccess,
    fetchLoadBlogCategoryPosts,
    fetchLoadBlogCategoryPostsError,
    fetchLoadBlogCategoryPostsSuccess,
    fetchLoadCategories,
    fetchLoadCategoriesError,
    fetchLoadCategoriesSuccess,
    fetchSaveBlogCategory,
    fetchSaveBlogCategoryError,
    fetchSaveBlogCategorySuccess,
    fetchSaveBlogPost, fetchSaveBlogPostError, fetchSaveBlogPostSuccess,
    fetchUpdateBlogPostState,
    fetchUpdateBlogPostStateError,
    fetchUpdateBlogPostStateSuccess,
    removeBlogCategoryFromState,
    removeBlogCategoryImage,
    removeBlogPostFromState,
    removeBlogPostImage, resetHighlightBlogPostFetchStatus,
    resetLoadBlogCategoryPostsFetchStatus,
    resetLoadCategoriesFetchStatus,
    resetSaveBlogCategoryFetchStatus, resetSaveBlogPostFetchStatus, resetUpdateBlogPostStateFetchStatus,
    setBlogCategoryLocalization,
    setBlogCategoryPostLocalization,
    setBlogCategoryResponsible, setBlogPostState,
    setBlogPostTags,
    setCreatedCategorySuccessFetchStatus, setCreatedPostSuccessFetchStatus,
    uploadBlogCategoryImageError,
    uploadBlogCategoryImageFinish,
    uploadBlogCategoryImageProgress,
    uploadBlogPostImageError,
    uploadBlogPostImageFinish,
    uploadBlogPostImageProgress
} from './types';
import {normalize} from '../../helper/helperFunctions';


const initialState: BlogState = {
    fetchStatus: {
        categories: FetchStatus.DEFAULT,
        posts: {}
    },
    categories: {},
    posts: {},
    postsByCategory: {}
};

export default createReducer<BlogState>(initialState, builder =>
    builder.addCase(fetchLoadCategories, state => ({
        ...state,
        ...{
            fetchStatus: {
                ...state.fetchStatus,
                ...{
                    categories: FetchStatus.ACTIVE
                }
            }
        }
    })).addCase(fetchLoadCategoriesSuccess, (state, action) => ({
        ...state,
        ...{
            fetchStatus: {
                ...state.fetchStatus,
                ...{
                    categories: FetchStatus.SUCCESS
                }
            },
            categories: {
                ...state.categories,
                ...normalize(action.payload)
            }
        }
    })).addCase(fetchLoadCategoriesError, state => ({
        ...state,
        ...{
            fetchStatus: {
                ...state.fetchStatus,
                ...{
                    categories: FetchStatus.ERROR
                }
            }
        }
    })).addCase(resetLoadCategoriesFetchStatus, state => ({
        ...state,
        ...{
            fetchStatus: {
                ...state.fetchStatus,
                ...{
                    categories: FetchStatus.DEFAULT
                }
            }
        }
    })).addCase(addNewBlogCategory, state => ({
        ...state,
        ...{
            categories: {
                ...state.categories,
                ...{
                    '-1': {
                        BlogImages: [],
                        id: -1,
                        Localizations: [],
                        views: 0,
                    }
                }
            }
        }
    })).addCase(uploadBlogCategoryImageProgress, (state, action) => ({
        ...state,
        ...{
            categories: {
                ...state.categories,
                ...{
                    [action.payload.categoryID]: {
                        ...(state.categories[action.payload.categoryID] as BlogCategory),
                        imageUploadProgress: action.payload.progress,
                        imageUploadFetchStatus: FetchStatus.ACTIVE
                    }
                }
            }
        }
    })).addCase(uploadBlogCategoryImageFinish, (state, action) => ({
        ...state,
        ...{
            categories: {
                ...state.categories,
                ...{
                    [action.payload.categoryID]: {
                        ...(state.categories[action.payload.categoryID] as BlogCategory),
                        imageUploadProgress: 1,
                        imageUploadFetchStatus: FetchStatus.SUCCESS,
                        BlogImages: [action.payload.image]
                    }
                }
            }
        }
    })).addCase(uploadBlogCategoryImageError, (state, action) => ({
        ...state,
        ...{
            categories: {
                ...state.categories,
                ...{
                    [action.payload.categoryID]: {
                        ...(state.categories[action.payload.categoryID] as BlogCategory),
                        imageUploadFetchStatus: FetchStatus.ERROR
                    }
                }
            }
        }
    })).addCase(removeBlogCategoryImage, (state, action) => ({
        ...state,
        ...{
            categories: {
                ...state.categories,
                ...{
                    [action.payload]: {
                        ...(state.categories[action.payload] as BlogCategory),
                        BlogImages: []
                    }
                }
            }
        }
    })).addCase(setBlogCategoryLocalization, (state, action) => {
        let category = state.categories[action.payload.id];
        if (category) {
            const {key, language, value} = action.payload;
            category = {...category};
            let localization: Localization = category.Localizations.filter(it => it.key === action.payload.key && it.lang === action.payload.language)[0];
            if (!localization) {
                localization = {
                    id: -1,
                    key,
                    lang: language,
                    value
                }
            } else {
                localization = {...localization, value};
            }
            category.Localizations = [...category.Localizations.filter(it => it.key !== action.payload.key || it.lang !== action.payload.language), ...[localization]];
            return {
                ...state,
                ...{
                    categories: {
                        ...state.categories,
                        ...{
                            [action.payload.id]: category
                        }
                    }
                }
            }
        }
        return state;
    }).addCase(setBlogCategoryResponsible, (state, action) => ({
        ...state,
        ...{
            categories: {
                ...state.categories,
                ...{
                    [action.payload.categoryID]: {
                        ...(state.categories[action.payload.categoryID] as BlogCategory),
                        responsibleAdmin: action.payload.admin
                    }
                }
            }
        }
    })).addCase(fetchSaveBlogCategory, (state, action) => ({
        ...state,
        ...{
            categories: {
                ...state.categories,
                ...{
                    [action.payload]: {
                        ...(state.categories[action.payload] as BlogCategory),
                        saveFetchStatus: FetchStatus.ACTIVE
                    }
                }
            }
        }
    })).addCase(fetchSaveBlogCategoryError, (state, action) => ({
        ...state,
        ...{
            categories: {
                ...state.categories,
                ...{
                    [action.payload]: {
                        ...(state.categories[action.payload] as BlogCategory),
                        saveFetchStatus: FetchStatus.ERROR
                    }
                }
            }
        }
    })).addCase(fetchSaveBlogCategorySuccess, (state, action) => ({
        ...state,
        ...{
            categories: {
                ...state.categories,
                ...{
                    [action.payload.id]: {...action.payload, saveFetchStatus: FetchStatus.SUCCESS}
                }
            }
        }
    })).addCase(setCreatedCategorySuccessFetchStatus, (state) => ({
        ...state,
        ...{
            categories: {
                ...state.categories,
                ...{
                    '-1': {
                        ...(state.categories[-1] as BlogCategory),
                        saveFetchStatus: FetchStatus.SUCCESS
                    }
                }
            }
        }
    })).addCase(resetSaveBlogCategoryFetchStatus, (state, action) => ({
        ...state,
        ...{
            categories: {
                ...state.categories,
                ...{
                    [action.payload]: {
                        ...(state.categories[action.payload] as BlogCategory),
                        saveFetchStatus: FetchStatus.DEFAULT
                    }
                }
            }
        }
    })).addCase(fetchDeleteBlogCategory, (state, action) => ({
        ...state,
        ...{
            categories: {
                ...state.categories,
                ...{
                    [action.payload]: {
                        ...(state.categories[action.payload] as BlogCategory),
                        deleteFetchStatus: FetchStatus.ACTIVE
                    }
                }
            }
        }
    })).addCase(fetchDeleteBlogCategorySuccess, (state, action) => ({
        ...state,
        ...{
            categories: {
                ...state.categories,
                ...{
                    [action.payload]: {
                        ...(state.categories[action.payload] as BlogCategory),
                        deleteFetchStatus: FetchStatus.SUCCESS
                    }
                }
            }
        }
    })).addCase(fetchDeleteBlogCategoryError, (state, action) => ({
        ...state,
        ...{
            categories: {
                ...state.categories,
                ...{
                    [action.payload]: {
                        ...(state.categories[action.payload] as BlogCategory),
                        deleteFetchStatus: FetchStatus.ERROR
                    }
                }
            }
        }
    })).addCase(removeBlogCategoryFromState, (state, action) => {
        const categories = {...state.categories};
        delete categories[action.payload];
        return {
            ...state,
            ...{
                categories
            }
        }
    }).addCase(fetchLoadBlogCategoryPosts, (state, action) => ({
        ...state,
        ...{
            fetchStatus: {
                ...state.fetchStatus,
                posts: {
                    ...state.fetchStatus.posts,
                    [action.payload]: FetchStatus.ACTIVE
                }
            }
        }
    })).addCase(fetchLoadBlogCategoryPostsError, (state, action) => ({
        ...state,
        ...{
            fetchStatus: {
                ...state.fetchStatus,
                posts: {
                    ...state.fetchStatus.posts,
                    [action.payload]: FetchStatus.ERROR
                }
            }
        }
    })).addCase(resetLoadBlogCategoryPostsFetchStatus, (state, action) => ({
        ...state,
        ...{
            fetchStatus: {
                ...state.fetchStatus,
                posts: {
                    ...state.fetchStatus.posts,
                    [action.payload]: FetchStatus.DEFAULT
                }
            }
        }
    })).addCase(fetchLoadBlogCategoryPostsSuccess, (state, action) => ({
            ...state,
            ...{
                fetchStatus: {
                    ...state.fetchStatus,
                    posts: {
                        ...state.fetchStatus.posts,
                        [action.payload.categoryID]: FetchStatus.SUCCESS
                    }
                },
                posts: {
                    ...state.posts,
                    ...normalize(action.payload.posts)
                },
                postsByCategory: {
                    ...state.postsByCategory,
                    [action.payload.categoryID]: action.payload.posts.map(it => it.id)
                }
            }
        }
    )).addCase(addNewCategoryBlogPost, (state, action) => ({
        ...state,
        ...{
            posts: {
                ...state.posts,
                '-1': {
                    id: -1,
                    views: 0,
                    Localizations: [],
                    BlogImages: [],
                    BlogTags: [],
                    state: BlogPostState.INACTIVE,
                    highlighted: false,
                    blogCategoryID: action.payload
                }
            }
        }
    })).addCase(setBlogCategoryPostLocalization, (state, action) => {
        let post = state.posts[action.payload.id];
        if (post) {
            const {key, language, value} = action.payload;
            post = {...post};
            let localization: Localization = post.Localizations.filter(it => it.key === action.payload.key && it.lang === action.payload.language)[0];
            if (!localization) {
                localization = {
                    id: -1,
                    key,
                    lang: language,
                    value
                }
            } else {
                localization = {...localization, value};
            }
            post.Localizations = [...post.Localizations.filter(it => it.key !== action.payload.key || it.lang !== action.payload.language), ...[localization]];
            return {
                ...state,
                ...{
                    posts: {
                        ...state.posts,
                        [action.payload.id]: post
                    }
                }
            }
        }
        return state;
    }).addCase(uploadBlogPostImageProgress, (state, action) => ({
        ...state,
        ...{
            posts: {
                ...state.posts,
                ...{
                    [action.payload.postID]: {
                        ...(state.posts[action.payload.postID] as BlogPost),
                        imageUploadProgress: action.payload.progress,
                        imageUploadFetchStatus: FetchStatus.ACTIVE
                    }
                }
            }
        }
    })).addCase(uploadBlogPostImageFinish, (state, action) => ({
        ...state,
        ...{
            posts: {
                ...state.posts,
                ...{
                    [action.payload.postID]: {
                        ...(state.posts[action.payload.postID] as BlogPost),
                        imageUploadProgress: 1,
                        imageUploadFetchStatus: FetchStatus.SUCCESS,
                        BlogImages: [action.payload.image]
                    }
                }
            }
        }
    })).addCase(uploadBlogPostImageError, (state, action) => ({
        ...state,
        ...{
            posts: {
                ...state.posts,
                ...{
                    [action.payload.postID]: {
                        ...(state.posts[action.payload.postID] as BlogPost),
                        imageUploadFetchStatus: FetchStatus.ERROR
                    }
                }
            }
        }
    })).addCase(removeBlogPostImage, (state, action) => ({
        ...state,
        ...{
            posts: {
                ...state.posts,
                ...{
                    [action.payload]: {
                        ...(state.posts[action.payload] as BlogPost),
                        BlogImages: []
                    }
                }
            }
        }
    })).addCase(setBlogPostTags, (state, action) => ({
        ...state,
        ...{
            posts: {
                ...state.posts,
                ...{
                    [action.payload.id]: {
                        ...(state.posts[action.payload.id] as BlogPost),
                        BlogTags: action.payload.tags
                    }
                }
            }
        }
    })).addCase(fetchDeleteBlogPost, (state, action) => ({
        ...state,
        ...{
            posts: {
                ...state.posts,
                ...{
                    [action.payload]: {
                        ...(state.posts[action.payload] as BlogPost),
                        deleteFetchStatus: FetchStatus.ACTIVE
                    }
                }
            }
        }
    })).addCase(fetchDeleteBlogPostSuccess, (state, action) => ({
        ...state,
        ...{
            posts: {
                ...state.posts,
                ...{
                    [action.payload]: {
                        ...(state.posts[action.payload] as BlogPost),
                        deleteFetchStatus: FetchStatus.SUCCESS
                    }
                }
            },
            postsByCategory: {
                ...state.postsByCategory,
                [(state.posts[action.payload] as BlogPost).blogCategoryID]: (state.postsByCategory[(state.posts[action.payload] as BlogPost).blogCategoryID] || []).filter(it => it !== action.payload)
            }
        }
    })).addCase(fetchDeleteBlogPostError, (state, action) => ({
        ...state,
        ...{
            posts: {
                ...state.posts,
                ...{
                    [action.payload]: {
                        ...(state.posts[action.payload] as BlogPost),
                        deleteFetchStatus: FetchStatus.ERROR
                    }
                }
            }
        }
    })).addCase(removeBlogPostFromState, (state, action) => {
        const posts = {...state.posts};
        delete posts[action.payload];
        return {
            ...state,
            ...{
                posts
            }
        }
    }).addCase(fetchHighlightBlogPost, (state, action) => ({
        ...state,
        ...{
            posts: {
                ...state.posts,
                ...{
                    [action.payload.id]: {
                        ...(state.posts[action.payload.id] as BlogPost),
                        highlightFetchStatus: FetchStatus.ACTIVE
                    }
                }
            }
        }
    })).addCase(fetchHighlightBlogPostSuccess, (state, action) => ({
        ...state,
        ...{
            posts: {
                ...state.posts,
                ...{
                    [action.payload.id]: {
                        ...(state.posts[action.payload.id] as BlogPost),
                        highlightFetchStatus: FetchStatus.SUCCESS,
                        highlighted: action.payload.highlighted
                    }
                }
            }
        }
    })).addCase(fetchHighlightBlogPostError, (state, action) => ({
        ...state,
        ...{
            posts: {
                ...state.posts,
                ...{
                    [action.payload.id]: {
                        ...(state.posts[action.payload.id] as BlogPost),
                        highlightFetchStatus: FetchStatus.ERROR
                    }
                }
            }
        }
    })).addCase(fetchUpdateBlogPostState, (state, action) => ({
        ...state,
        ...{
            posts: {
                ...state.posts,
                ...{
                    [action.payload.id]: {
                        ...(state.posts[action.payload.id] as BlogPost),
                        activateFetchStatus: FetchStatus.ACTIVE
                    }
                }
            }
        }
    })).addCase(fetchUpdateBlogPostStateSuccess, (state, action) => ({
        ...state,
        ...{
            posts: {
                ...state.posts,
                ...{
                    [action.payload.id]: {
                        ...(state.posts[action.payload.id] as BlogPost),
                        activateFetchStatus: FetchStatus.SUCCESS,
                        state: action.payload.state
                    }
                }
            }
        }
    })).addCase(fetchUpdateBlogPostStateError, (state, action) => ({
        ...state,
        ...{
            posts: {
                ...state.posts,
                ...{
                    [action.payload.id]: {
                        ...(state.posts[action.payload.id] as BlogPost),
                        activateFetchStatus: FetchStatus.ERROR
                    }
                }
            }
        }
    })).addCase(fetchSaveBlogPost, (state, action) => ({
        ...state,
        ...{
            posts: {
                ...state.posts,
                ...{
                    [action.payload]: {
                        ...(state.posts[action.payload] as BlogPost),
                        saveFetchStatus: FetchStatus.ACTIVE
                    }
                }
            }
        }
    })).addCase(fetchSaveBlogPostSuccess, (state, action) => {
        let postsByCategory = [...(state.postsByCategory[action.payload.blogCategoryID] || [])];
        if (!postsByCategory.includes(action.payload.id)) {
            postsByCategory.push(action.payload.id);
        }
        return {
            ...state,
            ...{
                posts: {
                    ...state.posts,
                    ...{
                        [action.payload.id]: {
                            ...action.payload,
                            saveFetchStatus: FetchStatus.SUCCESS
                        }
                    }
                },
                postsByCategory: {
                    ...state.postsByCategory,
                    [action.payload.blogCategoryID]: postsByCategory
                }
            }
        }
    }).addCase(fetchSaveBlogPostError, (state, action) => ({
        ...state,
        ...{
            posts: {
                ...state.posts,
                ...{
                    [action.payload]: {
                        ...(state.posts[action.payload] as BlogPost),
                        saveFetchStatus: FetchStatus.ERROR
                    }
                }
            }
        }
    })).addCase(setCreatedPostSuccessFetchStatus, (state) => ({
        ...state,
        ...{
            posts: {
                ...state.posts,
                ...{
                    '-1': {
                        ...(state.categories[-1] as BlogPost),
                        saveFetchStatus: FetchStatus.SUCCESS
                    }
                }
            }
        }
    })).addCase(resetSaveBlogPostFetchStatus, (state, action) => ({
        ...state,
        ...{
            posts: {
                ...state.posts,
                ...{
                    [action.payload]: {
                        ...(state.posts[action.payload] as BlogPost),
                        saveFetchStatus: FetchStatus.DEFAULT
                    }
                }
            }
        }
    })).addCase(resetUpdateBlogPostStateFetchStatus, (state, action) => ({
        ...state,
        ...{
            posts: {
                ...state.posts,
                ...{
                    [action.payload]: {
                        ...(state.posts[action.payload] as BlogPost),
                        activateFetchStatus: FetchStatus.DEFAULT
                    }
                }
            }
        }
    })).addCase(resetHighlightBlogPostFetchStatus, (state, action) => ({
        ...state,
        ...{
            posts: {
                ...state.posts,
                ...{
                    [action.payload]: {
                        ...(state.posts[action.payload] as BlogPost),
                        highlightFetchStatus: FetchStatus.DEFAULT
                    }
                }
            }
        }
    })).addCase(setBlogPostState, (state, action) => ({
        ...state,
        ...{
            posts: {
                ...state.posts,
                ...{
                    [action.payload.id]: {
                        ...(state.posts[action.payload.id] as BlogPost),
                        state: action.payload.state
                    }
                }
            }
        }
    })));