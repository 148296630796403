import {call, put, select, spawn, take, takeEvery, takeLatest} from 'redux-saga/effects';
import {AppState, BlogCategory, BlogImage, BlogPost} from '../types/types';

import {
    DELETE_BLOG_CATEGORY,
    DELETE_BLOG_POST,
    fetchDeleteBlogCategory,
    fetchDeleteBlogCategoryError,
    fetchDeleteBlogCategorySuccess,
    fetchDeleteBlogPost,
    fetchDeleteBlogPostError,
    fetchDeleteBlogPostSuccess, fetchHighlightBlogPost, fetchHighlightBlogPostError, fetchHighlightBlogPostSuccess,
    fetchLoadBlogCategoryPosts,
    fetchLoadBlogCategoryPostsError,
    fetchLoadBlogCategoryPostsSuccess,
    fetchLoadCategories,
    fetchLoadCategoriesError,
    fetchLoadCategoriesSuccess,
    fetchSaveBlogCategory,
    fetchSaveBlogCategoryError,
    fetchSaveBlogCategorySuccess,
    fetchSaveBlogPost,
    fetchSaveBlogPostError,
    fetchSaveBlogPostSuccess,
    fetchUpdateBlogPostState,
    fetchUpdateBlogPostStateError,
    fetchUpdateBlogPostStateSuccess,
    HIGHLIGHT_BLOG_POST,
    HighlightBlogPostPayload,
    LOAD_CATEGORIES,
    LOAD_CATEGORY_BLOG_POSTS,
    SAVE_BLOG_CATEGORY,
    SAVE_BLOG_POST,
    UPDATE_BLOG_POST_STATE,
    SetBlogPostStatePayload,
    setCreatedCategorySuccessFetchStatus,
    setCreatedPostSuccessFetchStatus,
    UPLOAD_IMAGE,
    UploadImagePayload
} from '../reducer/blog/types';
import {get, Route, put as putRequest, post, deleteRequest} from '../api/Api';
import {buffers, END, eventChannel, EventChannel } from 'redux-saga';
import {PayloadAction} from '@reduxjs/toolkit';

export const BASE_URL = process.env.REACT_APP_BASE_URL || '';

interface UploadEventChannel<T> {
    progress?: number;
    success?: boolean;
    err?: Error;
    response?: T;
}


function createUploadFileChannel<T>(jwt: string, endpoint: string, file: File): EventChannel<UploadEventChannel<T>> {
    return eventChannel<UploadEventChannel<T>>(emitter => {
        const xhr = new XMLHttpRequest();
        const onProgress = (e: ProgressEvent) => {
            if (e.lengthComputable) {
                const progress = e.loaded / e.total;
                emitter({progress});
            }
        };
        const onFailure = () => {
            emitter({err: new Error('Upload failed')});
            emitter(END);
        };
        xhr.upload.addEventListener('progress', onProgress);
        xhr.upload.addEventListener('error', onFailure);
        xhr.upload.addEventListener('abort', onFailure);
        xhr.onreadystatechange = () => {
            const {readyState, status, response} = xhr;
            if (readyState === 4) {
                if (status === 200) {
                    emitter({success: true, response: JSON.parse(response)});
                    emitter(END);
                } else {
                    onFailure();
                }
            }
        };
        xhr.open('POST', `${BASE_URL}${endpoint}`, true);
        xhr.setRequestHeader('Authorization', `Bearer ${jwt}`);
        const formData = new FormData();
        formData.append('file', file);
        xhr.send(formData);
        return () => {
            xhr.upload.removeEventListener('progress', onProgress);
            xhr.upload.removeEventListener('error', onFailure);
            xhr.upload.removeEventListener('abort', onFailure);
            xhr.onreadystatechange = null;
            xhr.abort();
        };
    }, buffers.sliding(2));
}


export function* loadCategories() {
    const jwt: string | undefined = yield select((state: AppState) => state.user.jwt);
    if (jwt?.trim()) {
        try {
            yield put(fetchLoadCategories());
            const categories: BlogCategory[] = yield call(get, Route.Blog.LOAD_CATEGORIES, undefined, jwt);
            yield put(fetchLoadCategoriesSuccess(categories));
        } catch (e) {
            yield put(fetchLoadCategoriesError());
        }
    }
}

export function* loadCategorySaga() {
    yield takeLatest(LOAD_CATEGORIES, loadCategories);
}

export function* uploadBlogImage() {
    yield takeEvery(UPLOAD_IMAGE, function* (action: PayloadAction<UploadImagePayload>) {
        const jwt: string | undefined = yield select((state: AppState) => state.user.jwt);
        const {file, onProgress, onFinish, onError} = action.payload;
        const channel: EventChannel<UploadEventChannel<{ body: BlogImage }>> = yield call(createUploadFileChannel as any, jwt, Route.Blog.UPLOAD_IMAGE, file);
        while (true) {
            const {progress = 0, err, success, response} = yield take<UploadEventChannel<{ body: BlogImage }>>(channel);
            if (err) {
                yield put(onError());
            }
            if (success) {
                if (response) {
                    yield put(onFinish(response));
                }
                break;
            }
            yield put(onProgress(progress));
        }
    });
}

export function* saveCategory(action: PayloadAction<number>) {
    const {jwt, category}: {jwt?: string, category?: BlogCategory} = yield select((state: AppState) => ({
        jwt: state.user.jwt,
        category: state.blog.categories[action.payload]
    }));
    if (jwt?.trim() && category) {
        try {
            let method = category.id > -1 ? putRequest : post;
            yield put(fetchSaveBlogCategory(action.payload));
            const body = {
                id: category.id,
                localizations: category.Localizations,
                imageID: category.BlogImages[0]?.id,
                responsible: category.responsibleAdmin?.id
            }
            const response: BlogCategory = yield call(method, Route.Blog.UPDATE_OR_CREATE_CATEGORY, body, jwt);
            yield put(fetchSaveBlogCategorySuccess(response));
            if (category.id === -1) {
                yield put(setCreatedCategorySuccessFetchStatus());
            }
        } catch (e) {
            yield put(fetchSaveBlogCategoryError(action.payload));
        }
    }
}

export function* saveCategorySaga() {
    yield takeLatest(SAVE_BLOG_CATEGORY, saveCategory);
}

export function* deleteCategory(action: PayloadAction<number>) {
    const {jwt, category}: {jwt?: string, category?: BlogCategory} = yield select((state: AppState) => ({
        jwt: state.user.jwt,
        category: state.blog.categories[action.payload]
    }));
    if (jwt?.trim() && category) {
        try {
            yield put(fetchDeleteBlogCategory(action.payload));
            const body = {
                id: category.id
            }
            yield call(deleteRequest, Route.Blog.UPDATE_OR_CREATE_CATEGORY, body, jwt);
            yield put(fetchDeleteBlogCategorySuccess(action.payload));
        } catch (e) {
            console.error(e);
            yield put(fetchDeleteBlogCategoryError(action.payload));
        }
    }
}

export function* deleteCategorySaga() {
    yield takeLatest(DELETE_BLOG_CATEGORY, deleteCategory);
}


export function* loadCategoriesBlogPosts(action: PayloadAction<number>) {
    const jwt: string | undefined = yield select((state: AppState) => state.user.jwt);
    if (jwt?.trim() && action.payload) {
        try {
            yield put(fetchLoadBlogCategoryPosts(action.payload));
            const posts: BlogPost[] = yield call(get, Route.Blog.LOAD_CATEGORY_POSTS(action.payload), undefined, jwt);
            yield put(fetchLoadBlogCategoryPostsSuccess({categoryID: action.payload, posts}));
        } catch (e) {
            yield put(fetchLoadBlogCategoryPostsError(action.payload));
        }
    }
}

export function* loadCategoryBlogPostsSaga() {
    yield takeLatest(LOAD_CATEGORY_BLOG_POSTS, loadCategoriesBlogPosts);
}

export function* savePost(action: PayloadAction<number>) {
    const {jwt, blogPost}: {jwt?: string, blogPost?: BlogPost} = yield select((state: AppState) => ({
        jwt: state.user.jwt,
        blogPost: state.blog.posts[action.payload]
    }));
    if (jwt?.trim() && blogPost) {
        try {
            let method = blogPost.id > -1 ? putRequest : post;
            yield put(fetchSaveBlogPost(action.payload));
            const body = {
                id: blogPost.id,
                localizations: blogPost.Localizations,
                imageID: blogPost.BlogImages[0]?.id,
                categoryID: blogPost.blogCategoryID,
                tags: blogPost.BlogTags,
                state: blogPost.state
            }
            const response: BlogPost = yield call(method, Route.Blog.UPDATE_OR_CREATE_POST(blogPost.blogCategoryID), body, jwt);
            yield put(fetchSaveBlogPostSuccess(response));
            if (blogPost.id === -1) {
                yield put(setCreatedPostSuccessFetchStatus());
            }
        } catch (e) {
            yield put(fetchSaveBlogPostError(action.payload));
        }
    }
}

export function* savePostSaga() {
    yield takeLatest(SAVE_BLOG_POST, savePost);
}

export function* deletePost(action: PayloadAction<number>) {
    const {jwt, post}: {jwt?: string, post?: BlogPost} = yield select((state: AppState) => ({
        jwt: state.user.jwt,
        post: state.blog.posts[action.payload]
    }));
    if (jwt?.trim() && post) {
        try {
            yield put(fetchDeleteBlogPost(action.payload));
            const body = {
                id: post.id
            }
            yield call(deleteRequest, Route.Blog.DELETE_POST, body, jwt);
            yield put(fetchDeleteBlogPostSuccess(action.payload));
        } catch (e) {
            console.error(e);
            yield put(fetchDeleteBlogPostError(action.payload));
        }
    }
}

export function* deletePostSaga() {
    yield takeLatest(DELETE_BLOG_POST, deletePost);
}

export function* updatePostState(action: PayloadAction<SetBlogPostStatePayload>) {
    const {jwt, post}: {jwt?: string, post?: BlogPost} = yield select((state: AppState) => ({
        jwt: state.user.jwt,
        post: state.blog.posts[action.payload.id]
    }));
    if (jwt?.trim() && post) {
        try {
            yield put(fetchUpdateBlogPostState(action.payload));
            yield call(putRequest, Route.Blog.SET_POST_STATE, action.payload, jwt);
            yield put(fetchUpdateBlogPostStateSuccess(action.payload));
        } catch (e) {
            console.error(e);
            yield put(fetchUpdateBlogPostStateError(action.payload));
        }
    }
}

export function* updatePostStateSaga() {
    yield takeLatest(UPDATE_BLOG_POST_STATE, updatePostState);
}

export function* highlightPost(action: PayloadAction<HighlightBlogPostPayload>) {
    const {jwt, post}: {jwt?: string, post?: BlogPost} = yield select((state: AppState) => ({
        jwt: state.user.jwt,
        post: state.blog.posts[action.payload.id]
    }));
    if (jwt?.trim() && post) {
        try {
            yield put(fetchHighlightBlogPost(action.payload));
            yield call(putRequest, Route.Blog.HIGHLIGHT_POST, action.payload, jwt);
            yield put(fetchHighlightBlogPostSuccess(action.payload));
        } catch (e) {
            console.error(e);
            yield put(fetchHighlightBlogPostError(action.payload));
        }
    }
}

export function* highlightPostSage() {
    yield takeLatest(HIGHLIGHT_BLOG_POST, highlightPost);
}

function* blogSaga() {
    yield spawn(loadCategorySaga);
    yield spawn(uploadBlogImage);
    yield spawn(saveCategorySaga);
    yield spawn(deleteCategorySaga);
    yield spawn(loadCategoryBlogPostsSaga);
    yield spawn(savePostSaga);
    yield spawn(deletePostSaga);
    yield spawn(updatePostStateSaga);
    yield spawn(highlightPostSage);
}

export default blogSaga;