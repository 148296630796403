import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    CircularProgress,
    Typography
} from '@material-ui/core';
import {useLocalization} from '../../../localization/localization';

interface ConfirmationDialogProps {
    onSubmit: () => void,
    onCancel?: () => void,
    title: string | string[],
    open: boolean,
    activeFetch: boolean
}

const useStyles = makeStyles((theme) => ({
    root: {},
    title: {
        padding: theme.spacing(3, 5, 1.5)
    },
    headline: {
        fontSize: 28,
        fontWeight: 600,
        color: '#193150',
        lineHeight: '36px'
    },
    cancelButton: {
        border: '1px solid #193150',
        color: '#193150',
        background: 'white',
        fontSize: 18,
        fontWeight: 600,
        textTransform: 'none',
        marginRight: theme.spacing(2),
        boxShadow: 'none',
        width: '50%',
        height: 48,
        '&:hover': {
            boxShadow: 'none'
        }
    },
    submitButton: {
        background: theme.palette.primary.main,
        color: 'white',
        textTransform: 'none',
        fontSize: 18,
        fontWeight: 600,
        boxShadow: 'none',
        width: '50%',
        height: 48,
        '&:hover': {
            boxShadow: 'none'
        }
    },
    content: {
        padding: theme.spacing(2, 5)
    },
    progress: {
        width: '12px !important',
        height: '12px !important',
        marginRight: theme.spacing(0.5)
    },
    actions: {
        padding: theme.spacing(0, 5, 3)
    }
}));

const ConfirmationDialog: React.FC<ConfirmationDialogProps> = ({
                                                                   title,
                                                                   children,
                                                                   onCancel,
                                                                   onSubmit,
                                                                   open,
                                                                   activeFetch
                                                               }) => {

    const classes = useStyles();
    const messages = useLocalization();

    return (
        <Dialog open={open} className={classes.root} onClose={onCancel}>
            <DialogTitle className={classes.title}>
                <Typography variant={'h2'} className={classes.headline}>
                    {title}
                </Typography>
            </DialogTitle>
            <DialogContent className={classes.content}>
                {children}
            </DialogContent>
            <DialogActions className={classes.actions}>
                <Button className={classes.cancelButton} variant={'contained'} onClick={() => onCancel?.()}>
                    {messages.dialogs.confirm.cancel}
                </Button>
                <Button disabled={activeFetch} className={classes.submitButton} variant={'contained'} color={'primary'}
                        onClick={onSubmit}>
                    {activeFetch && <CircularProgress className={classes.progress}/>}
                    {messages.dialogs.confirm.submit}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default ConfirmationDialog;