import React, {useCallback, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useSnackbar} from 'notistack';
import {IconButton, Theme} from '@material-ui/core';
import {Close} from '@material-ui/icons';
import {makeStyles} from '@material-ui/core/styles';
import * as classnames from 'classnames';
import {AppState, SnackbarVariant} from '../../../types/types';
import {dismissApplicationMessage, removeApplicationMessage} from '../../../reducer/application/types';

let openNotifications: string[] = [];

const useStyles = makeStyles((theme: Theme) => ({
    closeButton: {
        color: theme.palette.primary.contrastText
    },
    errorButton: {
        color: '#F91C3D'
    },
    successButton: {
        color: theme.palette.primary.dark
    },
    content: {
        backgroundColor: 'white !important'
    },
    error: {
        color: '#F91C3D !important'
    },
    success: {
        color: `${theme.palette.primary.dark} !important`
    }
}));

function Notifier() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const notifications = useSelector((state: AppState) => state.application.messages);
    const {enqueueSnackbar, closeSnackbar} = useSnackbar();

    const storeNotification = (id: string) => {
        openNotifications = [...openNotifications, id];
    };

    const removeDisplayed = (id: string) => {
        openNotifications = [...openNotifications.filter(it => id !== it)];
    };

    const closeSnackbarItem = useCallback((_: any, key: string) => {
        // remove this snackbar from redux store
        dispatch(dismissApplicationMessage(key));
        removeDisplayed(key);
    }, [dispatch]);

    useEffect(() => {
        notifications.forEach(it => {
            if (it.dismissed) {
                // dismiss snackbar using notistack
                closeSnackbar(it.key);
                dispatch(removeApplicationMessage(it.key));
                return;
            }
            if (openNotifications.includes(it.key)) {
                return;
            }
            enqueueSnackbar(<span dangerouslySetInnerHTML={{__html: it.text}} />, {
                key: it.key,
                variant: it.variant,
                autoHideDuration: it.duration || 3000,
                className: classnames(classes.content, {
                    [classes.error]: it.variant === SnackbarVariant.ERROR,
                    [classes.success]: it.variant === SnackbarVariant.SUCCESS,
                }),
                onExited: (event, key) => {
                    closeSnackbarItem(event, (key as string));
                },
                action: () => (
                    <IconButton className={classnames(classes.closeButton, {
                        [classes.errorButton]: it.variant === SnackbarVariant.ERROR,
                        [classes.successButton]: it.variant === SnackbarVariant.SUCCESS,
                    })} onClick={() => {
                        closeSnackbarItem(null, it.key);
                    }}>
                        <Close/>
                    </IconButton>
                )
            });
            storeNotification(it.key);
        });
    }, [notifications, dispatch, enqueueSnackbar, closeSnackbar, closeSnackbarItem, classes]);

    return null;

}

export default Notifier;
