import {spawn} from 'redux-saga/effects';
import hotelSaga from './HotelSaga';
import userSaga from './UserSaga';
import blogSaga from './BlogSaga';
import adminSaga from './AdminSaga';

/**
 * The root saga of the web app. It calls all other sagas which will be used in the web app.
 */

function* appSaga(){
    yield spawn(hotelSaga);
    yield spawn(userSaga);
    yield spawn(blogSaga);
    yield spawn(adminSaga);
}

export default appSaga;