import React, {ChangeEvent} from 'react';
import Ifta from './Ifta';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {fade, makeStyles, Theme} from '@material-ui/core/styles';
import {isEqual} from 'lodash';
import {Chip} from '@material-ui/core';

export interface IftaAutocompleteProps<OptionType> {
    options: OptionType[];
    label: string;
    value: (string | OptionType)[];
    id?: string,
    onChange: (e: ChangeEvent<any>, value: (string | OptionType)[]) => void;
    getOptionLabel: (option: OptionType) => string;
    creatable?: boolean;
    noOptionsText?: string;
    placeholder?: string;
    rows?: number
}

const useStyles = makeStyles((theme: Theme) => ({
    autoCompleteIfta: {
        minHeight: 73,
        position: 'relative'
    },
    autoCompleteInput: {
        border: 'none',
        boxShadow: 'none',
        '&:focus': {
            border: 'none',
            boxShadow: 'none'
        }
    },
    autoCompleteInputRoot: {
        ...theme.input,
        paddingTop: theme.spacing(3),
        position: 'relative',
        minHeight: '73px important',
        [theme.breakpoints.down('sm')]: {
            paddingLeft: theme.spacing(1.5)
        }
    },
    autoCompleteTag: {
        background: 'white',
        boxShadow: '0 0 1px 0 rgba(0,0,0,0.1), 0 1px 3px 0 rgba(0,0,0,0.08)',
        borderRadius: 100,
        height: 35,
        fontSize: 15,
        fontWeight: 500,
        letterSpacing: -0.34,
        color: '#595F6F',
        '&>svg': {
            width: 24,
            height: 24,
            color: '#BFC4CE',
            '&:hover': {
                color: fade('#BFC4CE', 0.5)
            }
        }
    }
}));

export interface BaseOptionType {
    name: string;
}

const IftaAutocomplete = <OptionType extends BaseOptionType, >({options, value, id, label, onChange, getOptionLabel, creatable, noOptionsText, placeholder, rows}: IftaAutocompleteProps<OptionType>) => {

    const classes = useStyles();

    return (
        <Autocomplete
            id={id}
            options={options}
            autoComplete={true}
            multiple={true}
            getOptionLabel={getOptionLabel}
            fullWidth={true}
            openOnFocus={true}
            value={value}
            onChange={onChange}
            freeSolo={creatable}
            getOptionSelected={(option, value) => isEqual(option, value)}
            noOptionsText={noOptionsText}
            classes={{
                input: classes.autoCompleteInput,
                inputRoot: classes.autoCompleteInputRoot,
                tag: classes.autoCompleteTag
            }}
            autoHighlight={true}
            renderTags={(value: OptionType[], getTagProps) =>
                value.map((option: OptionType, index: number) => (
                    <Chip variant={'outlined'} label={option.name} {...getTagProps({ index })} color={'primary'} />
                ))
            }
            filterSelectedOptions={true}
            renderInput={(params) => {
                return <Ifta label={label}
                             rows={rows}
                             multiline={rows !== undefined && rows > 1}
                             className={classes.autoCompleteIfta}
                             placeholder={value.length > 0 ? '' : placeholder}
                             name={`${id}-input`} {...params}/>
            }}
        />
    );
}

export default IftaAutocomplete;