import React, {RefCallback, RefObject} from 'react';
import {Dictionary} from '@reduxjs/toolkit';

interface ImageProps {
    srcSet: Dictionary<Dictionary<string>>;
    alt?: string;
    title?: string;
    className?: string;
    pictureClass?: string;
    style?: React.CSSProperties;
    forceLoad?: boolean;
    surroundClassName?: string;
    onLoad?: () => void;
    imageRef?: RefObject<HTMLImageElement> | RefCallback<HTMLImageElement>;
}

const Image: React.FC<ImageProps> = ({
                                         className,
                                         alt,
                                         forceLoad,
                                         pictureClass,
                                         srcSet,
                                         style,
                                         surroundClassName,
                                         title,
                                         onLoad,
                                         imageRef
                                     }) => {

    return (
        <picture className={pictureClass}>
            <source srcSet={`${srcSet.fallback?.lg2x} 2x, ${srcSet.fallback?.lg} 1x`}
                    media={'(min-width: 900px)'}/>
            <source srcSet={`${srcSet.fallback?.md2x} 2x, ${srcSet.fallback?.md} 1x`}
                    media={'(min-width: 601px)'}/>
            <source srcSet={`${srcSet.fallback?.sm2x} 2x, ${srcSet.fallback?.sm} 1x`}
                    media={'(min-width: 451px)'}/>
            <source srcSet={`${srcSet.fallback?.xs2x} 2x, ${srcSet.fallback?.xs} 1x`}
                    media={'(max-width: 450px)'}/>
            <img className={className} src={srcSet.fallback?.lg}
                 alt={alt} title={title} style={style} ref={imageRef} onLoad={onLoad}/>
        </picture>
    )
}

export default Image;