import {createAction} from '@reduxjs/toolkit';
import {User} from '../../types/types';

export const LOGOUT = 'USER_ACTIONS/LOGOUT';
export const LOGIN_USER = 'USER_ACTIONS/LOGIN_USER';
export const FETCH_LOGIN_USER = 'USER_ACTIONS/FETCH_LOGIN_USER';
export const FETCH_LOGIN_USER_SUCCESS = 'USER_ACTIONS/FETCH_LOGIN_USER_SUCCESS';
export const FETCH_LOGIN_USER_ERROR = 'USER_ACTIONS/FETCH_LOGIN_USER_ERROR';

export interface FetchLoginUserSuccessPayload {
    user: User
    token: string
}

export interface LoginUserPayload {
    email: string
    password: string
}

export const logout = createAction(LOGOUT);

export const loginUser = createAction<LoginUserPayload>(LOGIN_USER);
export const fetchLoginUser = createAction(FETCH_LOGIN_USER);
export const fetchLoginUserSuccess = createAction<FetchLoginUserSuccessPayload>(FETCH_LOGIN_USER_SUCCESS);
export const fetchLoginUserError = createAction(FETCH_LOGIN_USER_ERROR);