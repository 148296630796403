import React, {useMemo} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Theme} from '@material-ui/core/styles/createTheme';
import {BlogCategory, BlogPostAction, Language, LocalizationKey} from '../../../../types/types';
import {getLocalizationValueForLanguageAndKey, mapImageToSrcSet} from '../../../../helper/helperFunctions';
import BlogImage from '../BlogImage';
import {Button, IconButton, Typography} from '@material-ui/core';
import {Link} from 'react-router-dom';
import {useLocalization} from '../../../../localization/localization';
import {Delete, Edit} from '@material-ui/icons';

interface CategoryProps {
    category: BlogCategory
    onEdit: () => void
    onDelete: () => void
}

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        position: 'relative',
        background: 'white',
        transition: theme.defaultTransition,
        boxShadow: '0 0 1px 0 rgba(16,36,52,0.25), 0 4px 12px 0 rgba(0,0,0,0.06)',
        borderRadius: 6,
        '&:hover': {
            boxShadow: '0 16px 32px 0 rgba(61,66,69,0.12), 0 0 1px 0 rgba(16,36,52,0.25), 0 4px 15px 0 rgba(46,49,52,0.12);'
        },
        '&:hover $actions': {
            opacity: 1
        }
    },
    image: {
        height: 288,
        width: '100%',
        marginBottom: theme.spacing(2.5)
    },
    content: {
        padding: theme.spacing(0, 3, 3),
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        flex: 1
    },
    title: {
        fontSize: 20,
        fontWeight: 600,
        color: '#193150',
        lineHeight: '25px',
        marginBottom: theme.spacing(0.5)
    },
    description: {
        color: theme.palette.primary.light,
        fontSize: 16,
        lineHeight: '20px',
        marginBottom: theme.spacing(2.5)
    },
    link: theme.link,
    button: {
        width: '100%',
        height: 52,
        fontSize: 16,
        textTransform: 'none',
        boxShadow: 'none',
        fontWeight: 600,
        '&:hover': {
            boxShadow: 'none'
        }
    },
    actions: {
        position: 'absolute',
        top: theme.spacing(1),
        right: theme.spacing(1),
        opacity: 0,
        transition: 'all 0.3s cubic-bezier(0.55, 0, 0.6, 1)',
        display: 'flex',
        flexDirection: 'column',
        zIndex: 20
    },
    iconButton: {
        width: 40,
        height: 40,
        marginBottom: theme.spacing(1.5),
        background: 'white',
        boxShadow: '0 1px 1px 0 rgba(0,0,0,0.14), 0 2px 1px -1px rgba(0,0,0,0.12), 0 1px 3px 0 rgba(0,0,0,0.2)',
        '&:hover': {
            background: '#2F89FC'
        },
        '&:hover $icon': {
            color: 'white'
        }
    },
    icon: {
        transition: 'all 0.3s cubic-bezier(0.55, 0, 0.6, 1)',
        color: '#193150'
    },
    views: {
        color: theme.palette.primary.main,
        fontWeight: 600,
        letterSpacing: -0.25,
        lineHeight: 1,
        fontSize: 14,
        padding: theme.spacing(1, 1, 0.75),
        background: '#FFFFFF',
        borderRadius: 3,
        position: 'absolute',
        top: theme.spacing(2),
        left: theme.spacing(2)
    }
}));

const Category: React.FC<CategoryProps> = ({category, onEdit, onDelete}) => {
    const classes = useStyles();
    const messages = useLocalization();

    const imageSet = useMemo(() => {
        const path = category.BlogImages[0]?.path || '';
        const type = category.BlogImages[0]?.type || '';
        return mapImageToSrcSet(path, type);
    }, [category.BlogImages]);

    const name = getLocalizationValueForLanguageAndKey(category.Localizations, LocalizationKey.BlogCategoryName, Language.GERMAN);
    const description = getLocalizationValueForLanguageAndKey(category.Localizations, LocalizationKey.BlogCategoryDescription, Language.GERMAN);

    return (
        <div className={classes.root}>
            {imageSet !== undefined && <BlogImage imageSet={imageSet} placement={'center'}
                                                  heightRatio={0.53} className={classes.image}
                                                  alt={name} title={name}/>}
            <Typography variant={'body1'} className={classes.views}>
                {category.views.toLocaleString()}
            </Typography>
            <div className={classes.content}>
                <div>
                    <Typography variant={'h3'} className={classes.title}>
                        {name}
                    </Typography>
                    <Typography variant={'body1'} className={classes.description}>
                        {description}
                    </Typography>
                </div>
                <Link className={classes.link} to={`/blog/categories/${category.id}/${BlogPostAction.FETCH}`}>
                    <Button variant={'contained'} color={'primary'} className={classes.button}>
                        {messages.blog.categories.details}
                    </Button>
                </Link>
            </div>
            <div className={classes.actions}>
                <IconButton className={classes.iconButton} onClick={onEdit}>
                    <Edit className={classes.icon}/>
                </IconButton>
                <IconButton className={classes.iconButton} onClick={onDelete}>
                    <Delete className={classes.icon}/>
                </IconButton>
            </div>
        </div>
    );
}

export default Category;