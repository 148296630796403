import React, {useEffect, useMemo} from 'react';
import {BlogPost, BlogPostState, Language, LocalizationKey} from '../../../../types/types';
import {makeStyles} from '@material-ui/core/styles';
import {Theme} from '@material-ui/core/styles/createTheme';
import {getLocalizationValueForLanguageAndKey, mapImageToSrcSet} from '../../../../helper/helperFunctions';
import BlogImage from '../BlogImage';
import {Button, IconButton, Typography} from '@material-ui/core';
import {useLocalization} from '../../../../localization/localization';
import {fade} from '@material-ui/core/styles/colorManipulator';
import * as classnames from 'classnames';
import {Link} from 'react-router-dom';
import {Star, StarBorderOutlined, Visibility, VisibilityOff} from '@material-ui/icons';
import FetchStatus from '../../../../api/FetchStatus';
import {useDispatch} from 'react-redux';
import {
    highlightBlogPost,
    resetHighlightBlogPostFetchStatus,
    resetUpdateBlogPostStateFetchStatus,
    updateBlogPostState
} from '../../../../reducer/blog/types';

interface PostProps {
    post: BlogPost;
    onDelete: () => void
    openSnackbar: (message: string, icon: Object | null, variant: FetchStatus) => void
}

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        position: 'relative',
        background: 'white',
        transition: theme.defaultTransition,
        boxShadow: '0 0 1px 0 rgba(16,36,52,0.25), 0 4px 12px 0 rgba(0,0,0,0.06)',
        borderRadius: 6,
        overflow: 'hidden',
        '&:hover': {
            boxShadow: '0 16px 32px 0 rgba(61,66,69,0.12), 0 0 1px 0 rgba(16,36,52,0.25), 0 4px 15px 0 rgba(46,49,52,0.12);'
        }
    },
    image: {
        height: 228,
        width: '100%'
    },
    content: {
        boxSizing: 'border-box',
        padding: theme.spacing(3),
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        flex: 1
    },
    title: {
        fontSize: 20,
        lineHeight: '25px',
        color: '#193150',
        marginBottom: theme.spacing(0.5)
    },
    description: {
        fontSize: 16,
        color: theme.palette.primary.light,
        lineHeight: '20px'
    },
    actions: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    actionButton: {
        width: `calc(50% - ${theme.spacing(1)}px)`,
        background: 'white',
        borderColor: '#FF5252',
        color: '#FF5252',
        boxShadow: 'none',
        textTransform: 'none',
        fontSize: 18,
        fontWeight: 600,
        '&:hover': {
            background: fade('#FF5252', 0.25),
            borderColor: fade('#FF5252', 0.25),
            boxShadow: 'none'
        }
    },
    editButton: {
        background: theme.palette.primary.main,
        borderColor: theme.palette.primary.main,
        color: 'white',
        width: '100%',
        '&:hover': {
            background: fade(theme.palette.primary.main, 0.25),
            borderColor: fade(theme.palette.primary.main, 0.25)
        }
    },
    link: {
        ...theme.link,
        display: 'block',
        width: `calc(50% - ${theme.spacing(1)}px)`,
    },
    directActions: {
        position: 'absolute',
        top: theme.spacing(2),
        right: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
        zIndex: 20
    },
    iconButton: {
        width: 40,
        height: 40,
        marginBottom: theme.spacing(1.5),
        background: 'white',
        boxShadow: '0 1px 1px 0 rgba(0,0,0,0.14), 0 2px 1px -1px rgba(0,0,0,0.12), 0 1px 3px 0 rgba(0,0,0,0.2)',
        position: 'relative',
        '&:hover': {
            background: 'white'
        },
        '&:hover $invisible': {
            opacity: 1
        },
        '&:hover $iconActive': {
            opacity: 0
        }
    },
    icon: {
        transition: 'all 0.3s cubic-bezier(0.55, 0, 0.6, 1)',
        color: '#193150',
        position: 'absolute'
    },
    enabledIcon: {
        color: theme.palette.primary.main,
    },
    iconActive: {
        opacity: 1
    },
    defaultIcon: {},
    invisible: {
        opacity: 0
    },
    views: {
        color: theme.palette.primary.main,
        fontWeight: 600,
        letterSpacing: -0.25,
        lineHeight: 1,
        fontSize: 14,
        padding: theme.spacing(1, 1, 0.75),
        background: '#FFFFFF',
        borderRadius: 3,
        position: 'absolute',
        top: theme.spacing(2),
        left: theme.spacing(2)
    }
}));

const Post: React.FC<PostProps> = ({post, onDelete, openSnackbar}) => {
    const classes = useStyles();
    const messages = useLocalization();
    const dispatch = useDispatch();

    const imageSet = useMemo(() => {
        const path = post.BlogImages[0]?.path || '';
        const type = post.BlogImages[0]?.type || '';
        return mapImageToSrcSet(path, type);
    }, [post.BlogImages]);

    useEffect(() => {
        if (post.highlightFetchStatus === FetchStatus.SUCCESS) {
            openSnackbar(post.highlighted ? messages.blog.posts.manage.highlight.success : messages.blog.posts.manage.unhighlight.success, null, FetchStatus.SUCCESS);
            dispatch(resetHighlightBlogPostFetchStatus(post.id));
        } else if (post.highlightFetchStatus === FetchStatus.ERROR) {
            openSnackbar(post.highlighted ? messages.blog.posts.manage.unhighlight.error : messages.blog.posts.manage.highlight.error, null, FetchStatus.ERROR);
            dispatch(resetHighlightBlogPostFetchStatus(post.id));
        }
    }, [messages.blog.posts.manage, post.highlightFetchStatus, post.highlighted, openSnackbar, dispatch, post.id]);

    useEffect(() => {
        if (post.activateFetchStatus === FetchStatus.SUCCESS) {
            openSnackbar(post.state === BlogPostState.ACTIVE ? messages.blog.posts.manage.active.success : messages.blog.posts.manage.inactive.success, null, FetchStatus.SUCCESS);
            dispatch(resetUpdateBlogPostStateFetchStatus(post.id));
        } else if (post.activateFetchStatus === FetchStatus.ERROR) {
            openSnackbar(post.state === BlogPostState.ACTIVE ? messages.blog.posts.manage.inactive.error : messages.blog.posts.manage.active.error, null, FetchStatus.ERROR);
            dispatch(resetUpdateBlogPostStateFetchStatus(post.id));
        }
    }, [messages.blog.posts.manage, post.activateFetchStatus, post.state, openSnackbar, dispatch, post.id]);

    const name = getLocalizationValueForLanguageAndKey(post.Localizations, LocalizationKey.BlogPostName, Language.GERMAN);
    const description = getLocalizationValueForLanguageAndKey(post.Localizations, LocalizationKey.BlogPostDescription, Language.GERMAN);

    return (
        <div className={classes.root}>
            {imageSet !== undefined && <BlogImage imageSet={imageSet} placement={'center'}
                                                  heightRatio={0.53} className={classes.image}
                                                  alt={name} title={name}/>}
            <Typography variant={'body1'} className={classes.views}>
                {post.views.toLocaleString()}
            </Typography>
            <div className={classes.content}>
                <div>
                    <Typography variant={'h3'} className={classes.title}>
                        {name}
                    </Typography>
                    <Typography variant={'body1'} className={classes.description}>
                        {description}
                    </Typography>
                </div>
                <div className={classes.actions}>
                    <Button variant={'outlined'} className={classes.actionButton} onClick={onDelete}>
                        {messages.blog.posts.manage.delete.button}
                    </Button>
                    <Link to={`/blog/categories/${post.blogCategoryID}/post/${post.id}`} className={classes.link}>
                        <Button variant={'outlined'} className={classnames(classes.actionButton, classes.editButton)}>
                            {messages.blog.posts.manage.edit}
                        </Button>
                    </Link>
                </div>
            </div>
            <div className={classes.directActions}>
                <IconButton className={classes.iconButton} onClick={() => dispatch(highlightBlogPost({
                    id: post.id,
                    highlighted: !post.highlighted
                }))} disabled={post.highlightFetchStatus === FetchStatus.ACTIVE}>
                    <StarBorderOutlined className={classnames(classes.icon, {
                        [classes.invisible]: post.highlighted,
                        [classes.iconActive]: !post.highlighted
                    })}/>
                    <Star className={classnames(classes.icon, classes.enabledIcon, {
                        [classes.iconActive]: post.highlighted,
                        [classes.invisible]: !post.highlighted,
                    })}/>
                </IconButton>
                <IconButton className={classes.iconButton} onClick={() => dispatch(updateBlogPostState({
                    id: post.id,
                    state: post.state === BlogPostState.ACTIVE ? BlogPostState.INACTIVE : BlogPostState.ACTIVE
                }))} disabled={post.activateFetchStatus === FetchStatus.ACTIVE}>
                    <VisibilityOff className={classnames(classes.icon, {
                        [classes.invisible]: post.state === BlogPostState.ACTIVE,
                        [classes.iconActive]: post.state !== BlogPostState.ACTIVE
                    })}/>
                    <Visibility className={classnames(classes.icon, classes.enabledIcon, {
                        [classes.iconActive]: post.state === BlogPostState.ACTIVE,
                        [classes.invisible]: post.state !== BlogPostState.ACTIVE,
                    })}/>
                </IconButton>
            </div>
        </div>
    );
}

export default Post;